 <template>
  <div>
  <GFWEditorDefault v-if="(metadata.editor === 'GFWEditorDefault') || (metadata.editor === 'GFWEditorInventory')"
    :ref="metadata.editor"
    @closeUntilPosition="closeUntilPosition" 
    @update="passUpdate" @insert="passInsert" @close="close"
     :metadata=metadata
     :dataRecord=dataRecord
     :parentMeta=parentMeta
     :parentId=parentId
     :counter=counter
     :parentRecord=parentRecord
     :zIndex=zIndex
     :dataArray=dataArray
     :entityMap= entityMap
     :detailEntityMap=detailEntityMap
     :mapping=mapping
     :selectedIndex=selectedIndex
     :dataId=dataId
     :path=path
     :reload=reload
     :display= display
     :time=time  
    >
   </GFWEditorDefault>  
   <GFWEditorEditor v-else-if="metadata.editor === 'GFWEditorEditor'"
     :ref="metadata.editor"
     @closeUntilPosition="close"
     @close="close"
     :metadata=metadata
     :moduleData=dataRecord
    >
   </GFWEditorEditor>  
   <GFWEditorInventory v-else-if="metadata.editor === 'GFWEditorInventory'"
    :ref="metadata.editor"
    @closeUntilPosition="closeUntilPosition"
    @update="passUpdate" @insert="passInsert" @close="close"
     :metadata=metadata
     :dataRecord=dataRecord
     :parentMeta=parentMeta
     :parentId=parentId
     :counter=counter
     :parentRecord=parentRecord
     :zIndex=zIndex
     :dataArray=dataArray
     :entityMap= entityMap
     :detailEntityMap=detailEntityMap
     :mapping=mapping
     :selectedIndex=selectedIndex
     :dataId=dataId
     :path=path
     :display= display 
    >
    </GFWEditorInventory>  
    <GFWEditorRotation v-else-if="metadata.editor === 'GFWEditorRotation'"
    :ref="metadata.editor"
    @closeUntilPosition="closeUntilPosition"
    @update="passUpdate" @insert="passInsert" @close="close"
     :metadata=metadata
     :dataRecord=dataRecord
     :parentMeta=parentMeta
     :parentId=parentId
     :counter=counter
     :parentRecord=parentRecord
     :zIndex=zIndex
     :dataArray=dataArray
     :entityMap= entityMap
     :detailEntityMap=detailEntityMap
     :mapping=mapping
     :selectedIndex=selectedIndex
     :dataId=dataId
     :path=path
     :display= display 
    >
   </GFWEditorRotation>  
  </div>
</template>

<script>

export default {
  name: "GFWEditorRouter",
  props: {
    metadata: Object,
   
    dataRecord: Object,
    parentMeta: Object,
    counter: Number,
    parentId: { type: Number, default: 0 },
    reload: { type: Boolean, default: false },
    parentRecord: Object,
    time: Number,
    zIndex: { type: Number, default: 90 },
    dataArray: { type: Array, default: () => [] },
    entityMap: { type: Object, default: () => {} },
    detailEntityMap: { type: Object, default: () => {} },
    mapping: { type: Array, default: () => [] },
    selectedIndex: { type: Number, default: 0 },
    dataId: { type: Number, default: 0 },
    path: { type: Array, default: () => [] },
    display: Boolean
  },
  methods: {
  	close() {
  		this.$emit('close'); 
  	},

  	passUpdate(entity, record) {
        this.updateCount++;
    	this.$emit( "update", entity, record);
    },
    passInsert(entity, record) {
        this.updateCount++;
    	this.$emit( "insert", entity, record);
    },
    closeUntilPosition(position)
    {
    	this.$emit("closeUntilPosition", position)
    },
    open( data, meta )
    {
    	this.metadata =  meta;
    	this.$nextTick(function () {
    		if ( Array.isArray(this.$refs[meta.editor]))
    		{
    			this.$refs[meta.editor][0].readGivenRecord( data, meta);
    		}
    		else
    		{
    			this.$refs[meta.editor].readGivenRecord( data, meta);
    		}
    	
    	});
    }
  }
}
</script>
<style  scoped>

</style>