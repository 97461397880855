<template>
<div style='display: block;'>
<div class='inputWrapper' v-click-outside="close">
    <span class='span25'  @click='switchPopup'>
        <span v-if="disabled">
         	<div v-for="(col,idx) in getActiveColumns()" :key="'col'+idx" class='floatLeft' :style='getColumnStyle(col)'
							>
							
					       <div class='header higher disabled' >
					       		{{ col.show }}
					       	</div>
					    </div>
        </span>
        <span v-else-if="value && value.length">
		    <!--
		    <span class='fieldWrapper' v-for="(id,idx) in value" :key="'col_'+idx" style='border: 1'>
		    	{{getData().find(p=>p.id===id).label}}
		    </span>
		    -->

		    <div v-for="(col,idx) in getActiveColumns()" :key="'col'+idx" class='floatLeft' :style='getColumnStyle(col)'
					        @dragstart="startDragColumn($event, col.show, idx)" draggable
					        @drop="onDropColumn($event, idx)"
					        @dragover.prevent
							@dragenter.prevent
							>
							
					       <div class='header higher' >
					       		{{ col.show }}
					       	</div>
					    </div>
		</span>
		<span v-else>
			<span style='color: transparent;'>
				X
		    </span>
		</span>
    </span>

    
    <div v-show='!disabled && showColumnSelector' 
           @drop="removeColumn($event)" 
           @dragover.prevent
		   @dragenter.prevent
		   style='display: block'
           class='menuList'>
        <div class='avText'>available fields <span style='font-size: 8pt; font-weight: normal;'>(move and arrange with drag & drop)</span></div>
			        <div style='float: top; width: 100%; border: 1pt solid grey; background-color: #fff; vertical-align: middle;' class='smaller'>
						<div  style='float: left; border: 3pt groovy #ccc; width: 330pt;' class='transition'>
						<div v-if="notSelectedColumns().length > 0" style='height: 100pt;'>	 
					    	<div v-for="(col,idx) in notSelectedColumns()" :key="'COL_'+idx" 

					    	    class='transition'>
					    		<div :class='getAvColumnClasses()'   
					    		     draggable
					    	         @click="addColumn(col.show, -1)"
									 @dragover.prevent
									 @dragenter.prevent
					    	         @dragstart="startDragColumn($event, col.show, -1)"
					    	         > 
							       		{{ col.show }}
							       	</div>
					    	</div>
					    </div>
					    <div v-else>	 
					    	<div style='padding-left: 5%;' class='transition'>
					    		<div style='height: 100pt; width: 80%; color: #aaa; font-size: 10pt;'
									 @dragover.prevent
									 @dragenter.prevent
					    	         
					    	         > 
							       		move column here
							       	</div>
					    	</div>
					    </div>
				    </div>
       </div>
        
    </div>
</div>
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import ColumnSelector from '@/components/ColumnSelectorNonModal';
import ClickOutside from 'vue-click-outside'
export default {
  props: {
    value: Array,
    recordId: Number,
    column: String,
    metadata: {},
    disabled: Boolean,
    definition: Object,
    entityMap: Object,
    mark: { type: Boolean, default: false},
    border: { type: Boolean, default: false}
  },
  components : {
     
  },
  data() {
  	return {
  	   selectData: [],
  	   showColumnSelector: false,
  	   availableColumns: [],
  	   selected: [],
  	   selectedColumns: [],
  	}
  },
  directives:{
    ClickOutside,
  },
  methods: {
      getData() {
		//alert(JSON.stringify(this.definition))
		if ( this.definition.values && this.definition.values.length)
		{
			let map = [];
			for ( let i in this.definition.values)
			{
				map.push({id: i, label: this.definition.values[i], additionalColumn: [0,this.definition.values[i]]});
			}
			map.sort((a,b)=> a.label.localeCompare(b.label))
			//alert(this.column + "/" + JSON.stringify(map))
			return map;
		}
      	//let col = this.definition.columnHeader;
      	//alert(this.column + "/" + JSON.stringify(this.definition))
      	let col = this.definition.columnHeader;
      	
      	//alert(this.column + "/" + JSON.stringify( this.entityMap[col].data.filter(p => p.additionalColumn[0] === this.recordId)))
      	return this.entityMap[col].data.filter(p => p.additionalColumn[0] === this.recordId);
      },
      getAvColumnClasses()  { return "choice normal floatLeft button transition" },
      setSelectedColumns(selectedColumns)
      {
        let cols = this.getData();
        this.value = []
      	for (var i in selectedColumns)
      	{
      		let name = selectedColumns[i].name;
      		let column = cols.find( p => p.label === name );
      		this.value.push(column.id);
      		//alert( name + "->"+column.id)
      	}
      	this.$emit("input", this.value);
      	//this.$emit("enter", this.value);
      },
      getColumnStyle(col, spot)
     {
        return "color: #000 !important; width: " + (parseInt(col.w)/this.widthCalculated*100) +"%"+";"
     }, 
      getActiveColumns() {
        
		if ( this.selectedColumns && this.selectedColumns.length )
		{
			return this.selectedColumns;
		}
		else
		{
		    this.getAvailableColumns();
			this.arrangeColumns();
			
			return this.selectedColumns;
		}
	  },
	  close() {
	  	this.showColumnSelector=false
	  },
	  switchPopup() {
	  	this.showColumnSelector=!this.showColumnSelector
	  },
	  arrangeColumns()
	  {
	    
	    this.selectedColumns = new Array();
	    if ( this.selected && this.availableColumns && this.availableColumns.length )
	    {
			for ( var i in this.selected )
			{
				let colName = this.selected[i];
				//console.log(colName);
				for ( var j in this.availableColumns )
				{
					let column = this.availableColumns[j];
					//console.log(column.name);
					if ( column.name === colName )
					{
						this.selectedColumns.push( column );
						//console.log(JSON.stringify(this.selectedColumns));
						break;
					}
				}
		    }
		    this.calcWidths( false);

		    return this.selectedColumns;
		}
		return [];
	},
	calcWidths(withSaving)
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	if ( withSaving )
     	{
			this.setSelectedColumns(this.selectedColumns)
		}
     	this.widthCalculated = wTot;
	},
      getAvailableColumns()
      {
      	let cols = this.getData();
      	this.availableColumns = [];
      	this.selected = [];
      	for ( var i in cols )
      	{
      	   let column = cols[i];
      	   if ( column && column.label )
      	   {
	      	   //let cx = this.metadata.columns.find( c => c.column===column.label);
	      	   //if ( cx && cx.visible )
	      	   {
					this.availableColumns.push({ name: column.label, show: column.additionalColumn[1], w: 5});
	      	   }
      	   }
      	}
      	
      	if ( this.value )
      	{
	      	for ( i in this.value )
	      	{
	      	   let cId = this.value[i]
	      	   
	      	   this.selected.push( cols.find(p => p.id == cId).label);
	      	   
	      	}
	    }
	    this.arrangeColumns();
      	return this.availableColumns;
      },
      startDragColumn (evt, col, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('column', col)
        evt.dataTransfer.setData('columnIndex', idx)
  	},
    notSelectedColumns()
    {
     	var cols = new Array();
     	for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i].show;
     		var selected = false;
     		for ( var vis in this.selectedColumns )
     		{
     			let colSel = this.selectedColumns[ vis].show;
     			if ( colSel === col )
     			{
     				selected = true;
     				break;
     			}
     		}
     		if ( ! selected )
     		{
     			cols.push( this.availableColumns[ i]);
     		}
     	} 
     	return cols;
     },
     removeColumn (evt )
	{
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		//alert( columnIndex);
		//alert( JSON.stringify(this.selectedColumns));
		if ( columnIndex && columnIndex >= 0 ) 
		{
			this.selectedColumns.splice( columnIndex, 1);
			//alert( JSON.stringify(this.selectedColumns));
		}
		this.calcWidths( true );
	},
	addColumn (show) {
	    
			var colToInsert;
			for ( var i in this.availableColumns )
	     	{
	     		let col = this.availableColumns[ i];
	     		if ( col.show == show )
	     		{
	     			colToInsert = col;
	     			break;
	     		}
	     	}
	     	this.selectedColumns.splice( this.selectedColumns.length-1, 0, colToInsert); // insert at 6
	     	
			this.calcWidths( true);

	},
     onDropColumn (evt, target) {
		const source = evt.dataTransfer.getData('column');
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		var colToInsert;
		for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i];
     		if ( col.show == source )
     		{
     			colToInsert = col;
     			break;
     		}
     	}
     	if ( colToInsert )
     	{
	     	//alert( target + " vs " + columnIndex);
	     	if ( target <= columnIndex )
	     	{
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
				this.selectedColumns.splice( target, 0, colToInsert);
			}
			else
			{
				this.selectedColumns.splice( target+1, 0, colToInsert); // insert at 6
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
			}
			
			this.calcWidths( true );
		}
	},
      show()
      {
      	 this.getAvailableColumns();
      	 this.showColumnSelector = true;
      },
      enterPressed() { this.$emit("enter"); },
      changed() { this.$emit("changed", this.value); }
  },
  created() {
	  
	  this.getAvailableColumns();
	  
  },
  watch: {
	recordId: function() { this.getAvailableColumns()(); },
	definition: function() { this.getAvailableColumns()(); },
	value: function() {  this.getAvailableColumns(); }
  },
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left;}
.inputBorder { font-size: 12pt; padding: 2pt; text-align: left; border: 1pt solid #ccc; width: 80%;  }

.span25 { display: inline-block; width: 95%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }

.avText {
	font-weight: bold;
	font-size: 9pt;
	padding: 4pt;
	color: #888;
}
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.disabled {
	color: #888;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px; 
  font-weight: bold;
  width: 100pt !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.normal {
  height: 16pt; 
}

.inputWrapper { 
    display: block;
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding-left: 5pt;
    padding-top: 3pt;
    padding-bottom: 0pt;
    margin-bottom: 0pt;
    width: 100%;

	overflow: visible !important;
}
.fieldWrapper { 
    border: 1pt solid #888;
    background-color: #eef;
    border-radius: 6px;
    padding: 2pt;
    margin: 1pt;
}
.floatLeft {
    float: left;
}
.header { 
  display: flex;
  align-items: center;
  justify-content: center;

  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
}
.higher {
  height: 22pt; 
  font-weight: bold; 
  font-size:8pt;
}
.inputButton { 
    border: 1pt solid grey;
}
.menuList { 
    position: absolute; 
    float: top; 
    display: block;
	overflow: visible !important;
    background-color: #fff; 
    //color: #dde;
    border: 1pt solid #ccc; 
    z-index: 9999;
    border-radius: 4px;
    padding-top: 1pt;
    padding-bottom: 1pt;
    box-shadow: 2px 2px 4px 0 #aaa;
}
</style>