<template>
  <div id="popup" dropzone="true" :style="{display : popupData.display}">
      <div class="inner" draggable="true" @contextmenu="closeFunction">
        <div class="header">
            <div>{{popupData.header}}</div>
            <button @click="closeFunction">X</button>
        </div>
        <div class="body">{{popupData.body}}</div>

      </div>
  </div>
</template>
<script>
export default {
    props : ["popupData"],
    methods : {
        closeFunction(){
            this.popupData.display = "none";
        }
    }
}
</script>

<style lang="scss" scoped>

html,body{
    padding: 0;
    margin:0;
}
#popup{
    position: absolute;
    width: 100%;
    height :100%;
    top: 0;
    
}
#popup .inner .footer {
    background-color: #eee;
    height: 0%;
}
#popup .inner{
    border: 1pt solid #aaa;
    background-color: #eee;
	border-radius: 8px;
	padding: 2pt;
    position: inherit;
    top: 30%;
    left: 25%;
    right: 25%;  
    width: 22%;
}
#popup .inner.body{
    height: 80%;
}
#popup .inner div{
    text-align: left;
}
#popup .inner .header{
    display: flex;
    background-color: #ccc;
    justify-content: space-between;
}
</style>