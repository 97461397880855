<template>
  <div class="main white" >
       <div class='white headLine'>
	       <div class='white' style=' width: 90%; float: left;'>
	       	<b :style="'font-size: '+(fontSize+1)+'pt; padding-left: 10pt;'">Error logs ({{notifications.length}})</b>
	       </div> 
	       <div style='float: right;' @click='$emit("close")'>
	       		<WindowCloseBN/>
		   </div>
	  </div>
	  <br/>
	  <div class='white body'>
       <div class='white' style='width: 100%; height: 60pt; overflow-y: auto;' :load="reloadIdx">
      	<div v-for="(h,idx) in valid(notifications)" :key="'h_'+idx" class='item' 
      	     @click='openImmed( h)'
      	     @contextmenu.prevent="openCM($event, h)"
      	     :style="getStyle(h)">
      	       {{printTime( h)}}
      		  
      		  {{printNoty( h)}}
      		  <span @click="download(h.fileId, 'download')">
      		  <mdicon v-if='h.fileId' :width="10" :height="10" name="download" /> 
      		  </span>
      	</div>
       </div>
	 </div>

    <GFWEOpenEditor ref='editor' ></GFWEOpenEditor>

    
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, notifyAPI, userAPI, opsAPI, myTimer, receiveToken, myName, setErrorLogsWidgetReload, showError} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import { getAppStyle, setDarkMode, isDarkMode, initAppMode } from '@/AppStyle.js';
import { printUTCHHmm } from '@/basicTimeFN.js';
import { tr } from '@/translate.js';
import store from "@/store";
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
export default {
  name: 'GFW_LOGS',
  components : {
   WindowCloseBN
  },
  props: {
   	user: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
  data: function () {
    return {
      tr, 	
      reloadIdx: 1,
      notifications: [],
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
       printTime(h) {
         return printUTCHHmm(h.lUpdate);
       },
       printNoty(h) {
         return h.task + " "+ h.message;
       },
       show(x)
       {
    	 alert(JSON.stringify(x))  
       },
       valid( notifications)
       {
    	 return notifications.filter( p=>p.task);
       },
       getStyle(h) {
    	    if ( isDarkMode() )
    	    {
    	    	let col = "#030";
    	    	let fg = "#fff"
    	    	
                let extra = '';
         
           		return "color: "+fg+"; background-color: "+col+";font-size: "+this.fontSize+"pt; padding-left: 10pt;"+extra;
    	    }
            let col = "#fff";
            let extra = '';
       		return "color: #000; background-color: "+col+";font-size: "+this.fontSize+"pt; padding-left: 10pt;"+extra;
       },
       download( fileId, doWhat )
       {
    	let that = this;
    	if ( doWhat == 'download')
        {
	    	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
                let downloadResponse = response.data;
           		that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }
        else
        {
        	
        	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
               
                that.$refs.pdfView.open(response.data.file)
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }

    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	   },
      openCM(event, notification){
        event.stopPropagation();
        event.preventDefault();
        this.$refs.menu.open(event, notification )
	   },
	   closeCMN() { try{ this.$refs.menu.close(); } catch(e) { /* */ }},
       openBookMan( noty ) 
       {
			setGoBack( this.$router.currentRoute.path, "Desktop");
			if ( noty.mediaId )
			{
				this.$router.replace('/bookMan?toOpen=Placement&id='+ noty.placementId);
			}
			else
			{
				this.$router.replace('/bookMan?toOpen=Placement&list=true&id='+ noty.placementId);
			}
       },  
       openEditor(noty)
       {
			this.closeCMN(); 
			if ( noty && noty.moduleId && noty.dataId )
			{
				this.$refs.editor.open(noty.moduleId, noty.dataId);
			}
       },
       openNotification( id)
       {
			this.closeCMN(); 
			this.$refs.editor.open('Log', id);
       },
       
       addNotification(n) 
       { 
    	  let found = false;
    	  for ( let i in this.notifications)
    	  {
    		 let log = this.notifications[i];
    		 if ( log.id === n.id)
    		 {
    			 found = true;
    			 this.notifications[i] = n;
    			 break;
    		 }
    	  }
    	  if (! found)
    	  {
          	this.notifications.splice(0,0,n);
    	  }
    	  else
    	  {
    		  this.notifications.push({}).pop();
    	  }
    	  if ( this.notifications.length >= 200 )
    	  {
    		  this.notifications.length = 200;
    	  }
    	  this.reloadIdx++;
       },
       openImmed( h)
       {
    	   this.$refs.editor.open("ErrorLog", h.id);
       },
       getNotification() {
       	   let that = this;
       	   //alert( notifyAPI+"/getNotifications/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+this.user.id);
       	   HTTP.post( notifyAPI+"/getErrorLogs/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+this.user.id) 
	            .then( response => 
	               { 
	              	 let notifications = new Array();
	              	 //
	              	 //alert( JSON.stringify(response.data));
	              	 for ( var h in response.data )
	              	 {
	              	 	let hist = response.data[h];
	              	 	
	              	 	notifications.push( hist);

	              	 	
	              	 }
	              	 that.notifications = notifications;
    
	               }).catch(e => {
	                    showError( that.toast, e, { position: "topRight" });
			      });
       }
  },
  destroyed() {
  	setErrorLogsWidgetReload( null);
  },
  updated() 
  {
	  setErrorLogsWidgetReload( this.addNotification);
  },
  created()
  {
    setErrorLogsWidgetReload( this.addNotification);
	this.getNotification();
	
  },
  watch: {
        user: function() {
            this.getNotification();
		 }
    }
}
</script>

<style scoped>

.main {
	display: block;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}
.shadow {
    background-color: white !important;
	width: 100%;
    box-shadow: 5px 5px 8px 0 #444;
}
.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.white {
    //background-color: white !important;
}

.iButton:hover {background-color: #e8f0e8 !important;}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


