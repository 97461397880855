<template>
<div class="home">
 
</div>
</template>
<script>
import {getDomainPrefix} from '@/variables.js';

export default {
 
  name: 'home',
  components: {
 
  },
   data () {
    return {

    }
  },
  methods: {

  },
  created() {
	  let hostname = window.location.host;
	  if ( hostname && hostname.indexOf(".") > 0)
	  {
		  let prefix = hostname.substring( 0, hostname.indexOf("."));
		  if (isNaN(prefix) && prefix != 'www') {  
		  	this.$router.replace("/home/"+prefix); 
		  }
		  else
		  {
			  this.$router.replace("/home/start");
		  }
	  }
	  else
	  {
      	this.$router.replace("/home/"+getDomainPrefix());
      }
  }
}
</script>
<style lang="scss" scoped>
  
</style>