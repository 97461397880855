<template>
<div class='inputWrapper' >
     
     <div class='text' v-show="!isOpen(filterLine)" :style="'color:'+ getBGColor('#000','#fff')">
          
    	  <div style='display: inline-block;' @click="keepOpen=true; open=true">{{ readAble }}</div>
          
     </div> 
	 <!-- {{filterLine.id}} <--  TMP -->
    
     <div v-show="isOpen(filterLine)" style='display: inline-block; width: calc(88vH)' >
     <span :title="readAble" class='editorElement smallFont' style='width:13%; padding-top: 4pt;'>
    		  
    		  <b>{{filterLine.type.name}}</b>
    	
     </span>     
     <span :title='getToolTippOnOff()' class='editorElement' style='width:calc(10H);'>
    		<button @click='setOnOff()' :class='getOnOffStyle()' style='height: 20pt;'>{{getOnOff(value)}}</button>
     </span>	
     <div class='editorElement' style='display: inline-block;; width:calc(16vH); height: 33pt;'  >
        <div style='display: inline-block; width: 100%; margin-top: 5pt;'>   
			
	        <GSelectEntity @selected="setOperator"   
	                       border="false"
	                       entityTitle="Operator" 
	                       :options="allOperators" 
	                       :filters="getType( filterLine).operators" 
	                       entityName="InventoryOperator" 
						   noOpener="true"
	                       :visible="true" 
	                       :disabled="false" :id="filterLine.operator.id" :index=index>
	        </GSelectEntity>
			
		</div>              	
     </div>
            <span v-if='isSingle(filterLine)'>
				
             <span v-if='isOption(filterLine)' class='editorElement' style='height: 33pt; width:40%;'>
				<div style='display: inline-block; width: 100%; margin-top: 5pt;'>
	              	<GSelectEntity v-if='isMultiple(filterLine)' @selected="updateValues"  :multiple="true" 
	              	               :entityTitle=title :entityName=filterLine.type.entity 
	              	               :id="filterLine.struct.values"
	              	               :visible="true" :disabled="false">
	              	</GSelectEntity>
	             
	              	<GSelectEntity v-else @selected="updateValue"  :multiple="false" 
	              	               :entityTitle=title :entityName=filterLine.type.entity 
	              	               :id="filterLine.struct.value"
	              	               :visible="true" :disabled="false">
	              	</GSelectEntity>
				</div>
             </span>
             <span v-else-if='isWeekdays(filterLine)' class='editorElement' style='width:40%;'>
              	<InputWeekdays  @enter="update()" 
                            v-model="filterLine.struct.value" @change=update :disabled="false">
              	</InputWeekdays>
             </span>
             <span v-else-if='isDate(filterLine)' class='editorElement' style='width:40%;'>
              	<InputDatePick  @enter="update()" 
                            v-model="filterLine.struct.value" @change=update :disabled="false">
              	</InputDatePick>
             </span>
             <span v-else-if="isString(filterLine)" class='editorElement' style='width:40%;'>
             <InputText  
                            @enter="update()" @change="update()" :disabled="false" :clipboard="false" :border="true"
                            v-model="filterLine.struct.value"/> 
             </span>
             <span v-else-if="isInt(filterLine)" class='editorElement' style='width:40%;'>
             <InputInt
                            @enter="update()" @change=update :disabled="false" :clipboard="true" :border="false"
                            v-model="filterLine.struct.value"/>
             </span>
             <span v-else-if="isDate(filterLine) && isMultiple(value)" class='editorElement' style='width:40%;'>
			             <InputDatePick :auto="false" 
			                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
			                            v-model="filterLine.struct.value"/>
             </span> 
             <span v-else-if="isDate(filterLine)" class='editorElement' style='width:40%;'>
			             <InputDatePick :auto="false" 
			                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
			                            v-model="filterLine.struct.value"/>
             </span> 
             
            </span>
	     <span v-else-if='isRange(filterLine)'>
             <span class='editorElement borderElement' style='width:20%;'>
                    <InputTime v-if="isTime(filterLine)" 
                            @enter="update" @change=update :disabled="false" :clipboard="false" :border="false"
                            v-model="filterLine.struct.from"/>   
                    <InputInt v-else-if="isInt(filterLine)" 
                            @enter="update" @change=update :disabled="false" :clipboard="false" :border="false"
                            v-model="filterLine.struct.from"/> 
                    <InputText v-else-if="isString(filterLine)" 
                            @enter="update" @change="update" :disabled="false" :clipboard="false" :border="false"
                            v-model="filterLine.struct.from"/> 
                    <InputDatePick :auto="false" v-else-if="isDate(filterLine)" 
                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="false"
                            v-model="filterLine.struct.from"/> 
                         
             </span>
                <span class='editorElement' style='width:5%;'>{{value.operator.symbol}}</span>
                <span class='editorElement borderElement' style='width:20%;'>
                   <InputTime v-if="isTime(filterLine)" 
                            @enter="update" @change=update :disabled="false" :clipboard="false" :border="false"
                            v-model="filterLine.struct.to"/> 
             		<InputInt v-else-if="isInt(filterLine)" :clipboard="false" :border="false"
                           @enter="update" @change=update :disabled="false" 
                           v-model="filterLine.struct.to"/> 
                   <InputText v-else-if="isString(filterLine)" 
                            @enter="update" @change=update :disabled="false" :clipboard="false" :border="false"
                            v-model="filterLine.struct.to"/> 
                   <InputDatePick :auto="false" v-else-if="isDate(filterLine)" 
                            @enter="update" @change=update :disabled="false" :clipboard="false" :border="false"
                            v-model="filterLine.struct.to"/>

                           
                   
                </span>
            </span>
         </div>
         <div style='display: inline-flex; float: right;' v-if="simple">
         
            <button v-if="scope=='inline'" title='close' class='button' @click="closeEdit(false); open=false" :style="getAppStyle()+'width: 18pt; border: none;'">
                <mdicon name="close" :size='14'/>
			</button>
			<button v-if="scope=='definition' && !keepOpen && !(open||forceOpen)" class='button' @click="open=true" :style="getAppStyle()+'width: 14pt;border: none;'">
                <mdicon name="pencil" :size='14'/>
			</button>
			<button v-if="scope=='definition'  && !keepOpen && (open||forceOpen)" class='button' @click="toText( true); closeEdit(); open=false" :style="getAppStyle()+'width: 14pt;border: none;'">
                <mdicon name="close" :size='14'/>
			</button>
			
			<button v-if="scope=='definition'" title='copy' class='button' @click="copyLine()" :style="getAppStyle()+'width: 14pt; border: none;'">
                <mdicon name='content-copy' :size='14'/>
			</button>
			
			<button v-if="scope=='definition'" class='button' @click="removeLine()" :style="getAppStyle()+'width: 14pt; border: none;'">
                <mdicon name='delete' :size='14'/>
			</button>
			<button v-if="scope=='inline'" title='close and save' class='button' @click="closeEdit(true); open=false" :style="getAppStyle()+'width: 18pt; border: none;'">
                <mdicon name="check" :size='14'/>
			</button>
			<!-- 
			<button class='button' @click="showMe" :style="getAppStyle()+'width: 18pt; border: none;'">
                <mdicon name="eye" :size='14'/>
			</button>
			-->
			
         </div>
         <div v-else style='display: inline-flex'>   
			<button v-if="!(open||forceOpen)" class='button' @click="open=true" :style="getAppStyle()+'width: 14pt;'">
                <mdicon name="pencil" :size='14'/>
			</button>
			<button v-else class='button' @click="toText( true); closeEdit(); open=false" :style="getAppStyle()+'width: 14pt;'">
                <mdicon name="text" :size='14'/>
			</button>
			<button :style="getAppStyle()+'width: 14pt;'+(isOpen(filterLine)?'visibility: hidden;':'')" 
			    class='button transition' :disabled="filterLine.level<=0 || number==0" @click="filterLine.level--; update()">
                <mdicon name="arrow-left-bold" :size='14'/>
			</button>
			<button :style="getAppStyle()+'width: 14pt;'+(isOpen(filterLine)?'visibility: hidden;':'')" 
			       class='button transition' :disabled="number==0" @click="filterLine.level++; update()">
                <mdicon name="arrow-right-bold" :size='14'/>
			</button>
			
			<button class='button' @click="copyLine()" :style="getAppStyle()+'width: 14pt'">
                <mdicon name='content-copy' :size='14'/>
			</button>
			<button class='button' @click="removeLine()" :style="getAppStyle()+'width: 14pt;'">
                <mdicon name='delete' :size='14'/>
			</button>
		</div>	

</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import GSelectEntity from '@/components/GSelectEntity';
import {HTTP, fwAPI, invAPI, inventoryInlineAPI, APIURL, showError} from '@/variables.js';
import InputText from '@/components/inputElements/InputText';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import GImage from '@/components/GImage';
import InputSecret from '@/components/inputElements/InputSecret';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InputDouble from '@/components/inputElements/InputDouble';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import Switch from '@/components/Switch'
import { getAppStyle, setDarkMode, initAppMode, getBGColor } from '@/AppStyle.js';
var numeral = require('numeral');
export default {
  name: "InputInventoryFilter",
  props: {
	index: Number,
    value: {},
    scope: { type: String, default: "inline"},
    indent: { type: Number, default: 0},
    forceOpen: { type: Boolean, default: null},
    keepOpen: { type: Boolean, default: false },
    simple: { type: Boolean, default: false},
    number: { type: Number, default: 0},
    title: String,
    counter: Number,
    allOperators: Array,
    allTypes: Array,
    format: { type: String, default: "0,0.00"},
    disabled: Boolean
  },
  data(){
    return {
    	filterLine: null,
        opId: this.value.operator.id,
        leaveOpen: false,
        open: (this.forceOpen),
        readAble: "",
        operator: {},
        valueFrom: 0,
        valueTo: 0,
        valueFromStr: "",
        valueToStr: "",
    	entityData: Object,
    	values: [],
    	getAppStyle, getBGColor
    }
  },
  components : {
	  GSelectEntity, InputInt, InputWeekdays, InputTime, InputText, InputDatePick
    //GSelectEntity, 
  },
  methods: {
	  update()
	  {
		  if ( this.leaveOpen )
		  {
		  		this.open = true;
		  }
		  this.$set( this, "filterLine", { ...this.filterLine});
		  this.toText( true).then( p=> {
			  this.$emit( "setStruct", this.index, this.filterLine);
			  this.$emit( "update", this.filterLine);
		  })
	  },
	  closeEdit(save) 
	  {
		  if ( save )
		  {
			  this.$emit( "update", this.filterLine);
		  }
		  this.$emit('closeEdit', save?this.filterLine:false);
		  
	  },
	  setOnOff()
	  {
		  this.filterLine.positive=!this.filterLine.positive; 
		  this.$emit( "update", this.filterLine);
		  this.toText( true);  
	  },
	  changeJoiner()
	  {
		  //console.log("changeJoiner" )
			if ( this.filterLine.joiner==='AND')
			{
				this.filterLine.joiner='OR'
			}
			else
			{
				this.filterLine.joiner='AND'
			}
		  this.toText( true).then( p=> {
			  this.$emit( "setStruct", this.index, this.filterLine);
			  this.$emit( "update", this.filterLine);
		  })
	  },
	  showMe() {
		  alert( JSON.stringify( this.filterLine))
	  },
	  updateValue(v)
	  {
		  
		  if ( v && v.id )
		  {
			  this.filterLine.struct.value = v.id;
			  this.filterLine.struct.labels = [ v.label ];
		  }
		  else
		  {
			  this.filterLine.struct.value = 0;
			  this.filterLine.struct.labels = [ ];
		  }
		  
		  this.filterLine.struct.values = null;
		  this.filterLine.struct.from = null;
		  this.filterLine.struct.to = null;
		  
		  
		  this.toText( true).then( p=>{
			  this.$set( this, "filterLine", { ...this.filterLine});
			  this.$emit( "setStruct", this.index, this.filterLine);
			  this.$emit( "update", this.filterLine);
		  })
	  },
	  updateValues(v)
	  {
		  
		  if ( v && v.id )
		  {
			  this.filterLine.struct.values = v.map( v=>v.id);
			  this.filterLine.struct.labels = v.map( v=>v.label);
		  }
		  else
		  {
			  this.filterLine.struct.values = [];
			  this.filterLine.struct.labels = [];
		  }
		  this.filterLine.struct.value = null;
		  this.filterLine.struct.from = null;
		  this.filterLine.struct.to = null;
		  this.filterLine.struct.values = v.map( v=>v.id);
		  this.filterLine.struct.labels = v.map( v=>v.label);
		  
		  this.toText( true).then( p=>{
			  this.$set( this, "filterLine", { ...this.filterLine});
			  this.$emit( "setStruct", this.index, this.filterLine);
			  this.$emit( "update", this.filterLine);
		  })
	  },
	  copyLine()  { this.$emit("copy", this.filterLine); },
	  removeLine() {
		//alert( JSON.stringify( this.filterLine )) 
		this.$emit("remove", this.filterLine); 
	  },
      enterPressed() { this.$emit("enter"); this.toText(); },
	  isMultiple(entityData) {
	      	if ( entityData && entityData.operator && entityData.operator.operands && entityData.operator.operands == 'many' )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	      isOption(entityData) {
	      	if ( entityData && entityData.type.path && entityData.type.path.toLowerCase() === 'id' )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	      isSingle(entityData) {
	      	if ( entityData && entityData.operator && entityData.operator.operands 
	      	     && (entityData.operator.operands === 'one' || entityData.operator.operands === 'many') )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	      isInt(entityData) {
	      	if ( entityData && entityData.type.type && (entityData.type.type === 'Integer') )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	       isString(entityData) {
	      	if ( entityData && entityData.type.type && (entityData.type.type === 'String') )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	      isTime(entityData) {
	      	if ( entityData && entityData.type.type && (entityData.type.type === 'Time') )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	      isWeekdays(entityData) {
	      	if ( entityData && entityData.type.type && (entityData.type.type === 'Weekdays') )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	      isRange(entityData) {
	      	if ( entityData && entityData.operator && entityData.operator.operands && (entityData.operator.operands === 'two') )
	      	{
	      		return true;
	      	}
	      	return false;
	      },
	      isDate(entityData) {
	    	  if ( entityData && entityData.type.type && (entityData.type.type === 'Date') )
	        	{
	        		return true;
	        	}
	        	return false;
	        },
	  getOnOff() {
	      	if ( this.filterLine.positive )
	      	{
	      		return "IS";
	      	}
	      	else
	      	{
	      		return "BLACKLIST"
	      	}
      },
      getOnOffStyle() {
      	if ( this.filterLine.positive)
      	{
      		return "button on"
      	}
      	else
      	{
      		return "button off"
      	}
      },
      getToolTippOnOff() {
      	if ( this.filterLine.positive )
      	{
      		return "switch to NOT (= allow everything, that is NOT part of this filter-line), to invert this filter-line"
      	}
      	else
      	{
      		return "switch to IS (= allow everything, that is part of this filter-line)"
      	}
      },
	  setOperator(v)
	  {
    	  //this.filterLine.operator = this.allOperators.find( p=>p.id===v.id)
    	  this.$set( this.filterLine.operator,"id", v.id );
    	  let operator = this.allOperators.find( p=>p.id==v.id);
    	  let op = {id: operator.id, name: operator.name, type: operator.typeValue, operands: operator.operandsValue}
    	  this.filterLine.operator = op;
    	  this.toText( true).then( p => {
    		  this.$emit( "setStruct", this.index, this.filterLine)
        	  this.$emit( "update", this.filterLine);
    	  })
	  },
	  getOp()
	  {
		  return this.allOperators.find(p=>p.id==this.filterLine.operator.id)
	  },
	  getType( filter)
	  {
		  let type = this.allTypes.find(p=>p.id==filter.type.id);
		  return type
	  },
      isOpen( filter)
      {
    	  if ( (this.open === null && this.forceOpen === null && this.keepOpen === null))
    	  {
    		  return !this.hasValues( filter);
    	  }
    	  return this.open || this.forceOpen || this.keepOpen;
      },
      hasValues(filter)
	    {

	    	  let v = filter.struct;
			  if ( this.isRange( this.filterLine))
			  {
				return ( v.from !== null && v.to !== null);
			  }
	    	  if ( v.value || (v.from !== null && v.to !== null))
	          {
	    		  return true;
	          }
	    	  if ( v.values && v.values.length)
	          {
	    		  return true;
	          }
	    	  return false;
	      },
	  toData() 
      {
	       	let type = { name: this.filterLine.type.name, 
	       			     entity: this.filterLine.type.entity, 
	       			     path: this.filterLine.type.path, 
	       			     type: this.filterLine.type.type, 
	       			     id: this.filterLine.type.id};
	       	let op = { name: this.filterLine.operator.name, 
	       			   type: this.filterLine.operator.type, 
	       			   operands: this.filterLine.operator.operands, 
	       			   id: this.filterLine.operator.id};
	       	
	       	let onOff = this.filterLine.positive == null || this.filterLine.positive === true;
	       	//alert( i + "/"+JSON.stringify( type)+onOff); 
	       	this.filterLine.positive = onOff;
	       	return {operator: op, type: type, positive: onOff, struct: this.filterLine.struct};
      },
      
      toText( force)
      {

    	let data = this.filterLine; 
    	if( data.value && !force)
    	{
    		this.readAble = data.value;
    		return;	
    	}
    	let that = this;
    	let dataLoad = new Promise(function (resolve, reject) {

	      	HTTP.post( inventoryInlineAPI+"/transformToText/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, data )
	        .then( response => 
	        {
	        	 //console.log(response.data)
	        	 //alert( JSON.stringify(response.data)) 
	             //this.$toast.success( response.data, 'OK', { timeout: 1500, position: "topRight" });
	             that.readAble = response.data;
	             that.filterLine.value = that.readAble;
	             resolve( that.readAble)
	             return that.readAble;
	             
	        }).catch(e => {
	            showError(that.$toast, "failed", e);
	            reject();
	        });
    	});
    	return dataLoad;
      }
      
  },
  computed: {
    	
    },
  watch: {
	value: function() {
		this.filterLine= {... this.value};  
		this.toText(false);
	}
  },
  updated() 
  {

  },
  beforeMount()
  {
	this.filterLine= {... this.value};  
	//this.toText(false);
	//console.log("BEF MOUNT" +this.value.value)  
  },
  created() {

  },
 }
</script>
<style lang="scss" scoped>
.on { background-color: #ded; font-size: 7pt; width: 8em; font-weight: bold;}
.off { background-color: #000; color: #fcc; font-weight: bold; font-size: 7pt; width: 8em;font-weight: bold;}
.button:hover { background-color: #888; color: #fff;}
.button { border: 1px solid #888;}
.inputInt { text-align: right;}
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}
.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    //border: 1px solid blue;
    border-radius: 6px;
    padding: 0pt;
    width: 99%;
    display: inline-block;
    justify-content: top;
}
.joinerButton{
 border: 1px solid grey;
 margin-right: 2pt;
}
.joinerButton:hover {
  background-color: #888 !important; color: #fff  !important;
}
.inputButton { 
    border: 1pt solid grey;
}
.editorElement {
     display: inline-block;
     //padding-bottom: 8pt;
     //padding-top: 2pt;
     padding-right: 8pt;
     min-height: 22pt;
     justify-content: center;
     vertical-align: middle;
     margin-right: 4pt;
 }
 .smallFont {
 	font-size: 9pt;
 }
 .transition {
 	transition: all 0.1s;
   -webkit-transition: all 0.1s;
 }
.elementLabel {
    display: inline-flex;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    display: inline-flex;
     //border: 1pt solid yellow;
     width: 100%;
 }
 .text {
  padding-top: 4pt;
  cursor: pointer;
  height: 28pt !important; 
  display: inline-block; 
  //border-bottom: 1px solid #888; 
  width: 88%
 }
</style>