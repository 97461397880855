var darkModeBG = '#111';
var darkModeBG2 = '#aaa';

export function isDarkMode()
{
   return  sessionStorage.bgColor===darkModeBG;
}

export function setDarkModeBGColor( newColor)
{
   if ( newColor )
   {
   	darkModeBG = newColor;
   	setDarkMode( isDark( newColor));
   }
   else
   {
	darkModeBG = '#111';
	setDarkMode( false);
   }
}

export function getDefaultColorStyle()
{
	return 'background-color: '+getDefaultBG+'; color: '+sessionStorage.fgColor+';';
}

export function invers()
{
	return 'background-color: '+sessionStorage.fgColor+'; color: '+sessionStorage.bgColor+';';
}

export function getDefaultBG()
{
	return darkModeBG;
}

export function getBG()
{
	return sessionStorage.bgColor;
}

export function getFG()
{
	return sessionStorage.fgColor;
}

export function getBGColor(colorNormal, colorDark)
{
	if ( isDarkMode() )
	{
		return colorDark;
	}
	return colorNormal;
}

export function getFGColor(colorNormal, colorDark)
{
	if ( isDarkMode() )
	{
		return colorDark;
	}
	return colorNormal;
}

function toString2(x)
{
	//console.log( JSON.stringify(x))
	let str = x.toString(16);
	while( str.length < 2)
	{
		str = "0"+str;
	}	
	//console.log( JSON.stringify(str))
	return str;
	
}
export function adapt(color)
{
	if ( !isDarkMode())
	{
		return color;
	}
	let rgb = hexToRgb(color);
	
	rgb.r = Math.round(rgb.r/3)%255;
	rgb.g = Math.round(rgb.g/3)%255;
	rgb.b = Math.round(rgb.b/3)%255;
	//console.log( JSON.stringify(rgb))
	//let hex = '#' + (1<<24|rgb.r<<16|rgb.g<<8|rgb.b).toString(16).slice(1)
    //let hex = (rgb.r) | (rgb.g << 8) | (rgb.b << 16);
    //let hex = ((rgb.b | rgb.g << 8 | rgb.r << 16) / 0x1000000).toString(16).substring(2);
    let hex= toString2(rgb.r)+toString2(rgb.g)+toString2(rgb.b);
    //console.log( hex)
    return "#"+hex.toString(16);
	
}

export function autoFG(bgColor)
{
	try
	{
		if ( isDark( bgColor))
		{
			return "#fff";
		} 
	}catch (e)
	{
		//
	}
    return "#000";
	
}


export function getAppStyle()
{
   return 'background-color: '+ sessionStorage.bgColor+'; color: '+sessionStorage.fgColor+';';  
}
export function getAppStyleFG()
{
   return 'color: '+sessionStorage.fgColor+';';  
}

export function getAppStyleImp()
{
   return 'background-color: '+ sessionStorage.bgColor+' !important; color: '+sessionStorage.fgColor+' !important;';  
}

export function hexToRgb(hex) {
  if ( hex.startsWith('rgb'))
  {
	  let result = hex.slice(
        hex.indexOf("(") + 1, 
        hex.indexOf(")")
      ).split(", ");
    
    return result ? {
	    r: parseInt(result[0]),
	    g: parseInt(result[1]),
	    b: parseInt(result[2])
	  } : null;
  }
  if ( hex.length === 7)
  {
	  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	  return result ? {
	    r: parseInt(result[1], 16),
	    g: parseInt(result[2], 16),
	    b: parseInt(result[3], 16)
	  } : null;
   }
   if ( hex.length === 4)
   {
	  let result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
	  //console.log( "HEX> " + JSON.stringify(result));
	  return result ? {
	    r: parseInt(result[1]+result[1], 16),
	    g: parseInt(result[2]+result[2], 16),
	    b: parseInt(result[3]+result[3], 16)
	  } : null;
   }
   return null;
}

export function isDark(hex)
{
	//console.log( "isDark> " + hex);
	
	//console.log( hex);
    let colour = hexToRgb( hex)
    //console.log( JSON.stringify(colour));
    //alert( JSON.stringify(colour))
    let hsp = Math.sqrt(0.299 * (colour.r * colour.r) + 0.587 * (colour.g * colour.g) + 0.114 * (colour.b * colour.b))
    if (hsp>127.5)
    {
        return false;
    }
    else
    {
        return true;
    }
}

export function getBGStyle2()
{
   if ( sessionStorage.bgColor )
   {
	   if ( sessionStorage.bgColor === darkModeBG)
	   {
		  let bg = hexToRgb( darkModeBG2); 
	      return 'background-color: rgba('+bg.r+','+bg.g+','+bg.b+',.4); color: '+sessionStorage.fgColor+';';  
	   }
	   let bg = hexToRgb( sessionStorage.fgColor); 
	   return 'background-color: rgba('+bg.r+','+bg.g+','+bg.b+',.1); color: '+sessionStorage.fgColor+';';  
   }
   return "";
}

export function getBGStyle3()
{
   if ( sessionStorage.bgColor )
   {
	   if ( sessionStorage.bgColor === darkModeBG)
	   {
		  let bg = hexToRgb( darkModeBG2); 
	      return 'background-color: rgba('+bg.r+','+bg.g+','+bg.b+',.2); color: #fff !important;';  
	   }
	   let bg = hexToRgb( sessionStorage.bgColor); 
	   return 'background-color: rgba('+bg.r+','+bg.g+','+bg.b+',0.1); color: #000 !important;';  
   }
   return "";
}


export function getBGStyle4()
{
   if ( sessionStorage.bgColor )
   {
	   if ( sessionStorage.bgColor === darkModeBG)
	   {
		  let bg = hexToRgb( darkModeBG2); 
	      return 'background-color: rgba('+bg.r+','+bg.g+','+bg.b+',.4); color: '+sessionStorage.fgColor+';';  
	   }
	   let bg = hexToRgb( sessionStorage.bgColor); 
	   return 'background-color: rgba('+bg.r+','+bg.g+','+bg.b+',.1); color: '+sessionStorage.fgColor+';';  
   }
   return "";
}
export function initAppMode()
{
	let mode = isDarkMode()?"dark":"normal";
	import("@/themes/"+mode+"Theme.scss");
	try {
	 document.getElementsByTagName("body")[0].style.backgroundColor=sessionStorage.bgColor;
	 document.getElementsByTagName("body")[0].style.color = sessionStorage.fgColor;
	} catch(e1) {
		//
	}
	try {
	 document.getElementsByTagName("html")[0].style.backgroundColor=sessionStorage.bgColor;
	 document.getElementsByTagName("html")[0].style.color = sessionStorage.fgColor;
	} catch(e1) {
		//
	}
}
export function setDarkMode( darkMode)
{
	if ( darkMode )
	{
		sessionStorage.bgColor = darkModeBG; // #2c3e50';
		sessionStorage.fgColor = '#eee';
	}
	else
	{
		sessionStorage.bgColor = '#fff';
		sessionStorage.fgColor = '#2c3e50';
	}
	let mode = darkMode?"dark":"normal";
	import("@/themes/"+mode+"Theme.scss");
	try {
	
	document.getElementById("app").style.backgroundColor=sessionStorage.bgColor;
	document.getElementById("app").style.color = sessionStorage.fgColor;
	
	try {
	 document.getElementsByTagName("body")[0].style.backgroundColor=sessionStorage.bgColor;
	 document.getElementsByTagName("body")[0].style.color = sessionStorage.fgColor;
	} catch(e1) {
		//
	}
	document.getElementById("windowCloseBNSVN").style.backgroundColor=sessionStorage.bgColor;
	document.getElementById("windowCloseBNSVN").style.color = sessionStorage.fgColor;
	}
	catch( e )
	{
		//
	}
}