<template>
    
    <svg v-if="show" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#0c0" class="bi bi-check2" viewBox="0 -4 16 16">
	  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
	</svg>
     <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#c00" class="bi bi-x-lg" viewBox="0 -6 22 22">
		  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
		</svg>
</template>

<script>
import {HTTP, opsAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import InputDateTime from '@/components/inputElements/InputDateTime';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import GSelectSimple from '@/components/GSelectSimple';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'GOPS_Check',
  components : {
   
  },
  props: {
    show: {
      type: Boolean,
      default: true
    },

  },
  data () {
    return {

    }
  },
  directives:{

  },
  
  methods: {

  },
  watch: {

  },
  created() {

  }
}
</script>
<style scoped >

</style>
