<template>
<div>
    <div
      draggable
      @contextmenu="ctx($event, line, parent)"
      @dragstart.exact="dragStartFLCopy($event)"
      @dragstart.ctrl="dragStartFLMove($event)"
      @dragend.exact="dragEndFL($event)" 
      @drop.exact="dropFilter($event, line, 'copy')"
      @drop.ctrl="dropFilter($event, line, 'move')"
	  @dragover.prevent
	  @dragenter.prevent>
	
				<InputInventoryFilter v-if="line && line.struct"
	                          @update="update"
                              @copy="copyLine"
                              @remove="removeLine"
							  
			              	  :counter="counter"
			              	  :keepOpen="keepOpen"
			              	  :index=1
			              	  :number="number"
			              	  :indent="Math.max(0,indent-1)"
			              	  :forceOpen="(!line.struct.values || !line.struct.values.length) && !line.struct.value && !line.struct.from"
			              	  :simple="true"
			              	  :scope="'definition'"
			                  :allOperators="allOperators"
			                  :allTypes="allTypes"
			                  :operatorId=line.operator.id
			                  :value="line" 
			              	  :title=line.value 
			              	  >
			   </InputInventoryFilter>
			   <div v-else class="dropHere">
			   	drop filter here
			   </div>
   </div>

</div>
</template>
<script>
import InputInventoryFilter from '@/components/inputElements/InputInventoryFilterNew';
export default {
  props: {
	keepOpen: { type: Boolean, default: false },
  	line: Object,
  	parent: Object,
  	allOperators: Array,
  	number: { type: Number, default: 0},
  	allTypes: Array,
  	indent: { type: Number, default: 0}
  },
 components : {
	 InputInventoryFilter
  },
  data () {
    return {
    	counter: 0,
    }
  },
  methods: {
	  update( line)
	  {
		  this.$emit( "update", this.number, line)
	  },
	  ctx( evt, line, parent)
	  {
		  this.$emit( "openCTX", evt, line, parent)
	  },
	  removeLine( line) { this.$emit("remove", this.line); },
	  dropFilter(evt, line, type)
	  {
	     let typeId = evt.dataTransfer.getData('iRow');
	     if ( typeId && typeId > 0 )
	     {
	     	let type = this.allTypes.find(p=>p.id==typeId);
	    	 //alert( "drop "+type.name+" @ " +JSON.stringify( this.parent))
	     	this.$emit('addFilterToParentFL', type, this.parent, 0)
	     }
	     else 
	     {
	    	
	    	let joiner = evt.dataTransfer.getData('JOINER');
	    	if ( joiner )
	    	{
	     		this.$emit('addFilterToParentFL', joiner, this.parent, 0)
	     	}
	    	else
	    	{
	    		
	    		let lineId = evt.dataTransfer.getData('line');
	    		let from = evt.dataTransfer.getData('from');
	    		this.$emit('moveFilterToParentFL', lineId, this.parent, type=='copy'?null:from)
	    	}
	     }
	  },
	  dragEndFL(evt)
	  {
		  
	  },
	  dragStartFLCopy(evt)
	  {
	    	evt.dataTransfer.dropEffect = 'copy'
	        evt.dataTransfer.effectAllowed = 'copy'
	        evt.dataTransfer.setData('line', this.line.id)
	        evt.dataTransfer.setData('from', this.parent.id)
	  },
	  dragStartFLMove(evt)
	  {
	    	evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('line', this.line.id)
	        evt.dataTransfer.setData('from', this.parent.id)
	  },
	  copyLine( line )  { this.$emit("copy", this.parent, line); }
  },
  watch: {
	  line: function() {
		  this.counter = Math.round(Math.random()*100000000)
		  this.$set(this, "counter", this.counter);
		}
	  },
  beforeMount() {
	  this.counter = Math.round(Math.random()*100000000)
	  this.$set(this, "counter", this.counter);
  }
 }
</script>
<style lang="scss" scoped>
.dragMe {
	display: block;
}
.dropHere {
	padding: 2pt; 
	margin-left: 0pt; 
	margin-top: 0pt;
	margin-bottom: 2pt; 
	color: #aaa; font-size: 7pt; height: 14pt; border: 1px dotted #aaa;
}

</style>