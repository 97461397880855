var swi_data = {};

export function store( obj )
{
	swi_data = obj;
} 


export function restore( )
{
	return swi_data;
} 