<template>
  <div class="main white">
     <div class='white headLine'>
	  <div class='white' style='width: 90%; float: left; padding-top:0pt; padding-left: 0pt;'>
		 <div style='height: 100% !important; display: block; padding-top:2pt;padding-right:5pt; font-size: 9pt; font-weight: bold;'>Server controller</div>
			 <div style='display: block; padding-top:0pt;padding-right:5pt; font-size: 9pt;'>
			    Active: {{activeServerName}} <span style='font-size: 8pt;'>({{activeServer}})</span>
			 </div>
		</div> 
	    <div style='float: right;' @click='$emit("close")'>
	   		<WindowCloseBN/>
	    </div>
	  </div>
     <div>
     <div v-if="servers && servers.length" style='height: 100% !important; display: inline-block; padding-top:1pt;padding-right:5pt;'></div>
     <div v-if="servers && servers.length" style='display: inline-block;'>
     <span v-for="(d,k,i) in servers" :key="i">
       <button v-if="d.name=='LB'" :title="d.enabled?'switch to load-balancer':'node currently not reachable'" 
             @click='activate(d.url)' :disabled="!d.enabled"
             :class="'button lbButton '+((d.url==activeServer)?'selected':'')"  >
	        <span v-if="d.enabled">
	        {{d.name}}
	        </span>
	        
	        <mdicon v-else :width="18" name="sync-off" />
        </button>
	     <button v-else :title="d.enabled?'switch to ' + d.name +' ('+d.url+')':'node currently not reachable'" 
	             @click='activate(d.url)' :disabled="!d.enabled"
	             :class="'button svButton '+((d.url==activeServer)?'selected':'')"  >
	        <span v-if="d.enabled">
	        {{d.name}}
	        </span>
	        
	        <mdicon v-else :width="18" name="sync-off" />
	     </button>
	 </span>
     
     </div>
     <div :style="getAppStyle()+'display: block; width: 100%;'">
     <div :style="getAppStyle()+'display: inline-block; padding-top: 1px;'+ ((servers && servers.length)?'float: right;':'')">
     
     <button :title="'Reload data'" :class="'button'" :style="getAppStyle()"
		    	  	        @click="reload()"><mdicon :width="16" name="reload" /></button>
     </div>
     </div>
     </div>
   </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, searchAPI, userAPI, myTimer, getAvailableServers, receiveToken, myName, showError,rebuildAPI,testServer} from '@/variables.js';
import {store, restore} from '@/serverWidget.js';
import { getAppStyle, initAppMode } from '@/AppStyle.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
export default {
  name: 'GFW_SERVERS',
  components : {
	  WindowCloseBN
	  },
  props: {
   	user: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
  data: function () {
    return {
    	activeServerName: "",
    	activeServer: "",
    	servers: [],
    	getAppStyle
    }
  },
  methods: {
	    getServers() {
			let arr = JSON.parse( sessionStorage.servers);
			let result = [];
			for ( let x in arr)
			{
				let url = arr[x].second;
				result[x] = { url: arr[x].server+":"+arr[x].port, name: arr[x].shortname };
			}
			return result;
		},
	    storeSettings() 
		{
			try
			{
		    	let obj = {servers: this.servers, activeServer: this.activeServer, activeServerName: this.activeServerName};
		    	store( obj);
			} catch(e) { showError( this.$toast, "storeSettings()", e);  }
		},
		reload() {
			let srv = sessionStorage.server+":"+sessionStorage.port;
			this.activeServer = srv;
			getAvailableServers().then ( p => 
			{
		    	let tmp = this.getServers();
		    	//this.servers = [];
		    	for ( let i in tmp )
		    	{
		    		let parts = tmp[i].url.split(':');
		    		//alert("test " +tmp[i].url)
		    		this.servers[i] = {url: tmp[i].url, name: tmp[i].name, enabled: false};
		    		testServer( parts[0], parts[1]).then( p => {
		    			//if ( parts[1] == '89') p = 'LB';
		    			this.servers[i] = {url: tmp[i].url, name: tmp[i].name, enabled: true};
		    			this.$forceUpdate();
		    			//alert(JSON.stringify(this.servers))
		    	 	});
		    	}
		    	testServer( sessionStorage.server, sessionStorage.port).then( p => {
		   		 	this.activeServerName = p;
		   		 	this.$forceUpdate();
			 	});
			});
	    	
		},
		activate( server)
		{
			let that = this;
	    	 let parts = server.split(':');
	    	 if ( parts[0] != sessionStorage.server || parts[1] != sessionStorage.port)
	    	 {
		    	 testServer( parts[0], parts[1]).then( p => {
		    		 sessionStorage.server = parts[0];
		        	 sessionStorage.port = parts[1];
		        	 
		        	 this.activeServerName = p;
		        	 this.activeServer = sessionStorage.server+":"+sessionStorage.port;
		        	 rebuildAPI();
		        	 that.$toast.success("New active server: "+ p, 'Ok', { timeout: 1000, position: "topRight" });
		      
	    	 	}).catch( e => { that.$toast.error("switch to this server not possiblke... maybe down", 'Ok', { timeout: 1000, position: "topRight" });})
	    	 }
		}
  },
  beforeDestroy() {
	  this.storeSettings();

  },
  created()
  {
	let restoreView = restore();
	
	// restoreView = {}
	if ( restoreView && restoreView.servers && restoreView.servers.length)
	{
		this.activeServer = restoreView.activeServer;
		this.activeServerName = restoreView.activeServerName;
		this.servers = restoreView.servers;
	}
	let srv = sessionStorage.server+":"+sessionStorage.port;
	//alert(!this.servers || srv != this.activeServer || !this.activeServerName)
	if ( !this.servers || srv != this.activeServer || !this.activeServerName)
	{
		this.reload();
	}
	
  },
  watch: {
	  activeServer: function() 
	     {
	    	 
	     },
    }
}
</script>

<style scoped>
.main {
	display: block;
	padding-left: 5pt;
	padding-bottom: 5pt;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}
.shadow {
    background-color: white !important;
	width: 100%;
    box-shadow: 5px 5px 8px 0 #444;
}
.selected{
	background-color: #03a9f4 !important;
	color: #eee;
	font-weight: bold;	
}
.button {
	box-shadow: 1px 1px 2px 0 #000;
	border-radius: 4px;
	height: 18pt;
}
.svButton  {
	background-color: #efe;
	padding: 2px !important;
	width: 32pt;
	margin-right: 1px !important;
	font-size: 12px;
}
.lbButton  {
	background-color: #cec;
	padding: 2px !important;
	width: 32pt;
	margin-right: 1px !important;
	font-size: 12px;
	font-weight: bold;	
}
.svButton:disabled  {
	background-color: #faa;
	
}

.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.white {
    //background-color: white !important;
}
.text {
 	font-size: 9pt;
}

.iButton:hover {color: #e8f0e8 !important;}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


