<template>
<vue-modal-2 :darkMode="false" :noFooter=false 
      :headerOptions="{title: 'Error'}" 
      :footerOptions="{ btn1: 'Close', disableBtn2:true,  btn1OnClick:closeErrorPU}"
      name="modal-errorPU"  @on-close="closeErrorPU">
      <div class='puInfo' style='padding-top: 0em; padding-bottom: 1em;'>
        <b>{{ errorType }}</b><br/>
        {{ getMessage() }}
      </div>
</vue-modal-2>
</template>
<script>


export default {
  name: "ErrorPopUp",
  components : {
    
  },
  props: {
    error: [Object,String],
    errorType: String
  },
  data() {
      return {
      	
      }
  },
  methods: {
  	getMessage()
  	{
  		var text = this.error.message;
        if ( this.error.response && this.error.response.data)
    	{
    		text = this.error.response.data;
    	}
    	return text;
  	},
  	closeErrorPU() { 
      	this.$vm2.close('modal-errorPU');
      },
  },
  watch:
  {
  	error: function() { this.$vm2.open('modal-errorPU'); }
  }
 }
 </script>
 <style>
 .vm2__header div h4 { font-weight: bold; }
 </style>