<template>
  <div
    class="context-menu"
    ref="popper"
    v-show="opened"
    tabindex="-1"
    v-click-outside="close"
    @contextmenu.capture.prevent>
    <ul>
      <slot :contextData="contextData" />
    </ul>
   
  </div>
</template>

<script>
import Popper from 'popper.js';
import ClickOutside from 'vue-click-outside'
import { detectOverflow } from '@popperjs/core';


// @vue/component
export default {
  name: "ContextMenu",
  props: {
    offsetY: { type: Number, default: 0 },
    offsetX: { type: Number, default: 0 }
  },
  components: {
 
  },
  data() {
    return {
      opened: false,
      contextData: {},
    };
  },
  directives:{
    ClickOutside,
  },
  computed: {
    isVisible() {
      return this.opened;
    },
  },
  methods: {
    open(evt, contextData, offsetX, offsetY, placement) {
      this.opened = true;
      this.contextData = contextData;
      let plcm = 'left';
      if ( placement )
      {
    	  plcm = placement;
      }
      this.placement = placement;
      let oX = ( offsetX ) ? offsetX : 0;
      let oY = ( offsetY ) ? offsetY : 0;
      if (this.popper) {
        this.popper.destroy();
      }
      
      this.popper = new Popper(this.referenceObject(evt,oX+this.offsetX,oY-this.offsetY), this.$refs.popper, {
      //this.popper = new Popper(this.referenceObject(evt,oX,oY), this.$refs.popper, {
        placement: plcm,
        container: 'body',
        trigger: 'hover',
        html: true,
        modifiers: {
            preventOverflow: {
                // tried these individually and in various combinations:
                enabled: false,
                boundariesElement: 'body',
                escapeWithReference: true
            }
        }
      });
      
      this.popper.update();

       // Recalculate position
      this.$nextTick(() => {
        this.popper.scheduleUpdate();
      });
      
    },
    close() {
    
      this.opened = false;
       if (this.popper ) {
	       try { this.popper.close(); this.popper.destroy(); } 
	       catch(e) 
	       {
	       //
	       }
      }
      this.contextData = null;
      
    },
    referenceObject(evt,oX,oY) {
      
      const left = evt.clientX-oX;
      const top = evt.clientY-oY;
      const right = left + 1;
      const bottom = top + 1;
      const clientWidth = 1;
      const clientHeight = 1;
      
      
      function getBoundingClientRect() {
        return {
          left,
          top,
          right,
          bottom,
        };
      }

      const obj = {
        getBoundingClientRect,
        clientWidth,
        clientHeight,
      };
      return obj;
    },
  },
  beforeDestroy() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },
};

</script>

<style lang="scss" scoped>

.context-menu {
    position: absolute;
    z-index: 999;
    overflow: hidden;
    //background: rgb(240, 244, 244);
    background-color: #fff;
    border-radius: 4px;
    padding-top: 2pt;
    padding-bottom: 2pt;
    border: .5px solid #aaa;
    box-shadow: 4px 4px 4px 0 #aaa;
    
    &:focus {
        outline: none;
    }

    ul {
      padding:0px;
      margin:0px;
    }
}


</style>