<template>
  <div >
	<GFWEditorRouter v-show='showEditorDetail' ref='geditor'
	    @close="close" 
	    @closeUntilPosition="closeUntilPosition"
	    @update="passUpdate"
	    @insert="passInsert"
	    :path=path
	    :reload='true'
	    :entityMap=detailEntityMap
	    :metadata="metadataDetail"
	    :counter="detailCounter" 
	    :mapping=allDetailIDs
	    :time="getTime()"
	    :dataRecord=selectedDetail>
   </GFWEditorRouter>  
  </div>
</template>
 
<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, userAPI, myTimer, receiveToken, myName} from '../variables.js';
import store from "@/store";


export default {
  name: 'GFWEOpenEditor',
  props: {
   	 path: { type: Array, default: () => [] },
   },
  data: function () {
    return {
   	
      allDetailIDs: [],
      detailEntityMap: {},
      selectedDetail: {},
      metadataDetail: {},
      detailCounter: 0,
      showEditorDetail: false,
    }
  },
  methods: {
    closeUntilPosition(p)
	    {
	        this.$emit("closeUntilPosition", p)
	    },
	close() {
  		this.showEditorDetail = false;
		this.$emit("closed")
  	},
  	getTime()
  	{
  		return new Date().getTime();
  	},
	passUpdate(entity, record) {
        this.updateCount++;
    	this.$emit( "update", entity, record);
    },
    passInsert(entity, record) {
        this.updateCount++;
    	this.$emit( "insert", entity, record);
    },
	open( module, dataId, listener)
	    {
    	
	        if ( module  )
	        {
		    	this.selectedDetail = {};
		    	this.metadataDetail = {};
		    	//alert(metadata.name)
		    	let that = this;
		    	let time = new Date().getTime();
		    	let mapperLoad = new Promise(function (resolve, reject) {
			    let url = fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+module+"/allEntitiesWithDate/null/true/1";
			    const moduleId = parseInt(module);
			    if ( !isNaN(moduleId))
			    {
			    	url = fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+moduleId+"/allEntitiesByIdWithDate/null/true/1";
			    }
				HTTP.post( url, [dataId])
		        		 .then( response => 
		                 {
		                 	that.detailEntityMap = response.data.entity2DataMap;
		                    that.metadataDetail = response.data.metadata;
		                    that.selectedDetail = response.data.data[0];
		                    if ( listener )
		                    {
		                    	that.selectedDetail = listener( that.selectedDetail);
		                    }
		                    if ( that.selectedDetail.extraData )
		                    {
		                    	for ( let i in that.selectedDetail.extraData )
		                    	{
		                    		that.selectedDetail[i] = that.selectedDetail.extraData[i];
		                    		//alert("<"+i+">" + "->" + that.selectedRecord[i])
		                    	}
		                    }
		                    
		                   
                   			that.$refs.geditor.open(that.selectedDetail, that.metadataDetail)
                   			that.showEditorDetail = true;
							that.$emit('opened')
		                          
		                 }).catch(e => {
		                        //console.log( JSON.stringify(e));
		                        that.$toast.error("loading data: " + e, 'Error', { position: "topRight" });
		                    });   
				   
			    	});
	    	}
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    },
  	
  },
  created()
  {
	
	
  },
  watch: {
        
    }
}
</script>

<style scoped>
.shadow {
   background-color: white !important;
   box-shadow: 5px 5px 8px 0 #444;
}
.white {
    background-color: white !important;
}
.task { cursor: pointer; margin: 4pt; margin-bottom: 2pt; margin-right: 0pt; margin-left: 0pt; display: block; background-color: #eef; 
          border: 1pt solid #ccc; width: 90%; height: 38pt; align-items: center; justify-content: center; 
          box-shadow: 5px 5px 8px 0 #444; 
          }
.task:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); font-weight: bold; }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>


