<template>
<div >
 
	<VueTableDynamic 
	   @cell-click='click' 
	   @cell-contextmenu="ctxMenu" 
	   @changed="getChangedSet" 
	   @loadAll="loadAll"
	   @search="changeSearch"
	   :enableSearch=withSearch
	   :params=getParams()>
	 </VueTableDynamic>
</div>
</template>

<script>
import { getAppStyle, setDarkMode, initAppMode, getBG, getFG } from '@/AppStyle.js';
export default 
{
name: "GTable",
props: {
    params: Object,
    height: { type: Number, default: 550 },
    withSearch: { type: Boolean, default: true },
  },
data() {
      return {
      }
  },
  methods: {
	getParams()
	{
		let p = {...this.params}
		p.enableSearch= this.withSearch;
		p.headerBgColor = getBG();
		p.headerFgColor = getFG();
		return p;
	},
    changeSearch(search) { },
    loadAll() { this.$emit("loadAll") },
  	click(rowIndex, columnIndex, data) { this.$emit("cell-click", rowIndex, columnIndex, data) },
  	ctxMenu(rowIndex, columnIndex, data) { this.$emit("cell-contextmenu",rowIndex, columnIndex, data)},
  	getChangedSet(array) { this.$emit("changed", array)}
  },
  watch:
  {
   withSearch: function() { this.getParams(); this.$forceUpdate(); }
  },
}
</script>
