<template>
<div :appendToBody="true" class="dateInputWrapper" style='font-size:9pt;'><input hidden v-model="value"/>
<Datepicker :appendToBody="true"  :format="customFormatter" @selected="selected" wrapper-class='comp_datepicker' input-class='comp_datepicker' :disabled='disabled' v-model="internalValue"/>
</div>
</template>
<script>
import Datepicker from 'vuejs-datepicker';
var momentTZ = require('moment-timezone');
export default {
  name: "DatePick",
  components : {
    Datepicker
  },
   props: {
    value: {
      type: [Date, String]
    },
    disabled: Boolean
  },
  data () {
    return {
      //displayedTime : {}
    }
  },
  computed: {
    internalValue: {
      get() {
        return new Date(momentTZ(this.value).utcOffset(0, true));
      },
      set(v) {
        this.$emit("input", v.toISOString().split('T')[0]);
        
      }
    }
   },
    methods: {
		customFormatter(date) {
		return momentTZ(date).tz(sessionStorage.timezone).format(sessionStorage.dateFormat);
		},
		selected(d)
		{
			this.$emit("change", d);
		}
     },
    created() {
       //this.displayTime()
    },

    watch:
    {
        //dateFormat: function()  { this.displayTime() },
        //internalValue: function()  { this.displayTime() }
    } 
 }
</script>
<style scoped lang="scss">
.dateInputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
    font-size: 11pt;
	white-space: normal;
	overflow: visible !important;
}
.comp_datepicker { font-size: 10pt; border: none !important; width: 100pt !important;}
.comp_small { font-size: 10pt; padding: 1; height: 10px;}
.comp_datepicker:disabled { font-size: 11pt; border: none; width: 100%; color: #888; background-color: transparent;}
input { border: none !important; }

</style>