<template>
<div class='inputWrapper'>
    <span class='span25' :style='getStyle()'  > 
    	<input  v-if="focus" ref='inp' :style='getStyle()' class='inputInt' 
		       @keypress.enter="enterPressed()" 
		       :disabled="disabled"
    	       @blur="focusLost()"
    	       @keyup.esc="escapePressed()"
    	       @keydown.tab="$emit('tab')"
			   @changed="$emit('changed')"
    	       @focus="$event.target.select()"
    	       type='text' v-model="internalValueNF" @click="$event.target.select()"/>
    	<input v-else ref='inp' :style='getStyle()' class='inputInt' @keydown.tab="$emit('tab')" @focus="focus=true" type='text' v-model.number="internalValue" />
    	<i18n-n :value="value" locale="de-DE"></i18n-n>
    </span>
    <span v-if="clipboard" class='span75'>
    <clipboard :value="value"><icon-write /></clipboard>
    </span>
</div>
</template>
<script>
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import clipboard from '@/components/Clipboard';
var numeral = require('numeral');
export default {
  name: "InputDouble",
  props: {
    value: Number,
    currency: Number,
    currencyObj: Object,
    dataMap: Object,
    nulls: Boolean,
    mark: { type: Boolean, default: false},
    clipboard: { type: Boolean, default: true},
    disabled: { type: Boolean, default: false}
    },
  data () {
    return {
    	focus: false,
		wasChanged: false,
		valueOrig: this.value
    }
  },  
  components : {
    clipboard
  },
  methods: {
      open() { 
      	//this.focus = true; 
      	this.$nextTick(function () {
	        this.$refs.inp.focus(); 
	      	this.$refs.inp.select();
         });
      },
      getStyle() { 
      	if ( this.mark )
      	{
      		return "background-color: #fdd;";
      	}
      	return getAppStyle();
      },
      escapePressed() { this.$emit("escape"); },
	  focusLost() { 
		this.focus=false; 
		this.$emit('input', this.value); 
		this.$emit('blur'); 
  		if ( this.wasChanged ) 
		{
			this.$emit("changed");
		}
	  },
      enterPressed() { this.$emit("enter"); this.$emit("changed");},
      onChange() { }
  },
  computed: {
    internalValue: {
      get() {
        this.$emit("input", this.value )
        if ( this.currencyObj && this.currencyObj.code )
        {
        	return this.currencyObj.code+ " "+ numeral(this.value).format( sessionStorage.numberFormat);
        }
        else if ( this.currency && this.dataMap && this.dataMap.data )
        {
        	let crny =  this.dataMap.data.find(c=>c.id==this.currency);
        	if ( crny )
        	{
        		return crny.additionalColumn[0]+ " "+ numeral(this.value).format( sessionStorage.numberFormat);
        	}
        }
        return numeral(this.value).format( sessionStorage.numberFormat);
      },
      set(v) {
    	this.value = numeral(v).value();
		
        this.$emit("input", numeral(v).value());
		if ( this.value !== this.valueOrig)
		{
			this.valueOrig = this.value;
			this.wasChanged = true;

		}
      }
    },
    internalValueNF: {
      get() {
        this.$emit("input", this.value )
        return this.value;
      },
      set(v) {
        if ( v.endsWith('k') )
        {
        	v = numeral(v.substring(0, v.length-1)).value()*1000;
        }
        this.value = numeral(v).value();
        this.$emit("input", numeral(v).value());
		if ( this.value !== this.valueOrig)
		{
			this.valueOrig = this.value;
			this.wasChanged = true;
		}
      }
    }
  },
  mounted() {
    this.open();
	this.focus = false;
  },
  created() {
	  this.focus = false;
	  this.valueOrig = this.value;
  },
  updated() {
  	 
  },
  watch: {
   	//value: function() { this.iValue = 0; this.$set( this, 'iValue', this.value);}
  }
 }
</script>
<style lang="scss" scoped>
.inputInt { text-align: right;}
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}
.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 99%;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>