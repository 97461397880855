<template>
<div>   
	  <XModal v-if="pdf" :name="name" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
	  <div style='float: left; width: 40%; height: 22pt; border-bottom: 1pt solid grey; '>
	   <button title="turn left" class='btn' @click='rotate=(rotate-90)%360'><mdicon :width="16" name="file-rotate-left-outline" /></button>
	   <button title="turn right" class='btn' @click='rotate=(rotate+90)%360'><mdicon :width="16" name="file-rotate-right-outline" /></button>
	   &nbsp;
	   <button title="previous page" class='btn' :disabled='page<=1' @click='page--'><mdicon :width="16" name="chevron-left"/></button>
	   <button title="next page" class='btn' :disabled='page>=pageCount' @click='page++'><mdicon :width="16" name="chevron-right"/></button>
	   &nbsp;
	   <button title="download pdf" class='btn' @click='forceFileDownload(pdfDataPlain, title)'><mdicon :width="16" name="download"/></button>
	  </div>	
	  <div style='color: #000;  float: left; padding-top: 4pt; width: 40%; height: 22pt; border-bottom: 1pt solid grey; font-size: 8pt; '>
	  {{title}}
	   </div>	
	  <div style='float: right; width: 20%; height: 22pt; border-bottom: 1pt solid grey;'>
	    <span style='color: #000; font-size: 8pt; padding-top: 4pt;'>
	    Page: {{page}} of {{pageCount}}  
	    </span>
	   <button class='btn' style='float: right' @click='close()'><mdicon :width="16" name="close"/></button>

	   </div>	
	  <div style='display: block; width: 100%; height: calc(100vH-22pt) !important; overflow-y: auto;'>
	     <pdf @num-pages="pageCount = $event" :src="pdf" :page="page" :rotate="rotate"/>
		</div>	
	  </XModal>
	  
</div>
</template>


<script>
import {HTTP, opsAPI, fwAPI, invAPI, setReload, showError} from '@/variables.js';
import InputDateTime from '@/components/inputElements/InputDateTime';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputText from '@/components/inputElements/InputText';
import GSelectSimple from '@/components/GSelectSimple';
import Checked from '@/components/Ops/Checked';
import GConfirm from '@/components/GConfirm';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import pdf from 'vue-pdf'

import JQuery from "jquery";
let $ = JQuery;

export default {
  name: 'GOPS_SalesRequestCompletePopup',
  components : {
   pdf
  },
  props: {
    name: {
      type: String,
      default: 'PDFWidget'
    },
    
    height: {
      type: Number,
      default: 94
    },
    width: {
      type: Number,
      default: 33
    }
  },
  data () {
    return {
		pdf: "",
		pdfDataPlain: "",
		title: "",
		rotate: 0,
		page: 1,
		pageCount: 0
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},
     // display
	 open(pdf, title) {
	    this.title = title;
	    this.pdf = 'data:application/pdf;base64,'+pdf;
	    this.pdfDataPlain = pdf;
	    this.page = 1;
	    this.rotate = 0;
	    this.$set( this, 'pdf', 'data:application/pdf;base64,'+pdf);
	    this.$nextTick(function () {
	    	this.$modal.show(this.name);
	    });
	 },
	 openFile(pdf, title) {
		    this.title = title;
		    this.pdf = pdf;
		    this.page = 1;
		    this.rotate = 0;
		    this.$set( this, 'pdf', pdf);
		    this.$nextTick(function () {
		    	this.$modal.show(this.name);
		    });
		 },
	 
	 // Direct download without display
	 download(pdf, title) 
	 {
	    this.forceFileDownload(pdf, title)
	 },
	 forceFileDownload(base64, name) 
		{  
	      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
	      const link = document.createElement('a');
	      link.href = url
	      link.setAttribute('download', name)
	      document.body.appendChild(link)
	      link.click()
	    },
	    base64ToArrayBuffer(base64) {
		    var binary_string = atob(base64);
		    var len = binary_string.length;
		    var bytes = new Uint8Array(len);
		    for (var i = 0; i < len; i++) {
		        bytes[i] = binary_string.charCodeAt(i);
		    }
		    return bytes.buffer;
		},
  },
  watch: {

  },
  created() {

  }
}
</script>
<style scoped >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}


.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
.appLeft {
	display:  inline-table; 
	text-align: right;
	width: 100%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.btn {
	background-color: #fff;
	border: none;
}
.btn:hover {
	background-color: #ccc;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.mainContent {
  position: relative;
  height: 100% !important;
  
  float: top;
} 
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.2em - 1px);
    padding-left: .3em;
    padding-right: .3em;
    padding-top: calc(0.2em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 10pt;
}
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
