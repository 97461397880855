<template>
<div class='inputWrapper'>
    <span class='span65'>
    <color-picker v-model="internalValue" position='width: 80'></color-picker>
    </span>
    <span class='span20' :style="{fontSize: '9pt', backgroundColor: internalValue}">
    {{internalValue}}
    </span>
    <span class='span15'>
    <clipboard v-model="internalValue"><icon-write /></clipboard>
    </span>
    

</div>
</template>
<script>
import clipboard from '@/components/Clipboard';

export default {
  props: {
    value: String,
    disabled: Boolean,
    mark: { type: Boolean, default: false},
    border: { type: Boolean, default: false}
  },
  components : {
    clipboard
  },
  methods: {
      getStyle() { 
      	if ( this.mark )
      	{
      		return "background-color: #fdd;";
      	}
      	return "";
      },
      showColorPicker( event, item)
	  {
	    	this.$vm2.open('modal-selectColumns');
	    },
	    close() {
	    	this.closeColorPicker();
	    },
	    closeColorPicker()
	    {
	    	this.$vm2.close('modal-selectColumns');
	    },
      enterPressed() { this.$emit("enter"); }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left;}
.inputBorder { font-size: 12pt; padding: 2pt; text-align: left; border: 1pt solid #ccc; width: 80%;  }

.span65 { display: inline-block; width: 65%}
.span20 { display: inline-block; width: 20%}
.dist5 { margin-left: 4pt; }
.span15 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>