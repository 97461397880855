<template>
<div class='inputWrapper'>
    <span class='span25'>
    <input v-if="border" class="inputBorder url" :style='getStyle()' :disabled=disabled 
    	@change="changed" 
    	@keypress.enter="enterPressed()" 
		@dblclick="open()"
    	@focus="$event.target.select()" v-model="internalValue"/>
    <input v-else class="inputText url" :style='getStyle()' :disabled=disabled 
    	@change="changed" 
    	@keypress.enter="enterPressed()" 
		@dblclick="open()"
    	@focus="$event.target.select()" v-model="internalValue"/>
    </span>
    <span class='span75'>
    <span @click="open()" style="display: inline-flex; float: top; padding-right: 4pt; margin-top: 0pt;"><mdicon size="20" name='web'></mdicon></span>
    <clipboard v-model="internalValue"><icon-write /></clipboard>
    </span>
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
export default {
  name: "GFInputJSON",
  props: {
    value: String,
    name: { type: String, default: "layout"},
    disabled: Boolean,
    mark: { type: Boolean, default: false},
    border: { type: Boolean, default: false}
  },
  components : {
    clipboard
  },
  methods: {
      getStyle() { 
      	if ( this.mark )
      	{
      		//return "background-color: #fdd;";
      	}
      	return getAppStyle();
      },
      enterPressed() { this.$emit("enter"); },
      changed() { this.$emit("changed", this.value); },
      open()
	  {
		let url = this.value;
		if ( !url.startsWith('http') && !url.includes('://'))
		{
			url = 'http://'+url;
		}
		window.open( url, '_blank').focus();
	  }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left;}
.inputBorder { font-size: 12pt; padding: 2pt; text-align: left; border: 1pt solid #ccc; width: 80%;  }

.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
}
.url {
	text-decoration: underline;
	color: blue;
	cursor: pointer;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>