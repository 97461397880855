<template>
<vue-modal-2 :darkMode="isDarkMode()"  :noFooter=false modalSize="md"
      :headerOptions="{title: 'Info'}" style='z-index: 7;'
      :footerOptions="{ btn1: 'Close', disableBtn2:true,  btn1OnClick:closeInfoPU}"
      name="modal-infoPU"  @on-close="closeInfoPU">
      <div class='puInfo' style=' padding-top: 0em; padding-bottom: 1em;'>
        <b>{{ infoType }}</b><br/>
        <div v-html="info" style='height: calc(60vh); overflow-y: scroll;'></div>
      </div>

</vue-modal-2>

</template>
<script>
import { getAppStyle, isDarkMode, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
var timers = [];
export default {
  name: "InfoPopUp",
  components : {
    
  },
  props: {
    time: Number,
    info: String,
    infoType: String
  },
  data() {
      return {
      	 closed: false,
      	 isDarkMode,getAppStyle
      }
  },
  methods: {
	
  	getMessage()
  	{
  		var text = this.info;
    	return text;
  	},
  	activateAgain() {
  	    clearInterval( timers.pop());
  	},
  	closeInfoPU() { 
  	    timers.push( setTimeout(this.activateAgain, 500));
      	this.$vm2.close('modal-infoPU');
      },
  },
  watch:
  {
  	time: function() { if ( this.time >  0 ) { this.$vm2.open('modal-infoPU'); }}
  }
 }
 </script>
 <style>
 .puInfo {}
 .vm2__header div h4 { font-weight: bold; }
 </style>