<template>
<div class='inputWrapper'>
     <div v-if="closed" style='width: 100%'>
          {{ value?value.title:'no restriction' }}
     </div> 
     <div v-else>
          	
			<span class='editorElement smallFont' style='width:15%;'>
    		  <b>{{value.type.name}}</b>
    		</span>
    		<span :title='getToolTippOnOff()' class='editorElement smallFont' style='width:8%;'>
    		
    		<button @click='setOnOff' :class='getOnOffStyle(value)' style='height: 20pt;'>{{getOnOff(value)}}</button>
            </span>
            <span class='editorElement' style='width:18%;'>
             
              	<GSelectEntity @selected="setOperator"  :multiple="false" entityTitle="Operator" :options="allOperators" :filters="value.type.operators" entityName="InventoryOperator" :visible="true" :disabled="false" :id=opId :index=index :time="new Date().getTime()">
              	</GSelectEntity>              	
             </span>
            
             
             <span class='editorElement' style='width:2%;'></span>
             <span v-if='isSingle(value)'>
	             <span v-if='isOption(value)' class='editorElement' style='width:45%;'>
	                
	              	<GSelectEntity @selected="setValues"  :multiple="isMultiple(value)" 
	              	               :entityTitle=title :entityName=value.type.entityName 
	              	               :id=value.values.values
	              	               :visible="true" :disabled="false">
	              	</GSelectEntity>
	             </span>
	             <span v-else-if='isWeekdays(value)' class='editorElement' style='width:45%;'>
	              	<InputWeekdays  @enter="update()" :time="new Date().getTime()"
	                            v-model="valueFrom" @change=update :disabled="false">
	              	</InputWeekdays>
	             </span>
	             <span v-else-if='isDate(value)' class='editorElement' style='width:45%;'>
	              	<InputDatePick  @enter="update()" :time="new Date().getTime()"
	                            v-model="valueFrom" @change=update :disabled="false">
	              	</InputDatePick>
	             </span>
	             <span v-else-if="isString(value)" class='editorElement' style='width:45%;'>
	             <InputText  
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueFromStr"/> 
	             </span>
	             <span v-else-if="isInt(value)" class='editorElement' style='width:45%;'>
	             <InputInt 
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueFrom"/>
	             </span>
	             <span v-else-if="isDate(value) && isMultiple(value)" class='editorElement' style='width:45%;'>
				             X<InputDatePick :auto="false" 
				                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
				                            v-model="valueFrom"/>
	             </span> 
	             <span v-else-if="isDate(value)" class='editorElement' style='width:45%;'>
				             <InputDatePick :auto="false" 
				                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
				                            v-model="valueFrom"/>
	             </span> 
	             
             </span>
             <span v-else-if='isRange(value)'>
	             <span class='editorElement borderElement' style='width:20%;'>
	                    <InputTime v-if="isTime(value)" 
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueFrom"/>   
	                    <InputInt v-else-if="isInt(value)" 
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueFrom"/> 
	                    <InputText v-else-if="isString(value)" 
	                            @enter="update()" @change="update()" :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueFromStr"/> 
	                    <InputDatePick :auto="false" v-else-if="isDate(value)" 
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueFrom"/> 
	                         
	             </span>
                 <span class='editorElement' style='width:5%;'>{{value.operator.symbol}}</span>
                 <span class='editorElement borderElement' style='width:20%;'>
                    <InputTime v-if="isTime(value)" 
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueTo"/> 
              		<InputInt v-else-if="isInt(value)" :clipboard="false" :border="true"
                            @enter="update()" @change=update :disabled="false" 
                            v-model="valueTo"/> 
                    <InputText v-else-if="isString(value)" 
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueToStr"/> 
	                <InputDatePick :auto="false" v-else-if="isDate(value)" 
	                            @enter="update()" @change=update :disabled="false" :clipboard="false" :border="true"
	                            v-model="valueTo"/>

	                           
                    
                 </span>
             </span>
       </div>      
          <button @click="removeLine()">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#888" class="bi bi-x" viewBox="0 0 16 16">
	         <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
	    </svg>

	</button>
	
			
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import GSelectEntity from '@/components/GSelectEntity';
import {HTTP, fwAPI, invAPI, APIURL} from '@/variables.js';
import InputText from '@/components/inputElements/InputText';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import GImage from '@/components/GImage';
import InputSecret from '@/components/inputElements/InputSecret';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InputDouble from '@/components/inputElements/InputDouble';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import Switch from '@/components/Switch'

var numeral = require('numeral');
export default {
  name: "InputInventoryFilter",
  props: {
	index: Number,
    value: {},
    title: String,
    counter: Number,
    allOperators: Array,
    operatorId: Number,
    format: { type: String, default: "0,0.00"},
    disabled: Boolean
  },
  data(){
    return {
    	closed: true,
        opId: this.value.operator.id,
        operator: {},
        valueFrom: 0,
        valueTo: 0,
        valueFromStr: "",
        valueToStr: "",
    	entityData: Object,
    	values: []
    }
  },
  components : {
    GSelectEntity, InputInt, InputWeekdays, InputTime, InputText, InputDatePick
  },
  methods: {
     
      removeLine() { this.$emit("remove", this.index); },
      enterPressed() { this.$emit("enter"); },
      isMultiple(entityData) {
      	if ( entityData && entityData.operator && entityData.operator.operands && entityData.operator.operandsValue == 'many' )
      	{
      		return true;
      	}
      	return false;
      },
      isOption(entityData) {
      	if ( entityData && entityData.type.path && entityData.type.path.toLowerCase() === 'id' )
      	{
      		return true;
      	}
      	return false;
      },
      isSingle(entityData) {
      	if ( entityData && entityData.operator && entityData.operator.operands && (entityData.operator.operandsValue === 'one' || entityData.operator.operandsValue === 'many') )
      	{
      		return true;
      	}
      	return false;
      },
      isInt(entityData) {
      	if ( entityData && entityData.type.typeValue && (entityData.type.typeValue === 'Integer') )
      	{
      		return true;
      	}
      	return false;
      },
       isString(entityData) {
      	if ( entityData && entityData.type.typeValue && (entityData.type.typeValue === 'String') )
      	{
      		return true;
      	}
      	return false;
      },
      isTime(entityData) {
      	if ( entityData && entityData.type.typeValue && (entityData.type.typeValue === 'Time') )
      	{
      		return true;
      	}
      	return false;
      },
      isWeekdays(entityData) {
      	if ( entityData && entityData.type.typeValue && (entityData.type.typeValue === 'Weekdays') )
      	{
      		return true;
      	}
      	return false;
      },
      isRange(entityData) {
      	if ( entityData && entityData.operator && entityData.operator.operands && (entityData.operator.operandsValue === 'two') )
      	{
      		return true;
      	}
      	return false;
      },
      isDate(entityData) {
    	  if ( entityData && entityData.type.typeValue && (entityData.type.typeValue === 'Date') )
        	{
        		return true;
        	}
        	return false;
        },
      getOnOff(filterObject) {
      	if ( filterObject.positive )
      	{
      		return "IS";
      	}
      	else
      	{
      		return "BLACKLIST"
      	}
      },
      getOnOffStyle(filterObject) {
      	if ( filterObject.positive)
      	{
      		return "button on"
      	}
      	else
      	{
      		return "button off"
      	}
      },
      getToolTippOnOff() {
      	if ( this.value.positive )
      	{
      		return "switch to NOT (= allow everything, that is NOT part of this filter-line), to invert this filter-line"
      	}
      	else
      	{
      		return "switch to IS (= allow everything, that is part of this filter-line)"
      	}
      },
      update(val)
      {
    	this.valueFromStr = val;
      	this.setOp( this.value.operator)
      },
      setOnOff() {
        //this.value.positive = !this.value.positive;
        //console.log( this.index + " --> " + this.value.positive);
        this.$emit("onOff", this.index, ! this.value.positive); 
      },
      setOperator(operator) {
    	//console.log("setOperator>"+operator) 
    	if ( operator )
      	{
      		
      	    if ( ! this.value.operator || ! this.value.operator.id || (this.value.operator.id != operator.id) )
      	    {
      	    	let opData = this.allOperators.find(p=>p.id == operator.id)
      	    	
      	    	this.value.operator = opData;
	            this.setOp( opData);
	           
	        }
      	}
      },
      setStruct()
      {
    	this.$emit("setStruct", this.index)  
      },
      setOp( operatorData)
      {
      	
		//
		//console.log( "setOp( "+ JSON.stringify(operatorData.id)+","+operatorData.name+","+operatorData.operandsValue+")");
		this.opId = operatorData.id;
		this.value.operator = operatorData;
		this.$emit("operator", this.index, operatorData ); 
		//alert( "setOp( "+ operatorData.operandsValue+")");
      	if ( operatorData.operandsValue == "two" )
      	{
      	
      		if ( this.isString(this.value)) 
      		{
      			
      			this.$emit("valueFromTo", this.index, this.valueFromStr, this.valueToStr); 
      		}
      		else
      		{
      			
	      		this.$emit("valueFromTo", this.index, this.valueFrom, this.valueTo); 
	    	}
	    }
	    else if ( operatorData.operandsValue == "one" )
      	{
	    	//console.log( this.isString(this.value)+"/"+this.value.type.typeValue);
      		if ( this.isString(this.value)) 
      		{
      			//console.log("emit (values) "+this.index+ ","+ this.valueFromStr)
      			this.$emit("values", this.index, [this.valueFromStr], [this.valueFromStr]); 
      		}
      		else
      		{
      			this.$emit("values", this.index, [this.valueFrom], ); 
      		}
      	}
      	else
	    {
	        //alert("1>>" + JSON.stringify(this.values));
	    	this.$emit("values", this.index, this.values); 
	    	
	    }
      },
      setValues(values) {
        //alert( JSON.stringify(values))
      	if ( values )
      	{
      		//alert( JSON.stringify( values))
      		if ( Array.isArray(values) )
      		{  
      			this.$emit("values", this.index, values.map(p=>p.id), values.map(p=>p.label)); 
      		}
      		else
      		{
      		    //alert("2>>" + JSON.stringify(values));
      		    if ( values && values.id )
      		    {
      		    	this.$emit("values", this.index, values.id, values.label); 
      		    }
      		    else
      		    {
      	    		this.$emit("values", this.index, values); 
      	    	}
      		}
      	}
      },
      parseFilterObject()
      {
      	this.setOperator( this.value.operator );
      	let operator = this.value.operator;
      	//alert( operator.operandsValue+ " " + JSON.stringify(this.value.values))
      	
      	if ( operator.operandsValue == "two" )
      	{
	      	this.valueFrom = this.value.values.from; 
	      	this.valueTo = this.value.values.to;
	      
	      	this.valueFromStr = this.value.values.from; 
	      	this.valueToStr = this.value.values.to;
	    }
	    else if ( operator.operandsValue == "one" )
      	{
	    	//alert( JSON.stringify(this.value.values))
	    	this.values = this.value.values.values; 
      		this.valueFrom = this.value.values.value; 
      		this.valueFromStr = this.value.values.value ; 
      		if ( this.isDate(this.value))
	      	{
	      		this.valueFrom = new Date();
	      	}
      		if ( !this.value.values.values )
      		{
      			this.value.values.values = [ this.valueFrom];
      			this.values = this.value.values.values; 
      		}
      		//alert( JSON.stringify(this.values))
      	}
      	else
	    {
	    	this.values = this.value.values.values; 
	    }
      	//alert(this.valueFrom+"-"+this.valueTo)
	    this.$forceUpdate();
      }
  },
  computed: {
    
    },
  watch: {
      counter: function() { this.parseFilterObject();  },
      operatorId: function() { this.operator = this.allOperators.find( op => op.id === this.operatorId);},
      value: function() { this.parseFilterObject();  },
      valueFrom: function() { this.setOp( this.value.operator) },
      valueFromStr: function() { this.setOp( this.value.operator) },
      valueTo: function() { this.$emit("valueFromTo", this.index, this.valueFrom, this.valueTo); },
      valueToStr: function() { this.$emit("valueFromTo", this.index, this.valueFromStr, this.valueToStr); }
  },
  updated() 
  {
	  
	  if ( this.value && this.value.operator )
	  {
	  	this.opId = this.value.operator.id;

	  }
  },
  created() {
    this.parseFilterObject()
  },
 }
</script>
<style lang="scss" scoped>
.on { background-color: #ded; font-size: 7pt; width: 8em; font-weight: bold;}
.off { background-color: #000; color: #fcc; font-weight: bold; font-size: 7pt; width: 8em;font-weight: bold;}
.button:hover { background-color: #888; color: #fff;}
.inputInt { text-align: right;}
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}
.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: none;
    border-radius: 6px;
    padding: 0pt;
    width: 99%;
}

.inputButton { 
    border: 1pt solid grey;
}
.editorElement {
     display: inline-flex; 
     padding-bottom: 8pt;
     padding-top: 2pt;
     padding-right: 8pt;
 }
 .smallFont {
 	font-size: 9pt;
 }
.elementLabel {
    display: inline-flex;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    display: inline-flex;
     //border: 1pt solid yellow;
     width: 100%;
 }
</style>