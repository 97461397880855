<template>
<div>
<div style="margin-top: 1px; display: flex; flex-direction: row; border: 1px solid #aaa; ">

     <div style='display: none;'>
     {{tree}}
     </div>
     
     <div v-if="tree.joiner" style='display: block; width: 40pt !important; position: relative; flex-grow: 1; float: left;'
          @contextmenu="ctxJoiner($event, tree)">
     	<div style='position: absolute; top: 50%; transform: translateY(-50%);'>
     	<button  :class="'joinerButton '+tree.joiner+'-color'" 
     		     @click="clickJoiner($event, tree)"
     	         :style="getAppStyle()+'width: 36pt; margin: 1pt;'" >
    		  <b>{{tree.joiner}}</b>
    	</button>
    	</div>
     </div>
     
     <div style='float: left; display: block; width: 99% !important;'>
        <div v-if="tree.atomicLines && tree.atomicLines.length" 
             style='overflow: auto;'>
         
	    	  <div v-for="(li, lii) in tree.atomicLines" :key="'lpi'+lii">
		   
			       <FilterLine :parent="parent?parent:tree" :number="lii" :allTypes="allTypes" 
			            :allOperators="allOperators" :indent="indent" 
			            :line="li" 
			            :keepOpen="keepOpen"
			            @openCTX="ctx"
			            @addFilterToParentFL=addFilterToParent
			            @moveFilterToParentFL=moveFilterToParent
			            @copy="copyLine"
			            @remove="removeLine"
			            @update="update"/>
		       </div>
        </div>
        <div v-for="(ch, chi) in tree.children" :key="'ch'+chi">
			<FilterTree :parent="tree" 
			            @openCTXFT="ctx"
			            @openCTXJoinerFT="ctxJoiner"
			            @clickJoinerFT="clickJoiner"
			            @remove="removeLine"
			            @copyLine="copyLine"
			            @addFilterToParentFT=addFilterToParent
			            @moveFilterToParentFT=moveFilterToParent
			            :allTypes="allTypes"  
			            :keepOpen="keepOpen"
			            :forceUpdate=forceUpdate
			            :allOperators="allOperators" 
			            :indent="indent" 
			            v-model="tree.children[chi]"/>
		
		 </div>
		
        <FilterLine v-if="(!tree.atomicLines || !tree.atomicLines.length)" :parent="tree" :number="99" :allTypes="allTypes" 
		            :allOperators="allOperators" :indent="indent" 
		            :line="{}" 
		            :keepOpen="keepOpen"
		            @moveFilterToParentFL=moveFilterToParent
		            @addFilterToParentFL=addFilterToParent
		   />
     </div>
     

    
    
</div>
</div>
</template>
<script>
import FilterLine from '@/components/filter/FilterLine';
import { getAppStyle, setDarkMode, initAppMode, getBGColor } from '@/AppStyle.js';
export default {
  props: {
  	value: Object,
  	keepOpen: { type: Boolean, default: false},
  	forceUpdate: Number,
  	parent: Object,
  	allOperators: Array,
  	allTypes: Array,
  	indent: { type: Number, default: 0}
  },
 components : {
	 FilterLine
  },
  data () {
    return {
    	tree: {...this.value},
    	getAppStyle
    }
  },
  methods: {
	  ctx( evt, line, parent)
	  {
		  this.$emit( "openCTX", evt, line, parent)
		  this.$emit( "openCTXFT", evt, line, parent)
	  },
	  ctxJoiner( evt, parent)
	  {
		  this.$emit( "openCTXJoiner", evt, parent)
		  this.$emit( "openCTXJoinerFT", evt, parent)
	  },
	  clickJoiner( evt, parent)
	  {
		  this.$emit( "clickJoiner", evt, parent)
		  this.$emit( "clickJoinerFT", evt, parent)
	  },
	  
	  update( number, filter)
	  {
		  this.tree.atomicLines[ number] = filter;
		  this.$emit( "input", this.tree)
	  },
	  removeLine( line) {this.$emit("remove", line); },

	  addFilterToParent(type, parent, from) {
		  
		  this.$emit('addFilterToParent', type, parent, from)
		  this.$emit('addFilterToParentFT', type, parent, from)
	  },
      moveFilterToParent(type, parent, from) {
		  
		  this.$emit('moveFilterToParent', type, parent, from)
		  this.$emit('moveFilterToParentFT', type, parent, from)
	  },
	  copyLine( parent, line )  { this.$emit("copyLine", parent, line); }
  },
  watch: {
		value: function() {
			this.tree = {...this.value}
		},
		forceUpdate: function() {
			this.tree = {...this.value}
			this.$forceUpdate();
		}
  
	  },
  beforeMount() {
	  this.tree = {...this.value}
  }
  
 }
</script>
<style lang="scss" scoped>
.joinerButton{
 border: 1px solid grey;
 margin-right: 2pt;
 cursor: pointer;
}
.joinerButton:hover {
  background-color: #888 !important; color: #fff  !important;
}
.OR-color {	
  background-color: #16c616 !important;
  color: #000 !important;
  width: 20pt;
  border-radius: 10px;
}
.AND-color {
  background-color: #ff1616 !important;
  color: #000 !important;
  width: 20pt;
  border-radius: 10px;
}
</style>