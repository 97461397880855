<template>
<div class='inputWrapper' :style="getAppStyle()+'width:'+width+'% !important;'">
   <span v-if='clipboard'>
	    <span class='span25'>
		    <input v-if="border" class="inputBorder" :style='getStyle()' :disabled=disabled ref='inp'
		    	@change="changed" 
		    	@keypress.enter="enterPressed()" 
		    	@keyup.esc="escapePressed()"
		    	@blur="$emit('blur')" 
		    	@focus="$event.target.select()" v-model="internalValue"/>
		    <input v-else class="inputText" :style='getStyle()' :disabled=disabled ref='inp'
		    	@change="changed" 
		    	@keypress.enter="enterPressed()" 
		    	@keyup.esc="escapePressed()"
		    	@blur="$emit('blur')" 
		    	@focus="$event.target.select()" v-model="internalValue"/>
	    </span>
        <span v-if="clipboard" class='span75'>
          <clipboard v-model="internalValue"><icon-write /></clipboard>
        </span>
   </span>    
   
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
export default {
  props: {
    value: String,
    fontSize: { type: Number, default: 14},
    disabled: Boolean,
    width:  { type: Number, default: 100 },
    mark: { type: Boolean, default: false},
    search: { type: Boolean, default: false},
    clipboard: { type: Boolean, default: true},
    border: { type: Boolean, default: false}
  },
  data() {
      return {
      	copy: null,
      	init: true,
      	doSearch: false,
      	getAppStyle
      }
  }, 
  components : {
    clipboard
  },
  methods: {
      open() { this.$refs.inp.focus(); this.$refs.inp.select();},
      getStyle() { 
      	if ( this.mark )
      	{
      		return "font-size:" +this.fontSize+"px; background-color: #fdd;";
      	}
      	return "font-size:" +this.fontSize+"px;"+getAppStyle();

      },
      save()  {
      	if ( this.init ) { this.copy = this.value; this.init = false }
      },
      fire()
      {
    	  if ( this.search)
    	  {
    		this.$emit('search');
    	  }
    	  else
    	  {
    		  this.$emit('enter');
    	   }
      },
      enterPressed() { this.fire();   },
      escapePressed() { this.$emit("input", this.copy); this.$emit("escape"); },
      changed() { this.$emit("changed", this.value); }
  },
  created() {
    //this.copy = ""+this.value; 
    
    
  },
  mounted() {
    //this.copy = ""+this.value; 
    this.open();
    
  },
  computed: {
    internalValue: {
      get() {
        this.save();
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; }
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left; height: 100%; margin: 0; padding: 0; border: none; }
.inputTextNBNC { text-align: left; height: 20pt; margin: 0; padding: 0; border: none; }
.inputBorder { padding: 2pt; text-align: left; border: 1pt solid #ccc;  }
.span100 { display: inline-block; width: 100%; height: 100%;}
.span25 { display: inline-flex; width: 85%; height: 100% !important; }
.spanSX { display: inline-flex; width: 85%; height: 100% !important; }
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    display: inline-block;
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    background-color: transparent !important;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>