<template>
<div style='width: 100%;'>
<div :class='inputWrapperTZ()'>
<!-- <input :disabled="disabled" @keypress.enter="enterPressed()" @change="showTZ" v-model="internalValue"/> -->
<GSelect :visible="visible"
         :disabled="disabled"
         :entityMap="entityMap"
         v-model="value"
         @changed="selected"
         @open="openLink"
         :data="data" :id=id :metadata="metadata" :definition="definition" />
</div>
<span v-if=infoVisible class="info">current time: {{ timeInZone }}</span></div>
</template>
<script>
var momentTZ = require('moment-timezone');
import GSelect from '@/components/GSelect'
//const MomentTimezoneDataPlugin = require('moment-timezone-data-webpack-plugin');
export default {
  name: "TZInput",
   components : { GSelect },
  props: {
    disabled: Boolean,
    infoVisible: {type: Boolean, default: true},
    dateFormat: {type: String, default: sessionStorage.dateFormat},
    timeFormat: {type: String, default: sessionStorage.timeFormat},
    visible: Boolean,
    data: Object,
    id: Number,
    time: Number,
    entityMap: Object,
    metadata: Object,
    definition: Object,
    value: Number
  },
  data () {
    return {
      timeInZone : {},
      internalValue: {}
    }
  },
  computed: {
    
   },
    methods: {
      enterPressed() { this.$emit("enter"); },
      openLink(id, link)
      {
        this.$emit("open", id, link);
      },
      selected(selectedValue)
      {
          //alert( JSON.stringify(selectedValue));
          this.internalValue = selectedValue.label;
          this.value = selectedValue.id;
          this.showTZ();
          this.$emit("input", selectedValue.id);
          //this.$emit("tz", selectedValue.label);
      },
      inputWrapperTZ() {
      	if (this.infoVisible)
      	{
      		return "inputWrapperTZ";
      	}
      	return "inputWrapperTZFull";
      },
      showTZ()
      {
        
        var fmt = this.dateFormat+ " " + this.timeFormat + " zz";
        try
        {
            this.timeInZone = momentTZ(new Date()).tz(this.internalValue).format(fmt);
        } catch (e)
        {
            //this.timeInZone = momentTZ(new Date()).tz("Asia/Dubai").format(fmt);
            this.timeInZone = JSON.stringify(e);
        }
        return this.timeInZone;

      }
     },
    created() {
        
    },
    updated() {
        
    },
    watch:
    {
        dateFormat: function()  { this.showTZ() },
        timeFormat: function()  { this.showTZ() }
    } 
 }
</script>
<style scoped>
input { font-size: 12pt; border: none; width: 100%}
.info { color: #888; font-size: 9pt;}
.inputWrapperTZ { 
    //border: 1pt solid #eee;
    border-radius: 6px;
    padding: 2pt;
    width: 40%;
    display: inline-block;
    margin-right: 2ex;
}
.inputWrapperTZFull { 
    //border: 1pt solid #eee;
    border-radius: 6px;
    padding: 2pt;
    width: 90%;
    display: inline-block;
    margin-right: 2ex;
}
</style>