<template>
  <div>
  <li v-show=!disabled @click="clicked()" class="context-menu-item-dis" :style="'background-color: '+bgColor">
    <slot/>
  </li>
   <li v-show=disabled class="context-menu-item-dis">
    <slot/>
  </li>
  </div>
</template>

<script>

export default {
    props: {
            disabled: { type: Boolean, default: false },
            bgColor: { type: String, default: 'transparent' },
        },
    methods: {
    clicked() 
    { 
        //console.log("clicked")
        this.$emit('clicked');     
    }
    }
}
</script>

<style lang="scss" scoped>
.context-menu-item {
    display: block;
    position: relative;
    padding: 1px 1px;
    //background: rgb(215, 215, 215);
    background-color: #cff;
    
    border-radius: 0;
    color: #000;
    text-decoration: none;
    font-size: 11px;
    width: 100%;
    text-align: left;
    
    cursor: pointer;
    padding: 0px;
    padding-right: 20pt;
    padding-left: 20pt;
}
.context-menu-item-dis {
    display: block;
    position: relative;
    padding: 2px 2px;
    //background: rgb(215, 215, 215);
    //background-color: #ffc;
    border-radius: 0;
    color: #666;
    text-decoration: none;
    font-size: 11px;
    width: 100%;
    text-align: left;
    font-weight: bold;
    
    cursor: default;
    padding: 4px;
    padding-right: 20pt;
    padding-left: 20pt;
}
</style>

