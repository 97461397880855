<template>
  <div class="events container">
  <!-- 
    <h2 class="subtitle is-3">
    Check out some manuals and specs
    </h2>
     -->
    <div class="columns is-multiline">
      <div v-for="event in events" :event="event" :key="event.id" class="column is-one-quarter">
         <EventCard :event="event" />
      </div>
    </div>
  </div>
</template>
<script>
import EventCard from '@/components/EventCard';
import {HTTP, infoAPI, findServer} from '../variables.js';

export default {
  name: 'EventsList',
  components : {
    EventCard
  },
  data () {
    return {
      event: {},
      events: []
    }
  },
  methods: 
  {
	  load()
	  {
		  HTTP.get(infoAPI+"/all")
		    .then(response => {
		      //alert( JSON.stringify(response.data))
		      this.events = response.data
		    })
		    .catch(e => {
		    	delete sessionStorage.serverInit;
		    	findServer( this.load);
		    }) 
	  }
  },
  // Fetches posts when the component is created.
  created() {
    this.load();
  }
}
</script>
<style lang="scss" scoped>
  .events {
    margin-top: 50px;
    text-align: center;
  }
</style>