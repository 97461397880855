<template>
<div  :title="displayedTime">
<input hidden v-model="internalValue"/>
<input :style="'color: '+getFGColor('#444','#bbb')+';'+getSize()" :disabled='disabled' @change="calculateUTC" v-model="displayedTime"/>
</div>
</template>
<script>
import { getAppStyle, setDarkMode, initAppMode,getFGColor } from '@/AppStyle.js';
var momentTZ = require('moment-timezone');
export default {

   props: {
    value: Number,
    timeZone: { type: String, default: sessionStorage.timezone},
    disabled: Boolean,
    size: { type: String, default: "12pt"},
    defaultValue: {type: String, default: ""},
    dateFormat: {type: String, default: sessionStorage.dateFormat},
    timeFormat: {type: String, default: sessionStorage.timeFormat},
  },
  data () {
    return {
      displayedTime : {},
	  getFGColor
    }
  },
  computed: {
    internalValue: {
      get() {
        // console.log("get() "+ this.value);
        // console.log("get() "+ new Date(parseInt(this.value)).getTime());
        return this.value;
      },
      set(v) {
        // console.log("set() "+ v);
        //var date = momentTZ(v, this.dateFormat+ " " + this.timeFormat, this.timezone).toDate();
        this.$emit("input", v)
      }
    }
   },
    methods: {
      getSize()
      {
          return getAppStyle()+"font-size: "+ this.size+";" +this.disabled?"":"border: 1px solid #aaa; width: 100%;";
      },
      displayTime()
      {
        try
        { 
        if ( ! this.dateformat )
        {
        	this.dateformat = sessionStorage.dateFormat;
        } 
        if ( ! this.timeFormat )
        {
        	this.timeFormat = sessionStorage.timeFormat;
        } 
        if ( ! this.timeZone )
        {
        	this.timeZone = sessionStorage.timezone;
        } 
        let fmt = this.dateFormat+ " " + this.timeFormat;
        //console.log( "fmt --> " + fmt+ "/"+ this.timeZone);
        //console.log( "fmt --> " + sessionStorage.dateFormat+" "+sessionStorage.timeFormat+ "/"+ sessionStorage.timezone);
        let date = new Date(parseInt(this.internalValue));
        //console.log( "date --> " + date);
        if ( this.value === null )
        {
            this.displayedTime = this.defaultValue;
        }
        else
        {
            this.displayedTime = momentTZ(date).tz(this.timeZone).format(fmt);
            //console.log( " this.displayedTime --> " +  this.displayedTime);
            if ( this.displayedTime.startsWith("un") )
            {
            	let fmt = sessionStorage.dateFormat+" "+sessionStorage.timeFormat;
            	let date = new Date(parseInt(this.internalValue));
            	this.displayedTime = momentTZ(date).tz(sessionStorage.timezone).format(fmt);
            }
        }
        } catch(e){
            console.log(e);
            let fmt = sessionStorage.dateFormat+" "+sessionStorage.timeFormat;
            let date = new Date(parseInt(this.internalValue));
            this.displayedTime = momentTZ(date).tz(sessionStorage.timezone).format(fmt);
        }
      },
      calculateUTC()
      {
        var fmt = this.dateFormat+ " " + this.timeFormat;
        var date = momentTZ.tz(this.displayedTime, fmt, this.timeZone).toDate();
        // console.log("recalc() "+ date);
        this.internalValue = date.getTime();
      }
     },
    created() {
       this.displayTime()
    },
    watch:
    {
        timeZone: function()  { this.displayTime() },
        dateFormat: function()  { this.displayTime() },
        timeFormat: function()  { this.displayTime() },
        internalValue: function()  { this.displayTime() }
    } 
 }
</script>
<style lang="scss" scoped>
.info { color: #888; font-size: 9pt;}
input:disabled {
    border: none;
    font-size: 11pt;
    background-color: transparent;
    width: 100%;
}
</style>