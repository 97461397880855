import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import HomeEmpty from '../views/HomeEmpty.vue'
import Workbench from '../views/Workbench.vue'
import Forward from '../views/Forward.vue'
import Dummy from '../views/Dummy.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    
    {
      path: '/fw/:module',
      name: 'fw',
      component: Workbench
    },
    {
      path: '/workbench/:module',
      name: 'workbench',
      component: Workbench
    },
	{
	      path: '/index.php',
	      name: 'index',
	      component: Dummy
	    },
    {
      path: '/home/:ident',
      name: 'home',
      component: Home
    },
    {
      path: '/',
      name: 'homeEmpty',
      component: HomeEmpty
    },
    {
      path: '/sudoku',
      name: 'sudoku',
      component: () => import('../views/Sudoku.vue')
    },
    {
      path: '/list',
      name: 'list',
      component: () => import('../views/GFWE.vue')
    },
    {
      path: '/upload',
      name: 'upload',
      component: () => import('../views/upload.vue')
    },
     {
      path: '/about',
      name: 'about',
      component: () => import('../views/About.vue')
    },
     {
      path: '/asRun',
      name: 'Reconciliation/AsRunLog',
      component: () => import('../views/AsRun.vue')
    },
     {
      path: '/availsReport',
      name: 'Availability/Load Report',
      component: () => import('../views/AvailsReport.vue')
    },
	{
      path: '/specialReports',
      name: 'Special-Reports',
      component: () => import('../views/SpecialReports.vue')
    },
    {
      path: '/cornerPostions',
      name: 'CornerPosition/Monitor',
      component: () => import('../views/CornerPositions.vue')
    },
    {
      path: '/ratingsAnalyser',
      name: 'Availability/Ratings Analyser',
      component: () => import('../views/RatingsAnalyser.vue')
    },
    {
      path: '/importGrid',
      name: 'Import/Grid',
      component: () => import('../views/ImportSchedule.vue')
    },
	{
      path: '/importRatings',
      name: 'Import/Ratings',
      component: () => import('../views/ImportRatings.vue')
    },
	{
      path: '/importBookings',
      name: 'Import/Bookings',
      component: () => import('../views/ImportBookings.vue')
    },
    {
      path: '/login/:ident',
      name: 'login',
      component: () => import('../views/Login.vue')
    },
    {
      path: '/activeUsers',
      name: 'activeUsers',
      component: () => import('../views/ActiveUsers.vue')
    },
    {
       path: '/setPasswd',
       name: 'Change password',
       component: () => import('../views/changePW.vue')
    },
    {
      path: '/report1',
      name: 'Report View',
      component: () => import('../views/ReportView2.vue')
    },
    {
      path: '/reporter',
      name: 'Client Report',
      component: () => import('../views/Reporter.vue')
    },
    {
      path: '/dealReport',
      name: 'Deal Report',
      component: () => import('../views/DealReport.vue')
    },
    {
      path: '/dealAnalyser',
      name: 'Deal Analyser',
      component: () => import('../views/DealAnalyser.vue')
    },
    {
      path: '/reportSpotList',
      name: 'BI Analyser',
      component: () => import('../views/ReportSpotList.vue')
    },
    {
      path: '/reportSpotListDesigner',
      name: 'BI Designer',
      component: () => import('../views/ReportSpotListDesigner.vue')
    },
    {
      path: '/workflowOperationsSalesInput',
      name: 'Operation Optimisation/Sales/Data Entry',
      component: () => import('../views/TrafficOpsWorkflowSalesInputNew.vue')
    },
    {
      path: '/workflowOperationsTrafficAssign',
      name: 'Operation Optimisation/Traffic/Data Completion',
      component: () => import('../views/TrafficOpsWorkflowTrafficAssign.vue')
    },
    {
      path: '/workflowOperationsTrafficDelivery',
      name: 'Operation Optimisation/Traffic/Delivery',
      component: () => import('../views/TrafficOpsWorkflowTrafficDelivery.vue')
    },
	{
      path: '/materialEntry',
      name: 'Traffic Material/Entry',
      component: () => import('../views/CopyMaterialEntry.vue')
    },
    {
      path: '/invoiceMonthly',
      name: 'Invoicing View',
      component: () => import('../views/InvoiceView1.vue')
    },
    {
      path: '/invoiceDiscountChange',
      name: 'invoiceDiscountChange',
      component: () => import('../views/InvoiceViewDiscountChanges.vue')
    },
    {
      path: '/invoiceInd',
      name: 'Invoicing View Indi',
      component: () => import('../views/InvoiceViewInd.vue')
    },
     {
      path: '/search',
      name: 'Search View',
      component: () => import('../views/SearchView1.vue')
    },
    {
      path: '/help',
      name: 'Manual Help View',
      component: () => import('../views/manual.vue')
    },
    {
      path: '/rightsAssign',
      name: 'Assign Rights',
      component: () => import('../views/RightsView1.vue')
    },
    {
      path: '/dataMiner',
      name: 'Data Miner',
      component: () => import('../views/WorkbenchDataDriven.vue')
    },
    {
      path: '/bookMan',
      name: 'Traffic Manager',
      component: () => import('../views/BookMan.vue')
    },
    {
      path: '/heartBeat',
      name: 'Heart-Beat',
      component: () => import('../views/HeartBeat.vue')
    },
    {
      path: '/resetCache',
      name: 'TenantAdmin',
      component: () => import('../views/ResetCache.vue')
    },
     {
      path: '/scheduleView',
      name: 'Ratecard/Grid Viewer',
      component: () => import('../views/ScheduleView.vue')
    },
    {
      path: '/detailPlanning',
      name: 'Daily rundown',
      component: () => import('../views/DetailPlanning.vue')
    },
    {
      path: '/ChannelDashboard',
      name: 'Channel Dashboard',
      component: () => import('../views/ChannelDashboard.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('../views/Logout.vue')
    },
     {
      path: '/error/:msg',
      name: 'error',
      component: () => import('../views/Error.vue'),
    },
     {
      path: '/welcome/:token',
      name: 'welcome',
      component: () => import('../views/Welcome.vue'),
    },
    {
      path: '/main',
      name: 'main',
      component: () => import('../views/Main.vue'),
      beforeEnter: requireAuth,
      meta: { requiresAuth: true }
    },
    {
      path: '/data',
      name: 'date',
      component: () => import('../views/Main.vue'),
      beforeEnter: requireAuth,
      meta: { requiresAuth: true }
    },
    {
      path: '/event/:id',
      name: 'eventSingle',
      component: () => import('../views/EventSingle.vue'),
       meta: {
          requiresAuth: true
        }
    },
	{
	    path :'*',
	    component:Dummy
	}
  ]
})

function requireAuth (to, from, next) {
  if (! sessionStorage.token) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
	
    next()
  }
}

