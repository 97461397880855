var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vue-input-wrap",style:({
    backgroundColor: _vm.inputBackground
  }),on:{"mouseenter":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}},[_c('input',_vm._b({ref:"input",staticClass:"vue-input",class:{
      'placeholder-light': _vm.placeholderEffect === 'light',
      'placeholder-dark': _vm.placeholderEffect === 'dark',
      'prefix': _vm.$slots.prefix || _vm.prefixIcon,
      'suffix': _vm.$slots.suffix || _vm.suffixIcon
    },style:({
      color: _vm.inputColor,
      borderColor: _vm.borderColor
    }),attrs:{"placeholder":_vm.placeholder,"type":_vm.type},on:{"compositionstart":_vm.handleCompositionStart,"compositionend":_vm.handleCompositionEnd,"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange}},'input',_vm.$attrs,false)),(_vm.$slots.prefix || _vm.prefixIcon)?_c('span',{staticClass:"vue-input-prefix"},[_vm._t("prefix"),(_vm.prefixIcon)?_c('i',{staticClass:"iconfont",class:_vm.prefixIcon}):_vm._e()],2):_vm._e(),(_vm.$slots.suffix || _vm.suffixIcon)?_c('span',{staticClass:"vue-input-suffix"},[_vm._t("suffix"),(_vm.suffixIcon)?_c('i',{staticClass:"iconfont",class:_vm.suffixIcon}):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }