<template>
<div class='inputWrapper' :style="getAppStyle()">
    <span class='span25' :style="getAppStyle()+style">
    <input :disabled=disabled @keypress.enter="enterPressed()" :autocomplete="autoComplete" class='textInput' :style="getAppStyle()+style" :type='type' v-model="value"/>
    </span>
    <span class='span75' >
    <toggleViewIcon :disabled=disabled v-model="type" tooltip='display/hide readable text'></toggleViewIcon>
   
    <span class='dist5' />
    <clipboard :disabled=disabled v-model="value"></clipboard>
    </span>
</div>
</template>
<script>
import { getAppStyle, getAppStyleImp, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import clipboard from '@/components/Clipboard';
import toggleViewIcon from '@/components/ToggleViewIcon';

export default {
  props: {
    value: String,
    style: String,
	autoComplete: { type: String, default: "one-time-code"},
    disabled: Boolean
  },
  components : {
	    clipboard
	    , toggleViewIcon
	  },
  data() {
      return {
          type: 'password',
          getAppStyle, getAppStyleImp
      }
  },
  methods: {
      enterPressed() { this.$emit("enter"); }
  },
  updated() {
      //console.log("updated");
  },
  created() {
	
  },
  
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      }
    }
    },
    watch:
    {
       value: function() {this.$emit("input", this.value)}
    }
    
 }
</script>
<style lang="scss" scoped>

.textInput { font-size: 12pt; border: 1pt solid #ccc; width: 100%}
.span25 { display: inline-block; width: 85%; background-color: transparent !important;}

.dist5 { margin-left: 4pt; }
.span75 { display: inline-block; width: 15%; text-align: right; flow: right; background-color: transparent !important;}
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
    background-color: transparent !important;
}
.inputButton { 
    border: 1pt solid grey;
}
input { font-size: 12pt; border: none; }
input:disabled { font-size: 12pt; border: none; color: #888;}
</style>