<template>
  <div >
    <div :title='task.task' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+';display: block; white-space: wrap;'" class='task' @click='$emit("openTask", $event, "Task", task.id)'>
	        	<b :style="'font-size: '+fontSize+'px;'">{{task.name}}</b><br/>
	        	<div :style="'display: flex; margin-left: 2pt; float:left; font-size: '+fontSize+'px; color: #666; width: 60%;'">
	        	 (until {{task.dueDate}})
	        	</div>

	        	<div style='display: flex; margin-right: auto;  width: 38%;'>
	        	
	        	<progress-bar style=''
	              :options=PBOptions
	              :value="getPercent(task)"
	              />
				
	           </div>
	</div>
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, userAPI, myTimer, receiveToken, myName} from '../variables.js';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import store from "@/store";


export default {
  name: 'GFWETaskItem',
  props: {
   	task: Object,
   	shadow1: {type: Number, defaultValue: 2},
   	shadow2: {type: Number, defaultValue: 4},
   	fontSize: {type: Number, defaultValue: 10},
   	shadowColor: {type: String, defaultValue: "#444"}
   },
  data: function () {
    return {
    	getAppStyle,
		PBOptions: {
		  text: {
			color: '#fff',
			shadowEnable: false,
		    fontSize: 11,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#dd4d4d',
		    backgroundColor: '#bbb'
		  },
		  layout: {
		    height: 16,
		    width: 60,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 33,
		    zeroOffset: 8,
		    strokeWidth: 110,
		    progressPadding: 1,
		    type: 'line'
		  }
		 }
    }
  },
  methods: {
    getPercent( task)
    {
    	let i1 = new Date().getTime() - new Date(task.created).getTime();
    	let i2 = new Date(task.dueDate).getTime() - new Date(task.created).getTime();
    	//alert( 100*i1/i2); 
    	return Math.round(100*i1/i2);
    }
  },
  created()
  {
	
	
  },
  watch: {
        task: function() {
            //
		 }
    }
}
</script>

<style scoped>
.white {
    background-color: white !important;
}
.task { cursor: pointer; margin: 4pt; margin-bottom: 2pt; margin-right: 0pt; margin-left: 0pt; display: block;  
          border: 1pt solid #ccc; width: 90%; height: 38pt; align-items: center; justify-content: center; 
          
          }
.task:hover { background-color: #ccc !important; color: #fff; text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3); font-weight: bold; }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>


