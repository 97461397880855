<template>
<div>   
	  <XModal v-if='popupData.data'  :name="name" :resizable="true" :width="width+'%'" :draggable="true" >
      <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
	  <div class='appHead'>
             <div style='display: inline-flex; float: left; font-weight: bold; padding-top: 2pt; margin-right: 15pt;'>
             
             </div>
            
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft'>
            	History for {{printHeader(popupData.header, popupData.data) }}
                </div>	
                <div v-if="popupData.body.length==1" class='appRight' style='font-size: 9pt; font-weight: normal; color: #888;'>
                
                (1 entry)
             
                </div>
                <div v-else-if="popupData.body.length > 1" class='appRight' style='font-size: 9pt; font-weight: normal; color: #888;'>
                
                ({{popupData.body.length}} entries)
             
                </div>
            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" id='main-body'>
	  
	      <div :style='getTableStyle()' >
          <slot name="body">
            <div v-if="!popupData.body.length">
            no history entries found for {{printHeader(popupData.header)}}
            </div>
            <div v-else-if="popupData.header.column" class='mainContent' >
             
              <table class='hTable' cellspacing="7" cellpadding="7" style='font-size: 10pt;'>
                <tr>
                	<td class='tdXLarge'><b>{{popupData.header.column.column}}</b></td>
                	<td class='tdLarge'><b>time</b></td>
                	<td class='tdSmall'><b>login</b></td>
                	<td class='tdLarge' colspan=2></td>
                </tr>
              	<tr v-for="(h,hIdx) in popupData.body" :key='"tr_"+hIdx'>
              	    
              		<td v-if="popupData.header.column.editable" class='history tdXLarge' title='re-enter this value' @click="$emit('reEnter', popupData.header.column.column, h.change)" style='cursor: pointer;'>{{h.change}}</td>
              		<td v-else class='history tdLarge' style='color: #aaa;' >{{h.change}}</td>
              		<td v-if="h.record.lUpdate" class='tdLarge'><InputDateTime size="9pt" :disabled=true v-model=h.record.lUpdate /></td>
              		<td v-else style='color: #aaa;' class='tdLarge'>current value</td>
              		<td class='tdSmall'>{{getUser(users, h.record.lUserId, updateLogin)}}</td>
              		<td class='tdSmall'><button :disabled="!h.record.lUpdate" title='show full history record' class='button btn-small' @click="nodeToOpen($event,'History', h.record.id)">show</button></td>
              		<td class='tdSmall'><button :disabled='!popupData.header.column.editable' class='button btn-small' @click="$emit('reEnter', popupData.header.column.column, h.change)">re-enter</button></td>
              	</tr>
              </table>
			</div>
			<div v-else class='mainContent' >
             
              <table class='hTable' cellspacing="7" cellpadding="7" style='font-size: 10pt;'>
                <tr>
                	<td class='tdXLarge'><b>changes</b></td>
                	<td class='tdLarge'><b>time</b></td>
                	<td class='tdSmall'><b>login</b></td>
                	<td class='tdLarge' colspan=2></td>
                </tr>
              	<tr v-for="(h,hIdx) in popupData.body" :key='"tr_"+hIdx'>
              	    
              		<td class='history tdXLarge' title='re-enter' @click="$emit('reEnter', null, h.record)" style='cursor: pointer;'>{{getChange(h.record)}}</td>
              		
              		<td v-if="h.record.lUpdate" class='tdLarge'><InputDateTime size="9pt" :disabled=true v-model=h.record.lUpdate /></td>
              		<td v-else style='color: #aaa;' class='tdLarge'>current value</td>
              		<td class='tdSmall'>{{getUser(users, h.record.lUserId, updateLogin)}}</td>
              		<td class='tdSmall'><button :disabled="!h.record.lUpdate" class='button btn-small' title='show full history record' @click="nodeToOpen($event,'History', h.record.id)">show</button></td>
              		<td class='tdSmall'><button class='button btn-small' @click="$emit('reEnter', null, h.record)">re-enter</button></td>
              	</tr>
              </table>
			</div>
	      </slot>
	      </div>
	      </div>
    	</div>
	  </XModal>
	  
	  	<GFWEditorRouter v-if='showEditorDetail' @close="closeEditorDetail" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadataDetail" 
    :dataId="recordId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter> 

</div>
</template>


<script>
import {HTTP, fwAPI, invAPI, setReload} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import InputDateTime from '@/components/inputElements/InputDateTime';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import ClickOutside from 'vue-click-outside'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";

let $ = JQuery;
export default {
  name: 'HHV',
  components : {
   InputDateTime
  },
  props: {
    name: {
      type: String,
      default: 'HistoryWidget'
    },

  },
  data () {
    return {
		width: 40,
		popupData: {},
        updateLogin: 0,
        showEditorDetail: false,
        allEntities: [],
        entityMap: [],
        metadataDetail: {},
        recordId: 0,
        mapping: [],
        selectedRecord: {},
        users: [],
        getAppStyle
       
    }
  },
  directives:{
    ClickOutside,
  },
  
  methods: {
     close() {  this.$modal.hide(this.name) },
     getWidth() { return this.width+"%";},
     printHeader( header, data)
     {
     	if ( header.column && header.column.column)
     	{
     		return header.entity +" ("+ data.name+ ") "+ header.column.column;
     	}
     	return "'"+header.entity+"' ("+ data.name+ ")";
     },
	 open(popupData) {
	    
	    //this.popupData=JSON.parse(JSON.stringify(popupData));
	    this.popupData=popupData;
	    this.width=50;
	 	//alert( JSON.stringify(this.popupData));
	 	//this.$modal.show('x1');
	 	let that = this;
	 	setTimeout(function() {
		  that.$modal.show(that.name);
		}, 50);
	 },
     getChange(h)
     {
     	if ( h.changes )
     	{
     		let change = h.changes;
     	    change = change.replace(/{/g, '')
   			change = change.replace(/}/g, '')
   			change = change.replace(/"/g, '')
     		return change;
     	}
     	return "initial value";
     },
     getHeight() {
     	return $("#historyModal").innerHeight();
     },
     getTableStyle() {
        
     	return "overflow-y: scroll; height: calc(20vH)";
     },

     nodeToOpen( event, name, id)
	    {
	        this.mapping = [ {row: 0, dataId: id, rId: 0, visible: true}];
	    	this.allEntities = [ ];
	    	this.selectedRecord = {};
	        //
			this.recordId = id;
	    	this.metadataDetail = {};
	    	let that = this;
	    	let time = new Date().getTime();
	    	let mapperLoad = new Promise(function (resolve, reject) {

			//console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/2022-02-02/true/1");
	    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+name+"/allEntitiesWithDate/null/true/1", [id])
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		//console.log("LOADED rights in " + dur +"ms");  
	                    that.allEntities = response.data.data;
	                    Object.assign( that.selectedRecord, that.allEntities[0]);
	                    that.metadataDetail = response.data.metadata;
	                    that.entityMap = response.data.entity2DataMap;
	                    that.showEditorDetail = true;
	                          
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    	});
	    	
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    },
     closeEditorDetail()
	 {
	        this.showEditorDetail = false;
	 },
	 closeIf()
	 {
	 	if ( !this.showEditorDetail)
	 	{
	 		this.close();
	 	}
	 },
	 getUser(users, id)
     {
            if ( id === 0 )
            {
            	return "system";
            }
            else
            {
            	if ( users[id] && this.users[id].login)
            	{
            		return users[id].login;
            	}
            	let that = this;
            	let mapperLoad = new Promise(function (resolve, reject) {
	            	HTTP.get( fwAPI+"/findUser/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+id)
	                    .then( response => 
	                    {
							users[id] = response.data;
							
	                        resolve(users[id].login);
	                    }).catch(e => {
	
	                        that.$toast.error("getUser("+id+") failed", e)
	                    });
                });
                mapperLoad.then( login => { that.updateLogin++; return login;})
            }
     }
  },
  watch: {

  },
  created() {

  }
}
</script>
<style >

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.hTable {
  table-layout: fixed;
  width: 100%;;
}


.tdSmall {
  overflow: hidden;
  width: 5%;
}
.tdLarge {
  width: 25%;
  overflow: hidden;
}
.tdXLarge {
  width: 40%;
  overflow: hidden;
}
 .appLeft {
	display:  inline-table; 
	text-align: left;
	width: 50%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 50%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.history {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 400 !important;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
}
.btn-small {
	height: 14pt;
}
.button {
    background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;
}
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset grey;
    background-color: #e8f0e8;
}
.modal-wrapper {

  vertical-align: middle;
}
td { 
    padding-right: 8px;
}
.modal-container {

  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
