<template>
<div>
  

  
</div>
</template>
<script>

import Workbench from '@/views/Workbench';

export default {
  name: 'home',
  components: {

  },
   data () {
    return {
    }
  },
  created() {  
      let module = this.$route.params.module;
      console.log("->" + module);
      console.log("->" + "/workbench/"+module);
      this.$router.push("/workbench/"+module);
    
  }
}
</script>
<style lang="scss" scoped>

</style>