<template>
  <div class="event-card">
    <div :title='event.name' class="card" :style="{ backgroundImage: `url('${event.featuredImage}')` }">
      <div class="card-content" @click="open(event)">
        <h2 class="is-size-4 has-text-weight-bold">{{ event.name }}</h2>
        
        <span>{{ event.location }}</span>
      </div>
    </div>
      <PDFViewer name='pdfView' ref='pdfView' />
  </div>

</template>
<script>
import PDFViewer from '@/components/PDFViewer';
export default {
  props: [
    'event'
  ],
  components : {
	   PDFViewer
  },
  data() {
    return {
      
    }
  },
  methods: {
      open(h) {
           if ( h.link )
           {
        	   this.$refs.pdfView.openFile(h.link)
           }
      },
  }
}
</script>
<style lang="scss" scoped>
  .card {
    
    height: 200px;
    background-position: center;
    background-size: cover;
    text-align: center;
  }
  .card-content {
    padding-top: 50px;
    position: absolute;
    color: #000;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    padding: 10px;
    height: 200px;
    width: 100%;
    span {
      font-size: 18px;
      text-align: center;
      width: 100%;
      position: absolute;
      bottom: 10px;
      right: 0;
    }
    h2 {
      margin-top: 10px;
    }
  }
  .event-date {
    background-color: #151515;
    color: #FFF;
    font-size: .75em;
    padding: 2px 10px;
    position: absolute;
    top: 0;
    right: 0;
  }
</style>