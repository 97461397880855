<template>
<div :class="(hideBorder?'inputWrapperNoBorder':'inputWrapper')" :style="getAppStyle()" >
    
   <span :title="value?value.title:'no restriction'" :class="!hideButton?'span25':'span100'" :style="getAppStyle()" >
            <div  class="inputBorder" :disabled="disabled"
                  :style="'display: inline-block; padding-top: 4pt; font-size: 9pt; border-radius: 2px; height: 22pt;'+(hideTemplates?'width: 100% !important;':'width: 75% !important;')+'overflow: hidden; text-overflow: ellipsis;white-space:nowrap;'" 
            @click="openEdit">
               {{internalValue}}
            </div>
            
            <span v-if='!hideTemplates' :title="'Select filter from predefined grid-filter'" class="inputNoBorder" 
            style='vertical-align: top; margin-top: 0pt;  display: inline-block; padding: 0pt; font-size: 9pt; width: 25% !important;' 
            >
              <GSelectEntity  @selected="setFilter"  :multiple="false" 
		              	               :entityTitle="'Grid-Filter'" :entityName="'Inventory'" 
		              	               :id="filter" :noOpener="true" :nulls="true"
		              	               :valid="time"
		              	               :autoSelect="false"
		              	               :columnFilter=columnFilter
		              	               :visible="true" :disabled="disabled">
              </GSelectEntity>
            </span>
            
	    </span>
	    <span class='span75' v-if='!hideButton' >
	      <clipboard v-if='!hideTemplates' v-model="internalValue" class='button btn' :style="getAppStyle()"><icon-write /></clipboard>
	      <button v-if='!hideButton' :style="getAppStyle()" @click="openEdit" class='button btn'>
	          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
			  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
			</svg>
	      </button>
	    </span>

    
  
</div>
</template>
<script>
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import {HTTP, inventoryInlineAPI, showError } from '@/variables.js'
import ClickOutside from 'vue-click-outside'
import clipboard from '@/components/Clipboard';
import GSelectEntity from '@/components/GSelectEntity';
export default {
  props: {
    value: Object,
    parentRecord: Object,
    columnFilter: Object,
    column: String,
    disabled: Boolean,
    hideBorder: { type: Boolean, default: false},
    hideTemplates: { type: Boolean, default: false},
    hideButton: { type: Boolean, default: true},
    columnName: { type: String, default: "filter" },
    mark: { type: Boolean, default: false},
    border: { type: Boolean, default: false}
  },
  components : {
	  clipboard, GSelectEntity
  },
  data() {
  	return {
  	   time: new Date().getTime(),
  	   selectData: [],
  	   filter: 0,
  	   showColumnSelector: false,
  	   availableColumns: [],
  	   selected: [],
  	   selectedColumns: [],
  	   getAppStyle
  	}
  },
  directives:{
    ClickOutside,
  },
  methods: {
		passUpdate() {
			
		},
		close()
		{
			
		},
		setFilter(e)
		{
			//alert( JSON.stringify(e))
			if ( e && e.id )
			{
				
				HTTP.post( inventoryInlineAPI+"/getFilter/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+e.id)
       		 	.then( response => 
                	{
                		this.value = response.data;
        				this.$emit('input', this.value)
                	
					}).catch(e => {
		                showError( this.$toast, "getInventoryFilters() failed", e)
		                console.log(e.response)
		            });
				
			}
			//alert( JSON.stringify(e))
		},
		openEdit()
		{
			if (! this.disabled)
			{
				this.$emit("open", this.value, this.parentRecord, this.columnName, true );
			}
		}
  },
  computed: {
	    internalValue: {
	      get() {
	        return this.value?this.value.title:'no restriction'; //JSON.stringify( this.value)
	      }
	    }
  },
  created() {
	  this.filter = 0;
	  
  },
  watch: {
	  value: function() {
		 this.filter = 0;
		 this.time=new Date().getTime()
		 this.$forceUpdate();
		 //alert( JSON.stringify(this.value ))
		 //this.$emit("open", this.value, this.parentRecord, this.columnName, false);
	 },
  },
 }
</script>
<style lang="scss" scoped>
.noLineBreak {
    white-space:nowrap;
    text-overflow: ellipsis;
    text-overflow: hidden;
}
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; }
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left;}
.inputBorder { font-size: 12pt; padding: 2pt; text-align: left; border: 1pt solid #ccc; width: 80%;  }
.inputNoBorder { font-size: 12pt; padding: 1pt; text-align: left; width: 80%;  }
.inputBorder:disabled { text-decoration: line-through; }
.inputBorder:hover { text-shadow: 1pt 1pt 2pt #aaa;  }

.span25 { display: inline-block; width: 85%; color: #fff;}
.span100 { display: inline-block; width: 99%; color: #fff;}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    padding-bottom: 0pt;
    width: 100%;
}
.inputWrapperNoBorder { 
    padding: 0pt;
    padding-bottom: 0pt;
    width: 100%;
}

.inputButton { 
    border: 1pt solid grey;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
</style>