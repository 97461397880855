var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.width < 100)?_c('div',{ref:"container",staticClass:"vue-scrollbar-horizontal",style:({ 
      opacity: _vm.opacity,
      height: _vm.scrollbarSize + 'px',
      bottom: _vm.scrollbarOffset + 'px'
    }),on:{"click":_vm.onClick}},[_c('div',{ref:"scrollbar",class:['scrollbar-inner', _vm.dragging ? '' : 'scrollbar-transition'],style:({
        width: this.width+'%',
        left: this.scrolling + '%',
        backgroundColor: _vm.hovering ? _vm.hoverColor : _vm.color,
        height: _vm.scrollbarSize + 'px',
        borderRadius: _vm.scrollbarBorderRadius + 'px'
      }),on:{"mousedown":_vm.startDrag,"mouseenter":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }