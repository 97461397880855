<template>
<div id='dropZone' class='dropZone' :ref="'I'+confName"
     @drop="dropFile($event)"
     @dragenter.prevent="hoverLine('dropZone')"
     @dragover.prevent="hoverLine('dropZone')"
     @dragleave.prevent="leaveLine('dropZone')">
     
	<img :width="size" :height="size" :src="data"/>
	<GConfirm :ref="confName"/>
</div>
</template>
<script>
import JQuery from "jquery";
import GConfirm from '@/components/GConfirm';
import { HTTPMP, opsAPI,showError } from '@/variables.js';
let $ = JQuery;
export default {
  props: {
    data: String,
    size: { type: Number, default: 100 },
    entity: String,
    id: Number,
    column: String
  },
  components : {
  	GConfirm
  },
  data(){
	    return {
			confName: "x"    	
	    }
  },
  methods: {
		dropFile($event)
	    {
	      
	      event.preventDefault();
	      event.stopPropagation();

	      const files = event.dataTransfer.files;
	      const itemArray = [...files];
	     
	      const item = itemArray.find((i) => i.type.startsWith('image'));
	      let that = this;
	      //this.$refs[this.confName].confirm( {text: 'Upload '+ item.name, text2:'(Unsaved changes might be lost)', title: "Upload", button1: "Cancel", button2: "Upload"}).
	      that.$iosConfirm( 'Upload '+ item.name+'\n(Unsaved changes might be lost)').then(x => {
	       	  const formData = new FormData();
	       	  
	       	  that.leaveLine('dropZone');
		      formData.append('file', item);      
	          if (item) that.upload(formData, item.name);
	       }).catch((err) => { 
	       that.leaveLine('dropZone');
	       });
	    },
	    async upload(data, name) {

      let uploadFile = await this.uploadFile(data, name);
     
	    },
	    uploadFile(formData, name) 
	    {
	      let that=this;

	      HTTPMP.put( opsAPI+"/fileUpload4Entity/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.entity+"/"+this.id+"/"+this.column, formData)
		            .then( response => 
		            { 
		                that.$emit("reload");
		                that.$toast.success("File '"+name+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
		                that.$forceUpdate();
	      }).catch((err) => (showError( that.$toast, "File '"+name+"' not uploaded", err)))
	     
	
	    },
		hoverLine(line)
	    {
	    	$('#'+line).addClass('reqLineBG');
	    },
	    leaveLine(line)
	    {
	    	$('#'+line).removeClass('reqLineBG');
	    },
  },
  computed: {
    
    },
  created() {
  	this.confName = "conf"+Math.floor(Math.random() * 10000);  	
  }
 }
</script>
<style lang="scss" scoped>
.reqLineBG{
 border: 1pt solid green;
 display: block;
}
.dropZone {
	width: 100% !important;
	min-height: 10pt;
	display: block;
}
</style>