<template>
  <div>
    <div class='appHeadLine' style='border-bottom: 1pt solid #ccc; padding: 0pt;'>
    <div style='width: 100% !important; overflow: hidden;'>
    <div style='float: left'>
    <div class='breadcrumbItem' :style="getAppStyle()" v-for="(obj,idx) in backTo()" v-tooltip='"Back to \""+obj.name+"\""'  :key='"BC"+idx' @click='goTo(obj,idx)'>
    {{obj.name}}
    </div>
    <div class='breadcrumbItemCurrent' :style="getAppStyle()">
    {{myModule()}}
    </div>
    </div>
    <div style='float: right'>
    <WindowCloseBN @click="close"/>
    </div>
    </div>
    </div>
    
       <ColumnSelectorNonModalModel v-if='showColumnSelector' 
	        :metadata=metadata
			@selectedColumns=arrangeData
	        @close="showColumnSelector=false">
        
    	</ColumnSelectorNonModalModel>
    <div style="width: 100%; display: inlibe-block;">		
	    <div style="width: 25%; display: flex; float: left;">	
			
	    <InputTableSearch v-show="entity"
	                 :searchTermStruct="searchTermStruct" 
	                 :header="header"
	                 :counter="cnt"
	                 :topic="tr(entity)" 
	                 @change="setSearch"
	                 @enter="doSearch" 
	                 :usage='searchTerm'/>	
	                 </div>
	    <div style="width: 65%; display: flex; float: left;">
	    	<button :title="'Seach all '+tr(metadata.name)+' data in the database (displayed or not)'" 
	    	    :disabled="!searchTermStruct.searchTerm || !searchTermStruct.searchTerm.length" 
	 	        @click="doSearch" class='button tableButton' :style='getAppStyle()'><mdicon size="16" name="magnify"/> Search</button>
	 	    <button :title="'Remove this search'" 
	 	        :disabled="!searchTermStruct.searchTerm || !searchTermStruct.searchTerm.length"
	 	        @click="clearSearch(); doSearch()" class='button tableButton' :style='getAppStyle()'><mdicon size="16" name="magnify-remove-outline"/> Clear</button>
	    	<button :title="'Activate in-table-search (only on loaded/displayd data in the table)'" 
	 	        @click="switchTableSearch()" class='button tableButton' :style='getAppStyle()'><mdicon size="16" name="tab-search"/> Quick Table-Search {{ tableSearch?"Off":"On" }}</button>
	 	    <button :title="'reload the loaded data'"  
	 	        @click="getData()" class='button tableButton' :style='getAppStyle()'><mdicon size="16" name="reload"/> Refresh</button>   
			<button :title="'create excel export of the displayed data'"  
			    @click="getData4Export()" class='button tableButton' :style='getAppStyle()'><mdicon size="16" name="microsoft-excel"/> Excel</button>   
					 	     
						   
	 	    <button :title="'Load all data (this might take a while...)'" 
	 	          :disabled="entities.dataCount === entities.dataCountAll" 
	 	        @click="loadAllData" class='button tableButton' :style='getAppStyle()'><mdicon size="16" name="reload"/> Load all data</button>   
	 	     <div :class="'loaded ' + (entities.dataCount===entities.dataCountAll?'allLoaded':'partiallyLoaded')" >
		        loaded {{ formatNumber(entities.dataCount) }} of {{ formatNumber(entities.dataCountAll) }}
		     </div>
	    </div>
	</div>
    
    <GTable :withSearch="tableSearch"  @cell-click='click' @cell-contextmenu="ctxMenu" @changed="getChangedSet" :params="entities">
    </GTable>


    <GFWEditorRouter v-show='showEditor' @update="update" @insert="insert" @close="closeEditor" 
    :selectedIndex="selectedIndex" 
    :dataArray="entities.orig" 
    :mapping="mapping" 
    :entityMap="entityMap"
    :metadata="metadata" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter>
    
    <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
    
    <GFWEditorRouter v-show='showEditorDetail' @close="closeEditorDetail" 
    :metadata="metadataDetail" 
    :counter=detailCounter
    :entityMap="detailEntityMap"
    :time="new Date().getTime()" 
    :dataRecord="selectedDetail">
    </GFWEditorRouter>
    
 	
 	<span  v-tooltip="'Create new '+tr(metadata.name)">
 	<button :disabled="!metadata || !metadata.insertAllowed" 
 	        @click='newData' class='button' :style='getAppStyle()'>Create new {{myModule()}}</button>
 	</span>
 	&nbsp;
 	<span  v-tooltip="'Back to my Desktop'">
 	<button @click='$emit("clearAll")' class='button' :style='getAppStyle()'>My Desktop</button>
 	</span>  
 	
 	<ContextMenu ref="menu">
      <template v-if="metadata && contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="click(selectedRow)">
          Open {{tr(metadata.name)}}: {{ contextData.visualDisplay }} 
        </ContextMenuItem>
         <ContextMenuItem v-show="metadata.editable" @clicked="deleteRow(selectedRow)">
          Delete {{tr(metadata.name)}}: {{ contextData.visualDisplay }} 
        </ContextMenuItem>
		<ContextMenuSep/>
		<ContextMenuItem  @clicked="getData4Export()">
          Export {{tr(metadata.name)}} (Excel)
        </ContextMenuItem>
		<ContextMenuSep/>
        <ContextMenuItem v-for="linkIdx in getCreatables(creatables,contextData)" :key="linkIdx.entity" @clicked="newDetail(contextData, linkIdx.name)">
          New {{linkIdx.entity}} for {{ contextData.visualDisplay }} 
        </ContextMenuItem>
        <ContextMenuSep v-if="creatables.length > 0" />
        <ContextMenuItem v-show="metadata.insertAllowed" @clicked="newData()">
          Create new {{ tr(metadata.name) }} 
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem  @clicked="$emit('clearAll'); $ref.menu.close();">
          Close and back to favorites
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem  @clicked="getData()">
          Refresh
        </ContextMenuItem>
        <ContextMenuItem  @clicked="resetCache()">
          Reset cache and reload
        </ContextMenuItem>
		<ContextMenuItem  @clicked="closeCTM(); showColumnSelector=true;">
         Arrange Columns
        </ContextMenuItem>
       </template>
    </ContextMenu>
    <vue-modal-2 :darkMode="false" :noFooter="false" 
      :headerOptions="{title: 'Reference check'}" 
      :footerOptions="{ btn1: 'Close', disableBtn2:true,  btn1OnClick:closeInfoPU}"
      name="modal-info"  @on-close="closeInfoPU">
      <div class='puInfo' style='padding-top: 0em; padding-bottom: 1em;'>
        Found linked record: 
		<li v-for="item in infoLinkedRecords" :key="item.id">{{item.linkedEntity+": " +item.linkedRecord}}</li>
      </div>
   </vue-modal-2>
    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'Delete'}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Delete', btn1OnClick:closeDeleteConfPU, btn2OnClick:deleteConfirmed}"
       name="modal-deleteConf"  @on-close="closeDeleteConfPU">
      <div class='puInfo' v-if="metadata" style='padding-top: 1em; padding-bottom: 1em;'>
        Delete {{metadata.name}}: {{selectedRecord.visualDisplay}}
      </div>
   </vue-modal-2>
  <PDFViewer name='pdfView' ref='pdfView' />
  <ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>  	
  </div>
</template>

<script>
import {HTTP, fwAPI, setReload, unregisterReload, formatNumber, showError} from '@/variables.js';
import InputTableSearch from '@/components/inputElements/InputTableSearch';
import { goBack, backTo } from '@/breadCrumb.js';
import { getAppStyle } from '@/AppStyle.js';
import { printTime2 } from '@/basicTimeFN.js';
import ContextMenu from '@/components/ContextMenu';
import PDFViewer from '@/components/PDFViewer';
import ColumnSelectorNonModalModel from '@/components/ColumnSelectorNonModalModel';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import GTable from '@/components/GTable';
import ProgressBar from '@/components/ProgressBar';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import { tr } from '@/translate.js';
var momentTZ = require('moment-timezone');
export default {
  name: "FW",
  components : {
    ContextMenu, ContextMenuItem, ContextMenuSep, InputTableSearch, GTable, ColumnSelectorNonModalModel, WindowCloseBN, ProgressBar,PDFViewer
  },
  props: { 
  	entity: String,
	xtitle: String
  },
  data() {
      return {
         
         tableSearch: false,
         entities: { orig: [], data: [],
            header: 'row',
            height: 550,
            pagination: true,
            pageSize: 16,
            
            style: { "background-color": "#111" },
            enableSearch: true,
            dataCount: 0,
            dataCountAll: 0,
            sort: [0, 1, 2, 3, 4, 5, 6, 7,8,9,10,11],
            pageSizes: [5, 10, 15, 16, 18, 20, 25, 50, 100],
            rowHeight: 18,
            columnWidth: [
					{column: 0, width: "5%"}, {column: 1, width: "15%"}
                ],
            border: true },
        selectedRecord: {},
        selectedIndex: 0,
        detailCounter: 0,
        showColumnSelector: false,
        searchTermStruct: { column: "", columnNo: 0, searchTerm: ""},
        header: [],
        columns: [],
        cnt: 0,
        mapping: [], // row mapping according to visual table
        mappingReverse: [], // id -> rowNo
        row2IdMapping: [],  // rowNo -> id
        selectedRow: 0, 
        metadata: {},
        optionMapping: [],
        entityMap: {},
        showEditor: false,
        loadAll: false,
        contextData: {},
        creatables: [],
        selectedDetail: {},
        metadataDetail: {},
        showEditorDetail: false,
        detailEntityMap: { type: Object, default: () => {} },
        infoLinkedRecords: [],
        errorPU: {},
        errorTypePU: "",
        getAppStyle,
        tr,
        // progBar
        pbAction: "",
        pbTitle: "",
        pbData: {},
        formatNumber,
        showProgressBar: false,
        MODULE: this.$route.params.module
      }
  },
  methods: {
      ctxMenu(event, rowIndex) // , columnIndex, data) 
      { 
          if ( rowIndex > 0 )
          {
            var usr = this.entities.data[rowIndex];
            var user = this.entities.orig.find(p => p.id === usr[this.id]);
            var idx = this.mapping.find(p => p.rId==rowIndex).row;
            this.selectedRow = rowIndex;
            var record = this.entities.orig[ rowIndex - 1];
            this.$refs.menu.open(event, record )
           }
      },
      action( what, myId, api)
      {
     		this[what](myId, api)
      },
      backTo()
	  {
	  	return backTo();
	  },
	  goTo( path, idx )
      {
    	this.$emit('clearAll', this.$event, this.entity)
      },
      switchTableSearch()
      {
		  this.tableSearch = !this.tableSearch;
	  },
	  setSearch(s)
      {
		this.searchTermStruct = s;
	  },
      doSearch(s)
      {
		if ( s && s.searchTerm )
		{
			this.searchTermStruct = s;
		} 
		this.getData();
	  },
      close() { this.$emit('close', this.$event, this.entity)},
      closeCTM() { try { this.$refs.menu.close(); } catch(e){/**/}},
      myModule() { return ( this.xtitle ? this.xtitle : (this.metadata && this.metadata.menuTitle)?this.metadata.menuTitle:this.entity) ;},
      click(rowIndex, columnIndex, data) {
        this.closeCTM();
        this.selectedRecord = {};
        this.selectedIndex = this.mapping.find(p => p.rId==rowIndex).row;
        //alert(  rowIndex + "--> " + JSON.stringify( this.mapping.find(p => p.rId==rowIndex).row));
        //Object.assign( this.selectedRecord, this.entities.orig[ rowIndex - 1]);
        this.$set( this, "selectedRecord", this.entities.orig[ rowIndex - 1])
        this.showEditor = true;
        //alert( this.selectedIndex +"/"+JSON.stringify( this.selectedRecord));
    	//this.$refs.editor.open( this.metadata.name, this.selectedRecord.id)
      //console.log(this.entities.orig[rowIndex]['flag']);
      },
      findColumn(column) { 
           //console.log( column );
           var colObject=this.metadata.columns.find(p => p.column === column); 
           //console.log( colObject );
           if ( colObject ) { 
              return colObject; 
           } 
           else { 
              colObject=this.metadata.links.find(p => p.name === column); 
              if ( colObject ) { 
              	return colObject; 
              } 
              else {
                return {};
              }
           }
        },
      newData() {
   		this.closeCTM();
   		this.showEditor = false;
   		HTTP.get( fwAPI+"/entityById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.MODULE+"/"+0)
                    .then( response => {
                    this.selectedRecord = {};
                    Object.assign( this.selectedRecord, response.data);
                    
                    if ( this.selectedRecord.fromDate == null )
                    {
                    	this.selectedRecord.fromDate = new Date().toISOString().split('T')[0];
                    }
                    if ( this.selectedRecord.untilDate == null )
                    {
                    	this.selectedRecord.untilDate = "2099-12-31";
                    }
                    
                    //alert( JSON.stringify(this.selectedRecord));
                    this.showEditor = true;
                }).catch(e => {
                   this.error("Can't create new record ("+this.MODULE+") ", e);
                });

      },
      clearSearch()
      {
		this.$set( this, "searchTermStruct", {column: '', columnNo: 0, searchTerm: ''}); 
		this.cnt++;
		this.$forceUpdate(); 
	  },
       resetCache() {
        
          HTTP.get( fwAPI+"/clearCache/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.MODULE)
                    .then( response => {

                    this.getData();
                }).catch(e => {
                     this.error("Can't reset cache ("+this.MODULE+") ", e);
                });
         },
      closeEditor()
      {
         this.showEditor = false;
      },
      closeEditorDetail()
      {
         this.showEditorDetail = false;
      },

      initData(create) {
         if ( create || this.MODULE != this.entity)
         {
             this.MODULE = this.entity;
             this.loadAll = false;
             this.getData();
         } 
      },
      newDetail(data, link)
        {
            this.closeCTM();
			HTTP.post( fwAPI+"/createNewDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.MODULE+"/"+link, data)
                    .then( response => {
                    this.selectedDetail = response.data.data;
                    this.metadataDetail =  response.data.metadata;
                    this.detailEntityMap = response.data.entity2DataMap;
                    //console.log( JSON.stringify( this.selectedDetail));
					
					this.showEditorDetail = true;
                    //
                }).catch(e => {
                    this.error("Can't create new detail ("+link+") ", e);
                });
        	
        },
      closeInfoPU() { 
      	this.$vm2.close('modal-info');
      },
      closeDeleteConfPU() { 
      	this.$vm2.close('modal-deleteConf');
      },
      error(txt, err)
      {	
		//this.$toast.error(txt, 'Error', { position: "topRight" });
		showError( this.$toast, txt, err);
      },
      deleteConfirmed() {
      	this.closeDeleteConfPU();
      	let row = this.entities.data.find(r => r[0] == this.selectedRecord.id);
      	
      	HTTP.delete( fwAPI+"/delete/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.MODULE+"/"+this.selectedRecord.id)
                    .then( response => {
                    //console.log( response );
                    let index = this.mappingReverse[ this.selectedRecord.id ];
                    this.entities.orig.splice( this.selectedRowIndex - 1, 1);
                    var rowNo = 0;
			        this.mapping = [];
			        this.mapping.push({row: 0, dataId: 0 });
			        for(var row in this.entities.orig)
			        {
			          rowNo++;
			  		  this.mapping.push({row: rowNo, dataId: this.entities.orig[row].id, rId: rowNo, visible: true});
			  		  this.row2IdMapping[ rowNo ] = this.entities.orig[row].id;
			  		  this.mappingReverse[ this.entities.orig[row].id] = rowNo;
			  		}
					this.entities.data.splice( index, 1);
                }).catch(e => {
                    this.error("Can't delete "+this.MODULE+": "+this.selectedRecord.visualDisplay, e);
                });
      },
      deleteRow( rowIndex) { 
    	  this.closeCTM();
	      this.pbData = rowIndex;
	      this.pbAction = "deleteRowInternal";
	      this.pbTitle = "checking dependencies";
	   	  this.showProgressBar = true;
      },
      deleteRowInternal( myId, api) { 
        
        let rowIndex = this.pbData;
        let rec = this.entities.orig[ rowIndex - 1];
        this.selectedRecord = rec;
        this.selectedRowIndex = rowIndex;
        api.get( fwAPI+"/canBeDeleted/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.MODULE+"/"+rec.id+"/"+myId)
                    .then( response => {
                    //console.log( response );
                    let linked = response.data;
                    if ( linked && linked.length > 0 )
                    {
                    	this.infoLinkedRecords = [];
                    	let count = 0;
                    	let differentEntities = [...new Map(linked.map(item =>[item.linkedEntity, item.linkedEntity])).values()];
                    	//alert( JSON.stringify(differentEntities ))
                    	
                    	if ( differentEntities.length > 5 )
                    	{
                    		for ( let i in differentEntities )
	                    	{
	                    		this.infoLinkedRecords.push({id: count++, linkedEntity: differentEntities[i], linkedRecord: ""}); 
	                    		if ( count > 10 )
	                    		{
	                    			if ( linked.length > 10 )
	                    			{
	                    				this.infoLinkedRecords.push({id: count++, linkedEntity: "...", linkedRecord: "("+(differentEntities.length-10)+" more modules)"}); 
	                    			}
	                    			break;
	                    		}
	                    	}
                    	}
                    	else
                    	{
	                    	for ( let i in linked )
	                    	{
	                    		this.infoLinkedRecords.push({id: count++, linkedEntity: linked[i].linkedEntity, linkedRecord:linked[i].linkedRecord.visualDisplay}); 
	                    		if ( count > 10 )
	                    		{
	                    			if ( linked.length > 10 )
	                    			{
	                    				this.infoLinkedRecords.push({id: count++, linkedEntity: "...", linkedRecord: "("+(linked.length-10)+" more)"}); 
	                    			}
	                    			break;
	                    		}
	                    	}
                    	}
                    	this.showProgressBar = false;
                    	this.$vm2.open('modal-info');
                    }
                    else
                    {
                    	this.showProgressBar = false;
				        this.$vm2.open('modal-deleteConf');
                    }
                }).catch(e => 
                {
                	this.showProgressBar = false;
                	this.error("Can't delete "+this.MODULE+": "+this.selectedRecord.visualDisplay, e);
                });
       
      },
      update( entity, rec) 
      {
          if ( entity == this.metadata.name )
          {
	          // Visual data (table-row=
	          var d = this.entities.data.find(r => r[0] == rec.id);
	          Object.assign( d, rec);
	          var i = 0;
	          for(var cell in this.columns)
	          {
	                var x = rec[this.columns[cell]];
	                if ( this.optionMapping[ this.columns[cell]] )
	                {
	                    if ( Array.isArray(x))
                        {
                            var val = "";
                            for ( var xi in x )
                            {
                                val += this.optionMapping[ this.columns[cell]][x[xi]]+" ";

                            }
                            x = val;
                        }
                        else
                        {
                            x = this.optionMapping[ this.columns[cell]][x];
                        }
	                }
	                if ( x )
	                {
	                    d[i++] = x;
	                } 
	                else
	                {
	                    d[i++] = "";
	                }
	             }
	             // force visual update
	             this.entities.data.push( d );
	             this.entities.data.pop();
	             // Full data set
	             d = this.entities.orig.find(r => r.id == rec.id);
	             Object.assign( d, rec);

	       }
      },
      insert( entity, rec) {
          if ( entity == this.metadata.name )
          {
	          // Visual data (table-row=
	          this.entities.orig.unshift( rec);
	          var rowNo = 0;
	          this.mapping = [];
	          this.mapping.push({row: 0, dataId: 0 });
	          for(var row in this.entities.orig)
	          {
	           	rowNo++;
	  			this.mapping.push({row: rowNo, dataId: this.entities.orig[row].id, rId: rowNo, visible: true});
	  			this.row2IdMapping[ rowNo ] = this.entities.orig[row].id;
	  			this.mappingReverse[ this.entities.orig[row].id] = rowNo;
	  		  }
	          var cells = new Array()
	          var i = 0;
	          for(var cell in this.columns)
	          {
	                var x = rec[this.columns[cell]];
	                if ( this.optionMapping[ this.columns[cell]] )
	                {
	                    if ( Array.isArray(x))
                            {
                                var val = "";
                                for ( var xi in x )
                                {
                                    val += this.optionMapping[ this.columns[cell]][x[xi]]+" ";

                                }
                                x = val;
                            }
                            else
                            {
                                x = this.optionMapping[ this.columns[cell]][x];
                            }
	                }
	                if ( x )
	                {
	                    cells[i++] = x;
	                } 
	                else
	                {
	                    cells[i++] = "";
	                }
             }
             // force visual update
             var headLine = this.entities.data.shift();
             this.entities.data.unshift( cells);
             this.entities.data.unshift( headLine);
             
             // Full data set
	      }

      },
      getChangedSet(array) 
      {
         this.mapping = [];
         this.mapping.push({row: 0, dataId: 0 });
         for ( var rowIdx=1; rowIdx < array.length; rowIdx++ )
         {
         	var id = this.row2IdMapping[  array[rowIdx].index];
         	var visible = array[rowIdx].show;
			//console.log( JSON.stringify(array[rowIdx]));
         	//console.log( JSON.stringify({row: rowIdx, dataId: id, rId: this.mappingReverse[id] }) ); 
         	this.row2IdMapping[ this.mappingReverse[id] ]
         	this.mapping.push({row: rowIdx, dataId: id, rId: this.mappingReverse[id], visible: visible });
         	//console.log( JSON.stringify({row: rowIdx, dataId: id, rId: this.mappingReverse[id], visible: visible }));
         }
      },
      loadAllData() { this.loadAll = true; this.getData(); },
      getCreatables( links, data)
        {
        	var newArray = [];
        	for ( var i in links )
        	{
        		if ( ! links[i].createIf && !links[i].disabled)
        		{
        			newArray.push( links[i]);
        		}
        		else 
        		{
        			if ( data[ links[i].createIf ] && !links[i].disabled)
        			{
        				newArray.push( links[i]);
        			}
        		}
        	}
        	return newArray;
        },
        
        calcWidths(header)
	    {
			let wTot = 0;
			for ( var i in header )
     		{
	            let col = this.findColumn( header[ i]);
	     		wTot += col.width;
	     	}
	     	return wTot;
	  },
	  getData() {
		  this.closeCTM();
	      this.pbAction = "getDataInternal";
	      if ( this.MODULE )
	      {
	      	this.pbTitle = "reading "+tr(this.MODULE)+" records";
	      }
	      else
	      {
	      	this.pbTitle = "reading data";
	      }
	   	  this.showProgressBar = true;
      },
	  getData4Export() {
		  this.closeCTM();
	      this.pbAction = "getData4ExportInternal";
	      if ( this.MODULE )
	      {
	      	this.pbTitle = "reading "+tr(this.MODULE)+" records";
	      }
	      else
	      {
	      	this.pbTitle = "reading data";
	      }
	   	  this.showProgressBar = true;
	    },
      getDataInternal( myId, api) {
          setReload( this.getData);
          let that = this;
          let search = null;
          
          if ( this.searchTermStruct.columnNo )
          {
          		search = this.searchTermStruct.columnNo-1+":"+this.searchTermStruct.searchTerm;
          }
          else
          {
			  search = this.searchTermStruct.searchTerm;
		  }
          
          let filter = {search: search, limit: 200, loadAll: this.loadAll, wsId: myId};
          HTTP.post( fwAPI+"/getAllDataForTable/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.MODULE, filter)         
            .then( response => 
            { 
            	that.optionMapping = new Array();
                let respData = response.data;
				
                that.metadata = response.data.metadata;
                var folder = that.metadata.folder;
                folder.sort(function(t1, t2) {
					  return t1.no - t2.no;
					});
			    for ( var fn in folder)
			    {
			    	folder[fn].no = fn;
			    }
				that.metadata.folder = folder;	
                that.creatables = [];
	   	    	for ( var i in that.metadata.links)
	        	{
	        		if ( that.metadata.links[i].createable )
	        		{
	        			that.creatables.push( that.metadata.links[i]);
	        		}
	        	}	

                that.entityMap = respData.entity2DataMap;
                for ( let column in respData.column2EntityMap )
                {
                    
                    that.optionMapping[column] = [];
                    //console.log(column + " -->" + respData.column2EntityMap[column]);
                    let entity = respData.column2EntityMap[column];
                    
                    let array = respData.entity2DataMap[ entity].data;
                    //alert("MAP> "+column+"/"+entity+"/"+array.length);
                    //console.log( JSON.stringify(array));
                    for ( var opt in array)
                    {
                        that.optionMapping[column][ array[opt].id ] = array[opt].label;
                    }
                }

                that.entities.orig = respData.data;
                that.entities.data = respData.printables;
                //alert( JSON.stringify( respData.printables))
                that.entities.dataCountAll = respData.countAll;
                that.entities.dataCount = respData.count;
                that.showImage = false;
                
                //alert(JSON.stringify(that.entities.orig.phone))
                
               
				that.arrangeData()
				this.showProgressBar = false;	
                }).catch(e => {
					this.showProgressBar = false;	
                    that.error("loading table data for "+that.MODULE, e);
            });
            
        return that.entities;

      },
	  getData4ExportInternal( myId, api) {
	           
	            let that = this;
	            let search = null;
	           
	            if ( this.searchTermStruct.columnNo )
	            {
	            		search = this.searchTermStruct.columnNo-1+":"+this.searchTermStruct.searchTerm;
	            }
	            else
	            {
	  			  search = this.searchTermStruct.searchTerm;
	  		   }
	            let filter = {search: search, limit: 200, loadAll: this.loadAll, wsId: myId};
	            HTTP.post( fwAPI+"/getAllDataForExport/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.MODULE, filter)         
	              .then( response => 
	              { 
	              	  let data = response.data;
					  this.$refs.pdfView.download(data.data, data.name);
					  this.showProgressBar = false;	
				  }).catch(e => {
	  					this.showProgressBar = false;	
	                      that.error("create excel for "+that.MODULE, e);
	              });
	              

	        },
      arrangeData( )
      {
      		let that = this;
      		let rowNumber = 0;
            let specialCell = 0;
            that.header = new Array();
            that.columns = new Array();
            that.entities.data = [];
            that.entities.style = {cell: []};
            that.entities.columnWidth = new Array();
            //alert( JSON.stringify(that.metadata.listColumns));
      		let cellType = [];
                try
                {
                    let widthTotal = that.calcWidths( that.metadata.listColumns);
                    //alert( JSON.stringify(that.metadata.listColumns))
	                for( var cell in that.metadata.listColumns)
	                {
	                    let hCol = that.findColumn( that.metadata.listColumns[cell]);
	                    //alert( cell+"-->"+JSON.stringify(hCol.display))
	                    that.header.push( hCol.display);
	                    //alert( JSON.stringify(that.header))
	                    that.columns.push( hCol.column);
	                    let tableColumn = hCol.column;

	                    cellType[ cell] = hCol.editor;
	                    that.entities.columnWidth.push({column: parseInt(cell), width: Math.round((100*hCol.width)/widthTotal)+"%"});
	                    // {column: 0, width: "5%"}, {column: 1, width: "15%"}
	                    
	                    if ( hCol.editor == 'COLOUR' )
	                    {
	                    	 for( let line=0; line < that.entities.orig.length; line++)
               				 {
               				 	var value = that.entities.orig[line][tableColumn];
               				 	var scope = new Array();
               				 	scope[1]=rowNumber;
               				 	scope[0]=line+1;
               				 	
               				 	that.entities.style.cell[specialCell] = {scope: [scope], styles: {backgroundColor: value}};
               				 	specialCell++;
               				 }
	                    }
	                   
	                    rowNumber++;
                    }
                }
                catch ( e)
                {
                	that.error( "x>"+JSON.stringify(e));
                }
                that.entities.data.push(that.header);
                var rowNo = 0;
                that.mapping = [];
                that.mapping.push({row: 0, dataId: 0 });
                
                for(var row in that.entities.orig)
                {
                    rowNo++;
                    that.mapping.push({row: rowNo, dataId: that.entities.orig[row].id, rId: rowNo, visible: true});
                    that.row2IdMapping[ rowNo ] = that.entities.orig[row].id;
                    that.mappingReverse[ that.entities.orig[row].id] = rowNo;
                   
                    var cells = new Array()
                    for( cell in that.columns)
                    {
                    	let column=that.columns[cell];
                        let x = that.entities.orig[row][column];
                        //console.log(cellType[cell])
                    	if ( cellType[cell] === 'TIME' )
                    	{
                    		x = printTime2(that.entities.orig[row][column]);
                    	}
                    	else if ( cellType[cell] === 'DATETIME' )
                    	{
                    		let fmt = sessionStorage.dateFormat +" "+ sessionStorage.timeFormat;
                    		x = momentTZ(new Date( that.entities.orig[row][column])).tz(sessionStorage.timezone).format(fmt);
                        } 
                   
                        if ( !x && that.entities.orig[row].extraData )
                        {
                        	x = that.entities.orig[row].extraData[column];
                        	if ( x )
                        	{
                        		that.entities.orig[row][column] = x;
                        		that.entities.data[row][column] = x;
                        
                        	}
                        }
                        if ( that.optionMapping[ that.columns[cell]] )
                        {
                            if ( Array.isArray(x))
                            {
                                var val = "";
                                for ( var xi in x )
                                {
                                    val += that.optionMapping[ that.columns[cell]][x[xi]]+" ";
					
                                }
                                x = val;
                            }
                            else
                            {
                                x = that.optionMapping[ that.columns[cell]][x];
                            }
                        }
                        if ( cellType[cell] === 'INVENTORY' )
                    	{
                        	if (that.entities.orig[row][column])
                        	{
                    			x = that.entities.orig[row][column].title;
                        	}
                    	}
                        //console.log(row + " "+cell+" "+ that.columns[cell]+ " --> " + x);
                        if ( x )
                        {
                        	cells.push(x);
                        } 
                        else 
                        { 
                        	cells.push("");
                        }
                        //console.log("----> "+row[cell]);
                    }
            	   
                    that.entities.data.push(cells);
     				
                }
      }
  }, 
  watch:
  {
   entity: function() { this.initData(); },
   imgData: function() { this.showImage = true; }
  },
  destroyed() {
  	unregisterReload(this.getData);
  },
  
  updated() {
  this.initData(false);
  },
  created()
  {
    this.initData(true)
  }
};
</script>
<style lang="scss" scoped>
th div { padding: 5pt; width: 100%; height: 100%; border-bottom: 2pt solid grey; }
th div:hover { 
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    //border: 1pt outset grey;
    cursor: pointer;
    background-color: #e8f0e8; }
th { padding: 0pt; }
tr[marked=true] { 
    //text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
font-weight: bold; }

.withPaddingL { padding-left: 20pt;  padding-right: 5pt; }
.withPaddingR { padding-right: 20pt;  padding-left: 5pt; }
.breadcrumbItem:hover, .breadcrumbItemCurrent:hover {
	background-color: #888 !important;
	color: #eee !important;
	box-shadow: 4px 4px 5px 0 #66a;
	transition: all 0.4s;
   -webkit-transition: all 0.4s;
}
.tableButton {
	height: 24pt;
	font-size: 9pt;
	margin-top: 3pt;
	margin-left: 5pt;
}
.loaded
{
	font-size: 9pt; 
	padding-left: 10pt; 
	padding-top: 8pt;
}
.partiallyLoaded {
	color: #f66;
}
.allLoaded {
	color: #aaa;
}
</style>