import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
    accessPoint: {},
    login: false
  },
  mutations: {
    SET_ACCESSPOINT(state, accessPoint) {
      state.accessPoint = accessPoint;
    },
    SET_LOGIN( state, b) {
        state.login = b;
    }
  },
  actions: {
    setAccessPoint(context, accessPoint) {
      context.commit("SET_ACCESSPOINT", accessPoint);
    },
    setLogin(context, b) {
      context.commit("SET_LOGIN", b);
    }
  },
  getters: {
    accessPoint(state) {
      return state.accessPoint;
    },
    login(state) {
      return state.login;
    }
  }
});

