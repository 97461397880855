<template>
<div class='inputWrapper' >
   <div class='input80' >	
   <vSelect :options="selectData" :appendToBody="true" v-model="selectedValue" :multiple=multiple :selectable=isSelectable :disabled="isDisabled()" @input="setSelected" placeholder=''>  
        </vSelect>
    </div>	
    <div class='input20' >	
		<button disabled='true' class='button btn'>
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
			  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
			</svg>
		</button>
	 </div>	
</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {HTTP, fwAPI, accessPointAPI, APIURL} from '@/variables.js';
export default {
  name: "GSelectModel",
  props: {
    disabled: { type: Boolean, default: false },
    useCache: { type: Boolean, default: true },
    visible: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    value: String,
    index: Number,
    time: Number
  },

  
  components : { vSelect },
  data() {
  	return {
  	   selectData: [],
         selectedValue:  [],
         selectedIndex: 1,
         showEditor: false,
        header: [],
        text: ""
  	}
  },
  methods: {
    searchList(text) { 
    },
    maybeCreate() {},
    openDetail() {
    	this.$emit("open", this.selectedValue.id, this.linkData.name)
    },
    setSelected() {
    	this.$emit("selected", this.selectedValue);
    },
    tooltip() { return ( this.entityTitle) ? "Open " + this.entityTitle: {} },
    show() {
    alert(JSON.stringify(this.definition))},
    editor() 
    {
        this.showEditor = true;
    },
    isSelectable(option) { return option.visible; },
    isDisabled() { return this.disabled || (this.selectedValue.id && !this.selectedValue.visible) },
    closeEditor()
      {
         this.showEditor = false;
         //this.getData();
      },
    update() {},
    doSearch(text) {
    }
        
      
  },
  computed: {
  	// watcher() { return this.id+this.visible; } 
  },
  watch: {
      index: function()  { this.getData() },
      time: function()  { this.getData() },
      visible: function()  { this.getData() },
      selectedValue: function() 
      { 
          if (this.selectedValue) 
          {
            //console.log( "--> " + this.selectedValue.id+"/"+this.value);
            this.$emit("input", this.selectedValue.id );
            //console.log( "--> " + this.selectedValue.id+"/"+this.value);
            }
            else
            {
                this.$emit("input", null );
            }
      }
  },
  created() {
    //console.log( "GSELECTMODEL:visible> " + JSON.stringify(this.visible));
  	this.getData();
  },
  updated() {
    //console.log( "GSELECT:visible> " + JSON.stringify(this.visible));
    // console.log( "GSELECTMODEL:visible> " + JSON.stringify(this.visible));
    //this.getData();
  }
 }
</script>
<style  scoped>
.inputWrapper {
	width: 100% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
label, span{
        display: block;
    }
</style>