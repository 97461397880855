 <template>
   <div>
	    
		<div v-if="!weekLimits.limits || !weekLimits.limits.length" style='font-weight: bold; text-align:center; padding-top: 20%; font-size: 10pt;'>
			no week limits needed
		</div>	
		<div v-else>
        <div>
			<div style="width: 10%; font-size: 9pt; padding-left: 1pt; border-bottom: 1px solid grey; display: inline-block; padding-top:0pt;">week</div>
			<div style="width: 22%; text-align: right; font-size: 9pt; padding-left: 1pt; border-bottom: 1px solid grey;display: inline-block; padding-top:0pt;">max. budget</div>
			<div style="width: 3%; text-align: right; font-size: 9pt; padding-left: 1pt; border-bottom: 1px solid grey;display: inline-block; padding-top:0pt; color: transparent;">.</div>
			<div style="width: 15%; text-align: right; font-size: 9pt; padding-left: 1pt; border-bottom: 1px solid grey;display: inline-block; padding-top:0pt;">%</div>
			<div style="width: 15%; text-align: right; font-size: 9pt; padding-left: 1pt; border-bottom: 1px solid grey;display: inline-block; padding-top:0pt;">paid</div>
			<div style="width: 15%; text-align: right; font-size: 9pt; padding-left: 1pt; border-bottom: 1px solid grey;display: inline-block; padding-top:0pt;">free</div>
			<div style="width: 15%; text-align: right; font-size: 9pt; padding-left: 1pt; border-bottom: 1px solid grey;display: inline-block; padding-top:0pt;">spots</div>
		</div>
		<div style='overflow-y: auto; height: calc(20vH);'>
		<div v-for="(weekly,i) in weekLimits.limits" :key="'w'+i" style='height:18pt;'>
			<div title="set remaining budget" style="width: 10%; cursor: pointer; font-size: 8pt; padding-left: 1pt; display: inline-block; padding-top:0pt;" @click="adjustWeekly(weekLimits, weekly.week)">W-{{weekly.week}}</div>
			<div style="width: 22%; font-size: 8pt; padding-left: 1pt; display: inline-block; padding-top:0pt;"><InputFieldS :disabled="weekLimits.usePacing" v-model="weekly.budget"/></div>
			<div style="width: 3%; font-size: 8pt; padding-left: 1pt; display: inline-block; padding-top:0pt;" @click="weekly.budget=0"><mdicon size=14 name="close"/></div>
			<div style="width: 15%; text-align: right; font-size: 8pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{formatPercent(100.0*weekly.budget/budget)}}</div>
			<div style="width: 15%; text-align: right;font-size: 8pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{fmt(weekly.actualBudgetPaid)}}</div>
			<div style="width: 15%; text-align: right;font-size: 8pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{fmt(weekly.actualBudget-weekly.actualBudgetPaid)}}</div>
			<div style="width: 15%; text-align: right;font-size: 8pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{weekly.actualSpots}}</div>
		</div>
		</div>
		<div>
			<div style="width: 10%; font-size: 9pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">total</div>
			
			<div v-if="weekLimits.limits.map(s=>s.budget).reduce((pv, cv) => pv + cv, 0) < budget" style="color: red; width: 25%; text-align: right; font-size: 9pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{format(weekLimits.limits.map(s=>s.budget).reduce((pv, cv) => pv + cv, 0))}} / {{format(budget)}}</div>
			<div v-else style="width: 25%; text-align: right; font-size: 9pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{format(weekLimits.limits.map(s=>s.budget).reduce((pv, cv) => pv + cv, 0))}} / {{format(budget)}}</div>
			<div style="width: 15%; text-align: right;font-size: 9pt; padding-left: 1pt; display: inline-block; padding-top:0pt;"></div>
			<div style="width: 15%; text-align: right;font-size: 9pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{fmt(weekLimits.limits.map(s=>s.actualBudgetPaid).reduce((pv, cv) => pv + cv, 0))}}</div>
			<div style="width: 15%; text-align: right;font-size: 9pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{fmt(weekLimits.limits.map(s=>s.actualBudget-s.actualBudgetPaid).reduce((pv, cv) => pv + cv, 0))}}</div>
			<div style="width: 15%; text-align: right;font-size: 9pt; padding-left: 1pt; display: inline-block; padding-top:0pt;">{{weekLimits.limits.map(s=>s.actualSpots).reduce((pv, cv) => pv + cv, 0)}}</div>
		</div>
		
        <div style='padding-top: 3pt;'></div>
        <div  class="dontWrapIB" style='height: 21pt; width: 100%;'>
		<span class='smallText bold' style='float: left; width: 20%;'>Use pacing</span>
		<div class="dontWrapIB" style='float: left; padding-bottom: 6pt; width: 20%; display: inline-block;'>
			<app-switch v-model="weekLimits.usePacing" :checked="weekLimits.usePacing" />
		</div>
		<span class='smallText bold' style='float: left; width: 20%;'>With shares</span>
		<div class="dontWrapIB" style='float: left; padding-bottom: 6pt; width: 20%; display: inline-block;'>
			<app-switch v-model="weekLimits.withShares" :checked="weekLimits.withShares" />
		</div>
		
		</div>
		<div  class="xdontWrapIB" style='height: 21pt; width: 100%;' :disabled="weekLimits.usePacing">
        <div class='smallTextSlider bold' style='width: 25%;'>Pacing: {{weekLimits.pacing}}</div>
        <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 70%; display: inline-block;'>
		<vue-slider :disabled="!weekLimits.usePacing" @change="pacingChange" :interval="5" :marks=false min=0 :max="200" v-model="weekLimits.pacing"></vue-slider>
		</div>
		<br/>
		<div  class="dontWrapIB" style='height: 21pt; width: 100%;' :disabled="!weekLimits.usePacing">
		<span class='smallTextSlider bold' style='width: 25%;'>Tolerance: {{formatPercent(weekLimits.tolerance)}}</span>
        <div class="dontWrapIB" style='float: right; padding-right: 5pt;  padding-top: 3pt; width: 70%; display: inline-block;'>
		<vue-slider :interval="5" :marks=false min=0 :max="50" @change="pacingChange" v-model="weekLimits.tolerance"></vue-slider>
		</div>
		</div>
		</div>
		
		
        <div v-if="!fromEditor" style='display: table; position: absolute; bottom: 0; width: 100%;'>
		<button :style="getAppStyle()+'float: right;'" class='button myButton' @click='saveWeeklys(weekLimits)'>SAVE</button>
		<button :style="getAppStyle()+'float: right;'" class='button myButton' @click="$refs.chartView.open(weekLimits, placementId)"><mdicon :width="16" name="chart-line" /></button>
		<button :style="getAppStyle()+'float: right;'" class='button myButton' @click='reload'><mdicon :width="16" name="reload" /></button>
				
		
		</div>
		</div>
		<AIndividualChart v-if="!fromEditor" ref='chartView'>
		</AIndividualChart>
  </div>
</template>

<script>
import {HTTP, fwAPI, userAPI, invAPI, bngAPI, opsAPI, reportAPI, inventoryInlineAPI, setReload, formatNumber,showError} from '@/variables.js';
import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import { formatPercent, format } from '@/Numbers.js';
import InputField from '@/components/booking/InputField';
import InputFieldS from '@/components/inputElements/InputMoneyNC';
//import InputFieldS from '@/components/booking/InputFieldS';
import AIndividualChart from '@/components/AIndividualChart';
import Switch from '@/components/Switch';
import vSelect from 'vue-select'
import ClickOutside from 'vue-click-outside';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
export default {
  name: "WEEKLIMITS",
   components : {
	  InputFieldS, AIndividualChart,
	  'app-switch': Switch
  },
  props: {
	  value: {},
	  placementId:{ type: Number, default: 0},
	  budget: { type: Number, default: 0},
	  fromEditor: { type: Boolean, default: false}
  },
  data(){
    return {
    	formatPercent, getAppStyle, format,
    	weekLimits: this.value
     }
    },
    
    directives:{
        ClickOutside,
      },
    methods: {
		fmt( val)
    	{
       		return formatNumber( val );
    	},
        adjustWeekly( weekLimits, week)
	   {
		  let total = weekLimits.limits.map(s=>s.budget).reduce((pv, cv) => pv + cv, 0);
		  total = total - weekLimits.limits.find(l=>l.week===week).budget;
		  weekLimits.limits.find(l=>l.week===week).budget = this.budget - total;
	   },
	   saveWeeklys( limits)
	   { 
		let that = this;
		
		HTTP.put( reportAPI+"/saveWeeklys/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId, limits)
        .then( response => 
        { 
			that.weekLimits = response.data;
			
        	that.$toast.success("Week limits saved", 'Ok', { timeout: 1000, position: "topRight" });
        }).catch(e => {
        	showError( that.$toast, "save limits", e)
        });

	   },
	   reload() {
		   this.weekLimits = this.value
		   this.$forceUpdate();
	   },
	   loadData() {
		   let that = this;
		   HTTP.post( reportAPI+"/getWeekLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.placementId)
	        .then( response => 
	        { 
				that.weekLimits = response.data;
				
	        	//that.$toast.success("Week limits loaded", 'Ok', { timeout: 1000, position: "topRight" });
	        }).catch(e => {
	        	that.$toast.error("Week limits", 'Error', { timeout: 1000, position: "topRight" });
	        });
	   },
	   equals( a, b)
	   {
		 return JSON.stringify(a) == JSON.stringify(b)  
	   },
	   pacingChange( value, index)
       {
		let that = this;
		HTTP.post( reportAPI+"/placementWeekLimits/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.placementId, this.weekLimits)
	            .then( response => 
	            { 
					that.weekLimits = response.data;
				}).catch(e => {
           
           showError( that.$toast, "pacingChange", e)
        });	
	        
	},
  },
   updated() {
    
  },
  computed: {
	
  },
  
  created() {
  	 this.weekLimits= this.value;
  	 
  	 if ( this.fromEditor )
  	 {
		this.loadData();   
	 }
  },
   watch:
   {
	  value:  function() { 
		  		this.weekLimits = this.value;
		  		if ( this.fromEditor )
			  	{
					this.loadData();   
				}
			  },
	  weekLimits: function() { 
		if (!this.equals(this.value, this.weekLimits)) 
		{
			this.$emit('input', this.weekLimits);
			this.$emit('changed', this.weekLimits);
		}
	  }
   }
}
</script>
<style  scoped>
.dontWrapIB {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: inline-block;
  height: 23pt;
  padding-top: 1pt;
}
.smallText {
  width: 20%;
  overflow: hidden;
  text-align: left;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.smallTextSlider {
  width: 60%;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  

</style>