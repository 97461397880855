//
// Basic time functions
//
var momentTZ = require('moment-timezone');
export function printUTCHHmm(utc)
      {
        try
        { 

	        //console.log( "fmt --> " + fmt+ "/"+ this.timeZone);
	        //console.log( "fmt --> " + sessionStorage.dateFormat+" "+sessionStorage.timeFormat+ "/"+ sessionStorage.timezone);
	        let date = new Date(parseInt(utc));
	        let displayedTime = momentTZ(date).tz(sessionStorage.timezone).format("HH:mm");
            return displayedTime;
        } catch(e){
            console.log(e);
            
        }
}
export function fmtTimePart( aTime) 
{
    if ( aTime < 10 ) 
    {
        return "0"+aTime;
    }
    return ""+aTime;
}

export function fmtDate( aDate) 
{
    return momentTZ( new Date( aDate)).format(sessionStorage.dateFormat);
}

// just hh:mm
export function  printTimeHHMM(time)
{
    //console.log( "scanTime("+val+") " + this.time )
    //alert("scanTime("+JSON.stringify(val)+") " + this.time)
    if ( isNaN( time ) || ! time  )
    {
        time = 0;
    }
    let ss = time % 60;
    let mm = ((time - ss) / 60 ) % 60;
    let hh = ((time - ss - mm * 60) / 3600 ) % 60;
    let hhVal = fmtTimePart(hh);
    let mmVal = fmtTimePart(mm);
    //let ssVal = this.fmtTimePart(ss);
    return hhVal +":"+mmVal;
}
export function  printTimeHHMMSS(time)
{
    //console.log( "scanTime("+val+") " + this.time )
    //alert("scanTime("+JSON.stringify(val)+") " + this.time)
    if ( isNaN( time ) || ! time  )
    {
        time = 0;
    }
    let ss = time % 60;
    let mm = ((time - ss) / 60 ) % 60;
    let hh = ((time - ss - mm * 60) / 3600 ) % 60;
    let hhVal = fmtTimePart(hh);
    let mmVal = fmtTimePart(mm);
    let ssVal = this.fmtTimePart(ss);
    return hhVal +":"+mmVal+":"+ssVal;
}

// just mm:ss
export function  printTimeMMSS(time)
{
    //console.log( "scanTime("+val+") " + this.time )
    //alert("scanTime("+JSON.stringify(val)+") " + this.time)
    if ( isNaN( time ) || ! time  )
    {
        time = 0;
    }
    let ss = time % 60;
    let mm = ((time - ss) / 60 ) % 60;
    let hh = ((time - ss - mm * 60) / 3600 ) % 60;
    let ssVal = fmtTimePart(ss);
    let mmVal = fmtTimePart(mm);
    if ( hh > 0 )
    {
	    let hhVal = fmtTimePart(hh);
		return hhVal+":"+mmVal +":"+ssVal;
	}
    return mmVal +":"+ssVal;
}

// just hh
export function  getHour(time)
{
    if ( isNaN( time ) || ! time  )
    {
        time = 0;
    }
    let ss = time % 60;
    let mm = ((time - ss) / 60 ) % 60;
    let hh = ((time - ss - mm * 60) / 3600 ) % 60;
    return hh;
}

// hh:mm:ss (with options)
export function printTimeOpt(timeInp,sep,withSec,noHour)
{
    let negative = false;
    let time = Math.round(timeInp);
    if ( time < 0 )
    {
        negative = true;
        time = -time;
    }
    if ( isNaN( time ) || ! time  )
    {
        time = 0;
    }
    let ss = time % 60;
    let mm = ((time - ss) / 60 ) % 60;
    let hh = ((time - ss - mm * 60) / 3600 ) % 60;
    let hhVal = fmtTimePart(hh);
    let mmVal = fmtTimePart(mm);
    let hhStr = hhVal + sep;
    if ( noHour )
    {
        hhStr = "";
    }
    if ( withSec )
    {
        let ssVal = fmtTimePart(ss);
        return hhStr + mmVal + sep + ssVal;
    }
    if ( negative )
    {
        return "-" + hhStr + mmVal;
    }                 
    return hhStr + mmVal;
}
// with next day
// hh:mm +|-1 
export function  printTime2(time)
{
    //console.log( "scanTime("+val+") " + this.time )
    //alert("scanTime("+JSON.stringify(val)+") " + this.time)
    if ( isNaN( time ) || ! time  )
    {
        time = 0;
    }
    let minusDay=false;
    let plusDay=false;
    if ( time < 0 )
    {
        time += 24*3600;
        minusDay = true;
    }
    let ss = time % 60;
    let mm = ((time - ss) / 60 ) % 60;
    let hh = ((time - ss - mm * 60) / 3600 ) % 60;


    if ( hh >= 24 )
    {
        hh -= 24;
        plusDay = true;
    }

    let hhVal = fmtTimePart(hh);
    let mmVal = fmtTimePart(mm);
    //let ssVal = this.fmtTimePart(ss);
    return hhVal +":"+mmVal +((plusDay)?" +1":"")+((minusDay)?" -1":"");
}
export function  printTime2WithSec(time)
{
    //console.log( "scanTime("+val+") " + this.time )
    //alert("scanTime("+JSON.stringify(val)+") " + this.time)
    if ( isNaN( time ) || ! time  )
    {
        time = 0;
    }
    let minusDay=false;
    let plusDay=false;
    if ( time < 0 )
    {
        time += 24*3600;
        minusDay = true;
    }
    let ss = time % 60;
    let mm = ((time - ss) / 60 ) % 60;
    let hh = ((time - ss - mm * 60) / 3600 ) % 60;


    if ( hh >= 24 )
    {
        hh -= 24;
        plusDay = true;
    }

    let hhVal = fmtTimePart(hh);
    let mmVal = fmtTimePart(mm);
	let ssVal = fmtTimePart(ss);
    //let ssVal = this.fmtTimePart(ss);
    return hhVal +":"+mmVal+":"+ssVal +((plusDay)?" +1":"")+((minusDay)?" -1":"");
}

export function isoDate(year,month,day)
{
	let y = year < 100 ? year+2000 : year;
	while ( month > 12 )
	{
		y++;
		month -= 12;
	}
	let m = month < 10 ? ("0"+month) : (""+month);
	let d = day < 10 ? ("0"+day) : (""+day);
	return y+"-"+m+"-"+d; 
}

export function parseTime( time)
{
	
	if (!isNaN( time))
	{
		return parseInt( time);
	}
	let timeArr = time.split(":");
	
	if ( timeArr.length == 3)
	{
		return (parseInt(timeArr[0])*60+parseInt(timeArr[1]))*60+parseInt(timeArr[2]);
	}
	if ( timeArr.length == 2)
	{
		return parseInt(timeArr[0])*60+parseInt(timeArr[1]);
	}
	return 0;
}

