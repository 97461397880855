let suffixes = ["", "k", "m", "b", "t"];


export function format( n )
{
	let idx = 0;
	let suffix = suffixes[ idx];
	let prefix = n < 0 ? "-" : "";
	let tmp = Math.abs( n);
	while ( tmp >= 1000.0 && (idx+1 < suffixes.length) )
	{
		tmp = tmp / 1000.0;
		idx++;
		suffix = suffixes[idx];
	}
	if ( tmp >= 100 )
	{
		return prefix + Math.round( tmp) + suffix;
	}
	return prefix + Math.round( tmp*100)/100 + suffix;
} 

export function formatPercent( n )
{
	return Math.round( n*10)/10+"%";
} 

export function formatPercentInt( n )
{
	return Math.round( n*100)+"%";
} 

export function fmt( n )
{
	return Math.round( n*100)/100;
}

export function format2( n )
{
	return Math.round( n*100)/100;
} 

export function formatNZ( n )
{
	let idx = 0;
	let suffix = suffixes[ idx];
	let prefix = n < 0 ? "-" : "";
	let tmp = Math.abs( n);
	
	while ( tmp >= 1000.0 && (idx+1 < suffixes.length) )
	{
		tmp = tmp / 1000.0;
		idx++;
		suffix = suffixes[idx];
	}
	if ( tmp == 0)
	{
		return "";
	}
	if ( tmp >= 100 )
	{
		return prefix + Math.round( tmp) + suffix;
	}
	
	return prefix + Math.round( tmp*100)/100 + suffix;
} 