<template>
<div class='inpWrapper' >
   <div :class="noOpener?'input100':'input80'" >	
  
   <vSelect :options="selectData" 
            :style="'font-size: 9pt; border: 1px solid grey; height: 23pt !important; width: 100%; border-radius: 4pt;'" 
            :appendToBody="true" 
            :clearable="nulls"
            v-model="selectedValue" 
            :multiple="multiple" :selectable="isSelectable" :disabled="isDisabled()" placeholder=''> 
            <template #option="{ id, label }">
      					<span style='font-size: 8pt; height: 12pt;'>{{label}}</span>
    		</template>
    		<template #no-options="{  }">
             <div :style="'font-size: 8pt; color: #666; position: relative; top: 0; margin:0;'">Sorry, no matching options</div>
            </template> 

   </vSelect>
    </div>	
    <div class='input20' v-if="!noOpener" >	
        <span v-tooltip='tooltip()'>
      
		<button :disabled='(selectedValue && !selectedValue.id) || !isSelectable(selectedValue)' class='button btn' @click="$refs.editor.open(entityName, multiple?selectedValue.map(p=>p.id):selectedValue.id)">
		<mdicon size="20" name="pencil"/>
		</button>
		</span>
	 </div>	
	 <GFWEOpenEditor ref='editor' ></GFWEOpenEditor>
</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {HTTP, fwAPI, accessPointAPI, APIURL} from '@/variables.js';
export default {
  name: "GSelectEntity",
  props: {
    disabled: { type: Boolean, default: false },
    noOpener: { type: Boolean, default: false },
    autoSelect: { type: Boolean, default: true },
    useCache: { type: Boolean, default: true },
    visible: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    columnFilter: Object,
    entityName: String,
    entityTitle: String,
    nulls: { type: Boolean, default: false },
    filters: { type: Array},
    options: { type: Array},
    id: [Array, Object, Number],
    valid: Number,
    index: Number,
    time: Number
  },
  components : { vSelect },
  data() {
  	return {
  	   selectData: [],
         selectedValue:  [],
         selectedIndex: 1,
         showEditor: false,
        header: [],
        text: "",
        columns: [],
        limit: 10,
        loadedData: ""
  	}
  },
  methods: {
    searchList(text) { 
    },
    maybeCreate() {},
    setSelected() {
    	//alert( "SET SL"+ JSON.stringify(this.selectedValue))
        if ( this.multiple )
        {
    		this.$emit("selected", this.selectedValue);
    	}
    	else
    	{
    		if ( this.selectedValue && Array.isArray(this.selectedValue) )
    		{
    			this.$emit("selected", this.selectedValue[0]);
    			
    		}
    		else
    		{
    			this.$emit("selected", this.selectedValue );
    		}
    	}
    },
    tooltip() { return ( this.entityTitle) ? "Open " + this.entityTitle: {} },
    show() {
    alert(JSON.stringify(this.definition))},
    editor() 
    {
        this.showEditor = true;
    },
    isSelectable(option) { return option ? option.visible: true; },
    isDisabled() { return this.disabled || (this.selectedValue && this.selectedValue.id && !this.selectedValue.visible) },
    closeEditor()
      {
         this.showEditor = false;
         //this.getData();
      },
    update() {},
    doSearch(text) {
    },
    getData()
  	{
		  
    	//alert("getData " +this.entityName + (this.options && this.options.length))
    	if ( this.options && this.options.length )
    	{
    		let array = this.options;
    		
    		if ( this.filters )
    		{
    			array = array.filter( p=> this.filters.includes( p.id));
    		}
    		this.selectData = array.map(p=> { return { id: p.id, label: p.name, visible: true}});
    		this.selectData.sort((a,b) => a.label.localeCompare(b.label))
    		
    		if ( this.selectData.length === 1 )
        	{
        		this.selectedValue = this.selectData[0];
        		this.setSelected();
        	}
    		else
    		{
    			if ( Array.isArray( this.id))
    			{
    				this.selectedValue = this.selectData.filter( p => (this.id.includes(p.id )));	
    			}
    			else
    			{
    				this.selectedValue = this.selectData.find( p => p.id == this.id);
    			}
    			//alert( this.id + JSON.stringify(this.selectedValue))
    			if ( this.selectedValue )
    			{
    				this.setSelected();
    			}
    		}
    		return;
    	}
  	    
		if ( ! this.entityName || ! this.visible)
  	        return;
  	        
  	    // console.log( JSON.stringify(this.linkData));
  	    // console.log( "getData() " + JSON.stringify(this.definition));
  	    // console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+this.linkData.entity+"/all/true/0);
  	    
  	    //console.log(JSON.stringify(this.parentMeta));
    	let id = this.id === null? 0: this.id;
    	let entity = this.entityName;
    	

    	//console.log( "GSELECT "+key+"/"+this.loadedData);
        //console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+entity+"/"+id+"/"+this.linkData.entity+"/options/true/0");
        //alert( JSON.stringify( this.filters) );
        
        //console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/asOptions/true/0"); 
        if ( !this.columnFilter)
        {
			this.columnFilter = {};
		}
		//alert(JSON.stringify( this.columnFilter))
    	HTTP.post( fwAPI+"/getAsOptionsWithFilter/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/true/0", this.columnFilter) 
            .then( response => 
            { 
                  try{         

                        //alert(JSON.stringify(response.data.data))
                       	let array = response.data;
                       	if ( this.filters )
                   		{
                   			array = array.filter( p=> this.filters.includes( p.id));
                   		}
                   		array.sort((a,b) => a.label.localeCompare(b.label))
                   		this.selectData = array;
                   		if ( this.autoSelect && this.selectData.length === 1 )
                       	{
                       		this.selectedValue = this.selectData[0];
                       		this.setSelected();
                       	}
                   		else
                   		{
                   			if ( Array.isArray( this.id))
                   			{
                   				this.selectedValue = this.selectData.filter( p => (this.id.includes(p.id )));	
                   			}
                   			else
                   			{
                   				this.selectedValue = this.selectData.find( p => p.id == this.id);
                   			}
                   			
                   			if ( this.selectedValue && this.selectedValue.id )
                   			{
                   				this.setSelected();
                   			}
                   		 }	
                       } catch (e) { console.log( e ) }
                    }).catch(e => {
                           //alert( e.response.status + " " + JSON.stringify(e.response.data) )
                          });
        }
      
  },
  computed: {
  	// watcher() { return this.id+this.visible; } 
  },
  watch: {
	  entityName: function()  { this.getData() },
      id: function() {
    	  
    	  
      },
      valid: function() {
    	  this.selectedValue = [];
    	  this.$forceUpdate();
      },
      selectedValue: function() 
      { 
    	  this.setSelected();
          if (this.selectedValue) 
          {
            this.$emit("input", this.selectedValue.id );
            }
            else
            {
                this.$emit("input", null );
            }
      }
  },
  created() {
  	this.getData();
  },
  updated() {
    //console.log( "GSELECT:visible> " + JSON.stringify(this.visible));
    this.loadedData = "";
    //this.getData();
  }
 }
</script>

<style  scoped>
.inpWrapper {
	width: 100% !important;
	
	display: inline-block;
	padding: 0;
	margin:0;
}
.input80 {
	width: 88% !important;
	display: inline-block;
	height: 33pt !important;
	padding: 0;
	margin:0;
}
.input100 {
	width: 100% !important;
	display: inline-block;
	height: 33pt !important;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
.btn {
	height: 2.0em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
label, span{
        display: block;
    }
</style>