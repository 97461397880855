<template>

  <div class="mainLogin">
    <div class="title" style='color: #fff; z-index: 1; width: 100%; height: calc(95vH) !important;'>
      <div style='float: top;'>
        <div>
           <center>
        
          <h1 style='padding-top: 10pt; font-size: 32pt;'>Login to {{ this.myTitle }}</h1>  
          </center>        
        </div>
      </div>
    
    <div style='display: flex; width: 100%; float: top;'>
      <form  name='loginForm' style='width: 100%; float: top;'>
      <center>
      <br/>
      <br/>
      <table width=25%>
        
        <tr>
        
         <td><div class="lb">LOGIN</div>
        <td class="loginInp"><InputText :disabled="loginDisabled" :border="true" @enter="submit()" v-model='user.login' /></td>
        </tr> 
        <tr>
        <td><div class="lb">PASSWORD</div>
        <td class="loginInp">
            <InputSecret @enter="submit()" :disabled="loginDisabled" :style="'background-color: transparent !important;'" v-model='user.password' /></td>
      </tr>
      </table>
      <button :disabled="loginDisabled" class="button is-dark down" type="button" @click="submit()">Login</button>
      
      </center>
      </form>
      </div>
    </div>

    <div style='position: absolute; bottom: 0; text-align: right; width: 100%; padding-right: 20pt; font-size: 9pt;'>
   {{getCopyright()}}
   </div>
  </div>
</template>
<script>
// import axios from 'axios';
import {HTTP, DLoginServ, showError} from '../variables.js';
import { getCopyright } from '../utils.js';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import InputText from '@/components/inputElements/InputText';
import InputSecret from '@/components/inputElements/InputSecret';
import Label from '@/components/Label';

var selectedAccessPoint = { auth: "", id: 1 };
export default {
  name: 'app',
  components : {
    InputText, InputSecret
  },
  data () { 
    return {
      selectedAccessPoint,
      accessPointID: 1,
      accessPoints: [],
      loginPage : "",
      getCopyright,
      myURL: window.location.href,
      disabled: ( selectedAccessPoint.auth != 'internal'),
      loginDisabled: false,
      loadingActive: false,
	  tries: 1,
      user : {
        login: '',
        password: ''
      }
      
      }
    },
  created()
  {
    sessionStorage.ident=this.$route.params.ident; // store ident
    initAppMode();
  }, 
    computed: {
    console: () => console,
    window: () => window,
   },
  watch:
  {
    accessPointID: function()  { console.log(this.accessPointID); this.checkAccessPoint(); }
  },
  methods: {
        ident() { return sessionStorage.ident; },
        submit(){
			this.loginDisabled = true;
			this.startLoader();
			let that = this;
			let login = { u: this.user.login, pw: this.user.password, site: this.$router.currentRoute.path};
			HTTP.post( DLoginServ+"/login", login)
			        .then( response => {
						setTimeout( that.stopLoader, 2000*(this.tries++))
			        }).catch(e => {
			                that.$toast.error(e.response.data, 'Error', { position: "topRight" });
			        });
          
        },
		stopLoader()
		{
			this.loginDisabled = false;
			console.log("Wrong password");
			this.$toast.error("Wrong passord", 'Error', { position: "topRight" });
			this.loadingActive = false;
			this.loader.hide();
		},
        startLoader()
	    {
	    	if ( !this.loadingActive)
	      	{
		      	this.loadingActive = true;
		      	this.loader = this.$loading.show({
		                    // Optional parameters
		                    container: this.$refs.formContainer,
		                    canCancel: true,
		                    programmatic: false,
		                    
		                    color: '#000000',
						    loader: 'dots',
						    width: 64,
						    height: 64,
						    active: true,
						    backgroundColor: '#ffffff',
						    opacity: 0.5,
						    zIndex: 999,
		                });
		    }
	    }
    }
}
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
  .menu-item {
	color: #212529;
  padding: .25rem 1.5rem;
  text-align: left;
	transition: color .15s ease-in-out,background-color .95s ease-in-out,border-color .95s ease-in-out,box-shadow .15s ease-in-out;
}
.title {
  background-image: linear-gradient( rgba(80,0,220,.5), rgba(0,80,120,.9) );
}
.mainLogin {
    display: block;
    float: top;
	height: 100% !important;
}
.loginInp {
  background-color: transparent;
  font-weight: normal;
  color: #000;
}
.lb {
  color: #000;
  font-weight: bold;
  padding-top: 8pt;
  font-size: 12pt;
}
.menu {
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: .25rem;
	color: #212529;
	cursor: pointer;
	display: flex;
    padding: 10pt;
  flex-direction: column;
  text-align: center;
	font-size: 1rem;
	list-style: none;
	margin: .125rem 0 0;
  padding: .5rem 0;
  width: 100%;
	// position: absolute;
	// text-align: left;
}
.down {margin-top: 10pt;}
  .login {
    margin-top: 30pt;
  }
</style>