<template>
  <div class="main white" >
     <div class='white headLine'>
	       <div class='white' style=' width: 90%; float: left; padding-top:0pt; padding-left: 0pt;'>
 <div style='height: 100% !important; display: block; padding-top:2pt;padding-right:5pt; font-size: 9pt; font-weight: bold;'>Background process controller</div>
 <div style='height: 100% !important; display: block; padding-top:0pt;padding-right:5pt; font-size: 9pt;'>
    
    </div>
	       </div> 
	       <div style='float: right;' @click='$emit("close")'>
	       		<WindowCloseBN/>
		   </div>
	  </div>
     <div>
     <div style='height: 100% !important; display: inline-block; padding-top:1pt;padding-right:5pt;'></div>
     <div style='display: inline-block;'>
     <span v-for="(d,k,i) in services" :key="i">
     <button :title="'switch '+d.name+' '+(d.active?'on':'off')" 
             @click='activate(d)' 
             :class="'button svButton '+((d.active)?'selected':'')"  >
        <span >
        {{d.name}}
        </span>
        
        
     </button>
     </span>
     
     </div>
     <div :style="getAppStyle()+'display: inline-block; float: right; padding-top: 1px;'">
     <button :title="'Reload data'" :class="'button '" :style="getAppStyle()"
		    	  	        @click="reload()"><mdicon :width="16" name="reload" /></button>
     </div>
     </div>
   </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, serviceAPI, searchAPI, userAPI, myTimer, getAvailableServers, receiveToken, myName, showError,rebuildAPI,testServer} from '@/variables.js';
import {store, restore} from '@/serverWidget.js';
import { getAppStyle, initAppMode } from '@/AppStyle.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
export default {
  name: 'GFW_SERVERS',
  components : {
	  WindowCloseBN
	  },
  props: {
   	user: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
  data: function () {
    return {
    	activeServerName: "",
    	activeServer: "",
    	services: [],
    	getAppStyle
    }
  },
  methods: {
	    getServices() {
			HTTP.post( serviceAPI+"/getBackgroundServices/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	            .then( response => 
	            { 
					this.services = response.data;
				}).catch(e => {
	                   //
	            });
		},
	    storeSettings() 
		{
			
		},
		reload() {
			this.getServices();
	    	
		},
		activate( service)
		{
			
			HTTP.put( serviceAPI+"/changeBackgroundServices/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, service)
	            .then( response => 
	            { 
					this.services = response.data;
				}).catch(e => {
	                   //
	            });
		}
  },
  beforeDestroy() {
	  this.storeSettings();

  },
  created()
  {
	 this.reload();
	
  },
  watch: {
	 
    }
}
</script>

<style scoped>
.main {
	display: block;
	padding-left: 5pt;
	padding-bottom: 5pt;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}
.shadow {
    background-color: white !important;
	width: 100%;
    box-shadow: 5px 5px 8px 0 #444;
}
.selected{
	background-color: #03a9f4 !important;
	color: #eee;
	font-weight: bold;	
}
.button {
	box-shadow: 1px 1px 2px 0 #000;
	border-radius: 4px;
	height: 18pt;
}
.svButton  {
	background-color: #efe;
	padding: 2px !important;
	width: 52pt;
	margin-right: 1px !important;
	font-size: 9px;
}
.lbButton  {
	background-color: #cec;
	padding: 2px !important;
	width: 32pt;
	margin-right: 1px !important;
	font-size: 12px;
	font-weight: bold;	
}
.svButton:disabled  {
	background-color: #faa;
	
}

.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.white {
    //background-color: white !important;
}
.text {
 	font-size: 9pt;
}

.iButton:hover {color: #e8f0e8 !important;}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


