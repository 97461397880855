import {HTTP, fwAPI, rightsAPI, userAPI, dashboardInfoAPI, showError} from '@/variables.js';
import {defer} from '@/utils.js';

export function getUser() 
{ 	    	
	let promDefer = defer();

	HTTP.post( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
        .then( response => 
        { 
            promDefer.resolve( response.data);
            
        }).catch(e => {
			   promDefer.reject();
               console.log( e)

        });
	 return promDefer;      
}
export function updateUser( user) 
{ 	    	
	let promDefer = defer();
	HTTP.put( userAPI+"/updateUser/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, user)
        .then( response => 
        { 
	
            promDefer.resolve( response.data);
            
        }).catch(e => {
			   promDefer.reject();
               console.log( e)

        });
	 return promDefer;      
}