<template>
    <label class="switch">
        <input :class="classes" @click="clicked()" type="checkbox" :checked="value" :name="name" :disabled="disabled" v-model="value">
        <span><slot></slot></span>
    </label>
</template>

<script>
    export default {
        props: {
            disabled: Boolean,
            classes: String,
            checked: Boolean,
            name: String,
            value: Boolean
        },
        data() {
            return {
               
            }
        },
        methods: {
          clicked() 
          {
            //alert('click on switch');
            this.$emit('change', this.value);
            //this.$emit('input', this.value);
          }
        },
        beforeMount() {
          
        },
        mounted() {
            
            this.$emit('input', this.value)
        },
        watch: {
			value: function() {this.$emit('input', this.value);this.$emit('changed', this.value);}
        }
    }
</script>

<style lang="scss" scoped>
    label.switch {
        input[type="checkbox"] {
            display: none;
            &:checked {
                + span {
                    &:before {
                        background-color: rgba(#007700, 0.3);
                    }
                    &:after {
                        background-color: #007700;
                        transform: translate(80%, -50%);
                    }
                }
            }
            + span {
                position: relative;
                display: inline-flex;
                cursor: pointer;
                font-weight: 500;
                text-align: left;
				vertical-align: middle;
                margin: 0px;
				
                padding: 0px 44px;
                &:before,
                &:after {
                    content: '';
                    cursor: pointer;
                    position: absolute;
                    margin: 0;
                    outline: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    transition: all 200ms ease-out;
                }
                &:before {
                    left: 1px;
                    width: 34px;
                    height: 18px;
                    background-color: rgba(99, 99, 99, 0.5);
                    border-radius: 8px;
                }
                &:after {
                    left: 0;
                    width: 20px;
                    height: 20px;
                    background-color: rgba(99, 99, 99, 0.8);
                    border-radius: 50%;
                    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);
                }
            }
            &:checked + span &:after {
                transform: translate(80%, -50%);
            }
        }
    }
</style>