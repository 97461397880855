<template>
<div>
	<span class='labelWrapper' v-tooltip='tooltip'>
	<span class='label'>{{ value }}</span>
	</span>
</div>
</template>
<script>

export default {
  props: [ 'value','tooltip' ],
  
 components : {
    
  }
 }
</script>
<style lang="scss" scoped>
.label { font-weight: bold; font-size: 11pt; color: #444; text-align: bottom; padding: 2pt;}
.labelWrapper { width: 100%; text-align: bottom;}
</style>

