<script type="text/javascript" src="`url('${envJSON}')`"></script>
<template>
	
  <div id="app" :style="getAppStyle()" class='transition'>
    <div v-if="locked" class="transition" style="z-index:99999; background-color: #444; display: block; position: absolute; top: 0; width: 100%; height: 100%;" @click='goto("/fw/myDashBoard", "Workbench")'>
	  <div style="background-color: #222; display: block; position: absolute; top: 0; width: 100%; height: 100%;" @click="unlock">
      <button @click="unlock">UNLOCK</button>
    </div>
    </div>  
    <div class='mymenu'>
		
        <MenuBar @tickerDisplay=getTickerDisplay :menuList=myMenuList :mainMenu=mainMenu 
        :tenantId=tenantId() :accessPointId=accPointId()
        :ready="appState"
        @lock="lockScreen"
        @reload=doReload
        :loggedIn='loggedIn()' :user="myLogin()" :unitOptions=unitOptions :selectedUnit=selectedUnit />
    </div>
	<div>
		
	<div  v-if="loggedIn() == true && $route && $route.name && ! ($route.name.startsWith('fw') || $route.name.startsWith('Data Miner') || $route.name.startsWith('XAssign') || $route.name.startsWith('XTraffic') || $route.name.startsWith('XSchedule'))" class='appHeadLine'>
    <div style='width: 100% !important; overflow: hidden;' >
      <div :style='"float: "+breadCrumb'>
       <div class='breadcrumbItem' :style="getAppStyle()" v-tooltip='"Back to \""+obj.name+"\""' v-for="(obj,idx) in backTo()" :key='"BC"+idx' @click='goTo(obj,idx)'>
        {{trTitle(obj.name, obj.name)}}
       </div>
       <div class='breadcrumbItemCurrent' :style="getAppStyle()">
        {{trTitle($route.path, $route.name)}}
       </div>
       <button v-if="breadCrumb !== 'left'"  id='closePopup2' type="button" class="iButton" @click="closeScreen()" >
              <WindowCloseBN @click="closeScreen()"/>
            </button>
       </div>
    <div v-if="breadCrumb==='left'" style='float: right'>   
     <WindowCloseBN @click="closeScreen()"/>
    </div>
    </div>
    </div>
   
	<router-view @login='setLogin()' :key="x" @logout='setLogout()'/>
    
    </div>
   <ProgressBar v-if="showProgressBar" :generalInfo=pbInfo :action=pbAction @action=action></ProgressBar> 
  
  </div>
</template>

<script>
// import auth from './auth'
import MenuBar from '@/components/MenuBar';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import { getAppStyle } from '@/AppStyle.js';
import { goBack, backTo } from '@/breadCrumb.js';
import { defineTranslation, trTitle } from '@/translate.js';
import ProgressBar from '@/components/ProgressBar';
import { initEnvironment, logout, findServer, setToast, HTTP, tenantAPI, fwAPI, showError, startTicker, reload, setAppServerListener, getSalesUnit, myName} from '@/variables';
var websocket;

export default {
  name: 'app',
  components: {
    MenuBar, WindowCloseBN, ProgressBar
  },  
  data() {
    return {
        login: !! (sessionStorage.token),
        breadCrumb: 'left',
        tenantID: 0,
        appState: 0,
        x: 1,
        myMenuList: [],
        mainMenu: {},
        translate: [],
        user: { login: "", id: 1},
        puInfoStr: "loading..",
        puValueInt: -1,
        unitOptions: [],
        selectedUnit: {},
        showProgressBar: false,
        locked: false,
        pbAction: "",
        pbInfo: "",
		
        getAppStyle, trTitle
     }
  },
  methods:
  {
    backTo()
    {
    	return backTo();
    },
    unlock()
    {
		this.locked = false;
	},
	lockScreen()
    {
		this.locked = true;
	},
    setLogin() 
    {
        this.login = true; 
        this.tenantID = sessionStorage.tenantId; 
        if ( !this.unitOptions || this.unitOptions.length==0 )
        {
        	this.unitOptions=[];
        	getSalesUnit( sessionStorage.salesUnitId, sessionStorage.unitId, this.unitOptions, this.selectedUnit);
        }
        this.getMenu()
    },
    doReload(unitId, unit) { 
      if ( unit) 
      { 
         this.selectedUnit = unit;
      } 
      reload(unitId); 
    },
    puInfo() { return this.puInfoStr; },
    puValue() { return this.puValueInt; },
    tenantId() {return parseInt(this.tenantID)},
    accPointId() {return parseInt(sessionStorage.accessPointId)},
    setLogout() {this.login = false; },
    myLogin() { if ( this.login ) { return sessionStorage.login; } else { return "LOGIN" } },
    myUnit() { if ( this.login && sessionStorage.salesUnit ) { return sessionStorage.salesUnit; } else { return "--" } },
    doLogin() {
        this.$router.replace("/login");
       // this.$router.go();
      },
    doLogout() {
        logout();
        this.$router.replace("/");
        //this.$router.go();
    },
    logOutIn()
    {
        if ( this.loggedIn() ) 
        { this.doLogout()} 
        else 
        { this.doLogin(); } 
    },
    closeScreen()
    {
        this.$emit('clearAll');
        let x = goBack();
        if ( x.path.startsWith('/fw/') )
        {
        	this.$router.replace( '/fw/myDashBoard');
        }
        else
        {
    		this.$router.replace( x.path);
    	}
    },
    goTo( path, idx )
    {
    	let x = path;
    	
    	while ( backTo().length > idx )
    	{
    		x = goBack();
    	}
    	
    	if ( x.path.startsWith('/fw/') )
        {
        	this.$router.replace( '/fw/myDashBoard');
        }
        else
        {
    		this.$router.replace( x.path);
    	}
    },
    loggedIn()
    {
        return this.login;
       
    },
    loggedOut()
    {
        return this.login;
        
    },
    appServerListener( command) 
    {
        //console.log("appServerListener> " + command);
    	if ( command == "restarted" )
    	{
    		this.getMenu();
    	}
    },
    getTickerDisplay(d)
    {
        startTicker(d);
    },
    action( what, myId, api)
    {
    	this[what](myId, api )
    },
    loadMenuData( myId, api)
    {
    	
    	api.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/getDaos/"+myId)
        .then( response => {
	            //console.log("----> CALL "+response.data.length);
	            var section = -1;
	            var menu= "";
	            this.mainMenu = {};
				for ( var item in response.data)
	            {
	            	if ( response.data[item].section == -99 )
	            	{
	            		continue;
	            	}
	            	if ( ! this.mainMenu[response.data[item].menu] )
	            	{
	            		this.mainMenu[response.data[item].menu] = [];
	            	}
	            	if ( menu != response.data[item].menu )
	            	{
	            		menu= "";
	            	}
	            	if (section >= 0 && response.data[item].section != section)
	            	{
	            		this.mainMenu[response.data[item].menu].push({"title": "", "class": "menuItemSep", "entity": "SEP."+section+"."+item});
	            	}
	            	section = response.data[item].section;
	            	menu = response.data[item].menu;
	            	this.translate[response.data[item].entity] = response.data[item].title;
	            	this.mainMenu[response.data[item].menu].push({"title": response.data[item].title, "section": response.data[item].section+1, "entity": response.data[item].entity, "class": "menuItem"});
	            
	            }
	           defineTranslation( this.translate );
	           this.appState = 2;
	           this.showProgressBar = false;
	        }).catch(e => {
	        	showError( this.$toast, "get Menu entries()", e);
	            console.log("ERR----> "+JSON.stringify( e.response.data));
	            this.showProgressBar = false;
	    });	
    },
    getMenu() {
        setAppServerListener( this.appServerListener);
        
        this.appState = 1;
        
        this.pbAction = "loadMenuData";
    	this.pbInfo = "Initializing application, rights and menu";
  	    this.showProgressBar = true;

        if ( !this.unitOptions || this.unitOptions.length==0 )
        {
        	this.unitOptions=[];
        	getSalesUnit( sessionStorage.salesUnitId, sessionStorage.unitId, this.unitOptions, this.selectedUnit);
        }
      },
    init()
    {
    	  findServer();
		 
    }
  },
  computed: {

   },
   updated()
   {
	   setToast( this.$toast);
   },
   created() {
	  //console.log("APP.created()")
	  initEnvironment().then(p=>{
		//console.log("==> " + JSON.stringify(p))
		document.title = myName;
		this.init();
        try {
      	  
    		 document.getElementsByTagName("body")[0].style.backgroundColor=sessionStorage.bgColor;
    		 document.getElementsByTagName("body")[0].style.color = sessionStorage.fgColor;
    		} catch(e1) {
    			//
    		}
    		
        if ( this.loggedIn() )
        {
            this.getMenu();
            this.unitOptions=[];
        	  getSalesUnit( sessionStorage.salesUnitId, sessionStorage.unitId, this.unitOptions, this.selectedUnit);
        	  setToast( this.$toast);
        }
	  })
	  
  },
   beforeMount() {
           
        }
}
</script>

<style>
  html, body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #2c3e50;
    scrollbar-color: black #aaa;
    //background-color: #000;
  }
body { padding-right: 0pt !important;}
 #app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}
div {
    scrollbar-color-thumb: hotpink;
    scrollbar-color-track: blue;
    scrollbar-width: thin;
    scrollbar-width-legacy: 5px;
}

  ul {
    line-height: 1.5em;
    padding-left: 1.5em;
  }

  a {
    color: #7f8c8d;
    text-decoration: none;
  }
.mymenu { display: inline; width: 100%; margin-bottom: 5pt; height: 60pt; }  
.menubaritem { display: inline; 
border: 1pt solid grey; 
height: 40pt;
float: left; 
padding: 5pt; 
font-size: 11pt; 
box-shadow: 2px 2px 2px 0 #ccc; }

 .transition {
 	transition: all 0.8s;
   -webkit-transition: all 0.8s;
 }
.appHeadLine { 
    width: 100%; 
   
    float: top;
    //border-bottom: 1pt solid #aaa; 
    text-align: right;
    margin-bottom: 2pt; 
    font-weight: bold;
    padding: 0pt 5pt 0pt;}

  a:hover {
    color: #4fc08d;
  }
  .markedElement {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    font-weight: bold;
  }
.button {

}
.button:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset green;
	background-color: #e8f0e8 !important;
	color: #000 !important;
  }
  
  /* Style the tabs */
.tabs {
    overflow: hidden;
    margin-left: 0px;
    margin-bottom: -20px; 
	
}
.tab-details { z-index: 4; }
.tabs ul {
    list-style-type: none;
    margin-left: 2px; 

}

.tabs a{
    float: left;
    cursor: pointer;
	max-width: 120pt;
    padding: 6px 16px 6px 16px;
	border: 1px solid #aaa;
	margin: 0px 3px 0px 0px;
    transition: all .7s;
    background-color: #ddd;
    border-radius: 6px 6px 0 0;
    box-shadow: 4px 4px 5px 0 #aaa;
    font-weight: bold;
	text-align: left;
	justify-content: left !important;
	overflow-x: hidden;
	  white-space: nowrap; /* Don't forget this one */
	  text-overflow: ellipsis; 
}
.tabs li.is-active a {
	
	font-weight: bold;
}


.tabs a:last-child { 
    border-right: 1px solid #ccc;
}

/* Change background color of tabs on hover */
.tabs a:hover {
    background-color: #aaa;
}
.breadcrumbItem {
    float: left;
    cursor: pointer;
    //position: relative;
	border: 1px solid #ccc;
	border-radius: 7px 7px 0 0;
	margin-top: 3pt;
	margin-right: 0pt;
	padding: 3px;
	background-color: #111;
	font-weight: normal;
	color: #fff;
	height: 21pt;
	font-size: 12px;
	box-shadow: 4px 0px 5px 0 #aaa;

}
.breadcrumbItemCurrent {
    float: left;
    //position: relative;
	//padding-bottom: 5pt;
	border: 1px solid #ccc;
	border-radius: 7px 7px 0 0;
	margin-top: 3pt;
	margin-right: 0pt;
	padding: 3px;
	background-color: #eed;
	font-weight: normal;
	color: #000;
	height: 21pt;
	font-size: 12px;
	box-shadow: 4px 0px 5px 0 #aaa;

}

.breadcrumbItem:hover, .breadcrumbItemCurrent:hover {
	background-color: #888 !important;
	color: #eee !important;
	box-shadow: 4px 4px 5px 0 #66a;
	transition: all 0.4s;
   -webkit-transition: all 0.4s;
}
/* Styling for active tab */
.tabs a.active {
    background-color: #ccc;
    color: #000;
    border-bottom: 2px solid #888;
    cursor: default;
}

.tabs li.is-active a {
    border-bottom: 1pt inset #fff; 
    background-color: #fff; 
    color: #000;
}

.puInfo { padding-left: 2em; }

.slide-fade-enter-active {
 
}

.slide-fade-leave-active {

}

.slide-fade-enter-from,
.slide-fade-leave-to {

}
</style>