<template>
  <div class="main white" 
     @contextmenu.prevent="openCM($event, data)" >
       <div class='white headLine'>
	       <div class='white' style='width: 90%; float: left;'>
	       	<div :style="'font-size: '+(fontSize+2)+'pt; padding-left: 10pt; max-width: 99% !important;; overflow: hidden;text-align: left;white-space: nowrap; text-overflow: ellipsis;'">Incoming sales requests in {{(mySetup.wUnit==1000)?'T':'M'}}{{houseCurrency}}{{getUnit()}}</div>
	       </div> 
	       <div  style='float: right;' @click='$emit("close")'>
	       		<WindowCloseBN/>
		   </div>
	  </div>
	  <br/>
	  <div class='white body' :style="'display: block; color: #000; height: '+mySetup.height+'px; width: 100%;'" >
       <div id='srChart' />
	 </div>

     <ContextMenu ref="menu" >
      <template v-if="contextData"  slot-scope="{ contextData }">
       
        <ContextMenuItem v-if='contextData.allowed && contextData.allowed.includes("Operations/Sales Input")' 
                         @clicked="closeCMN(); showSalesInput(contextData);">
          Open Sales Input Screen
        </ContextMenuItem>
         <ContextMenuItem v-if='contextData.allowed && contextData.allowed.includes("Operations/Data Completion")' 
                          @clicked="closeCMN(); showTrafficInput(contextData);">
          Open Traffic Completion
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='contextData.allowed && contextData.allowed.includes("Operations/Delivery")' 
                         @clicked="closeCMN(); showDelivery(contextData);">
          Open Delivery Screen
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='mySetup.group!=="DAY"' @clicked="mySetup.group='DAY'; changeSetup();">
          Show last 6 days
        </ContextMenuItem>
        <ContextMenuItem v-if='mySetup.group!=="MONTH"' @clicked="mySetup.group='MONTH'; changeSetup();">
          Show last 4 months
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='mySetup.chartType!=="bar"' @clicked="mySetup.chartType='bar'; changeSetup();">
          Show as bar-chart
        </ContextMenuItem>
        <ContextMenuItem v-if='mySetup.chartType!=="line"' @clicked="mySetup.chartType='line'; changeSetup();">
          Show as line-chart
        </ContextMenuItem>
        <ContextMenuItem v-if='mySetup.chartType!=="area"' @clicked="mySetup.chartType='area'; changeSetup();">
          Show as area-chart
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='mySetup.theme!=="blue"' @clicked="mySetup.theme='blue'; changeSetup();">
          Color scheme blue
        </ContextMenuItem>
        <ContextMenuItem v-if='mySetup.theme!=="green"' @clicked="mySetup.theme='green'; changeSetup();">
          Color scheme green
        </ContextMenuItem>
        <ContextMenuItem v-if='mySetup.theme!=="grey"' @clicked="mySetup.theme='grey'; changeSetup();">
          Color scheme grey
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if='mySetup.wHeight!=160' @clicked="mySetup.wHeight=160; changeSetup();">
          Smaller Size
        </ContextMenuItem>
        <ContextMenuItem v-if='mySetup.wHeight!=200' @clicked="mySetup.wHeight=200; changeSetup();">
          Bigger Size
        </ContextMenuItem>
        <ContextMenuItem v-if="mySetup.wUnit!=1000" @clicked="mySetup.wUnit=1000; changeSetup();">
          Unit T (Thousand)
        </ContextMenuItem>
        <ContextMenuItem v-if="mySetup.wUnit!=1000000" @clicked="mySetup.wUnit=1000000; changeSetup();">
          Unit M (Million)
        </ContextMenuItem>
     </template>
    </ContextMenu>
    
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, reportAPI, userAPI, opsAPI, myTimer, receiveToken, refresh, myName, setNotyWidgetReload, showError} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import { printUTCHHmm } from '@/basicTimeFN.js';
import store from "@/store";
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ApexCharts from 'apexcharts'
export default {
  name: 'GFW_LOGINS',
  components : {
	  ContextMenu,ContextMenuItem,ContextMenuSep,WindowCloseBN
  },
  props: {
   	user: Object,
   	setup: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
  data: function () {
    return {
      data: [],
      mySetup: {},
      fullSetup: {},
      houseCurrency: 'XXX',
      timeoutID: {}, 
      chart: null,
      wHeight: 180,
      chartOptions: {},
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
       printTime(h) {
            return printUTCHHmm(h.lUpdate);
       },
        printNoty(h) {
            return h.message;
       },
       changeSetup()
       {
    	   this.closeCMN();
    	   this.fullSetup.charts = this.mySetup;
    	   this.getNotification();
    	   this.$emit("setup", this.fullSetup)
       },
       getUnit()
       {
    	 if ( sessionStorage.unitId > 0 )
    	 {
    	 	return " ("+sessionStorage.salesUnit+")";  
    	 }
    	 return ""
       },
       openCM(event, data){
        event.stopPropagation();
        //alert( data.allowed)
        this.$refs.menu.open(event, data )
		},
		closeCMN() { try{ this.$refs.menu.close(); } catch(e) { /* */ }}, 
       showSalesInput(data)
       {
    	    let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/workflowOperationsSalesInput').name;
	    	if ( !viewName )
	    	{
	    		return;
	    	}
	    	
	    	this.$router.replace({name: viewName, params: { from: data.min, until: data.max}});
       },
       showTrafficInput(data)
       {
    	    let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/workflowOperationsTrafficAssign').name;
	    	if ( !viewName )
	    	{
	    		return;
	    	}
	    	
	    	this.$router.replace({name: viewName, params: { from: data.min, until: data.max}});
       },
       showDelivery(data)
       {
    	    let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/workflowOperationsTrafficDelivery').name;
	    	if ( !viewName )
	    	{
	    		return;
	    	}
	    	
	    	this.$router.replace({name: viewName, params: { from: data.min, until: data.max}});
       },
       getNotification() {
       	let that = this;
       	  
       	let to = new Date();
       	let from = new Date();
       	if ( that.mySetup.group === 'DAY')
       	{
       		from.setDate( from.getDate() - 6);
       	}
       	else if ( that.mySetup.group === 'MONTH')
       	{
       		from.setDate( 1);
       		from.setMonth( from.getMonth() - 4);
       		
       		to.setMonth( to.getMonth() + 1);
       		to.setDate( 0);
       	}
       	let fromStr = from.toISOString().split('T')[0];
       	let toStr = to.toISOString().split('T')[0];
       	//console.log(opsAPI+"/getRequestsPerTime/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromStr+"/"+toStr)
       	HTTP.post( opsAPI+"/getRequestsPerTime/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+fromStr+"/"+toStr+"/"+that.mySetup.group+"/"+that.mySetup.wUnit)
        .then( response => 
        { 
        	//console.log( "loaded")
        	this.data = response.data;
        	let chart = response.data;
        	
        	let data = [{"name":"Entered","data":chart.entered},
        		        {"name":"Traffic","data":chart.requested},
        		        {"name":"Assigned","data":chart.inProgress},
        		        {"name":"Delivery","data":chart.inDelivery}];  
        	
        	let colors = ['#ccc', '#aaa', '#888', '#666' ];
        	if ( that.mySetup.theme === 'green')
        	{
        		colors = ['#ccc', '#88f2d8', '#21f396', '#67b73a' ];
        	}
        	if ( that.mySetup.theme === 'blue')
        	{
        		colors = ['#ccc', '#88d8f2', '#2196f3', '#673ab7' ];
        	}
        	let col = sessionStorage.fgColor;
        	that.$forceUpdate();

        	that.chartOptions = {
  				  chart: { 
  				    type: that.mySetup.chartType,
  				    height: this.mySetup.wHeight,
  				    toolbar: {
  			         show: false
  				    },
  				    animations: {
  				        enabled: false
  				    },
  				    events: {
  				    	 
  				    }						
  				  },

  				  title: {
  					//  text: "Sales requests in TUSD"
  				  },
  			  	  dataLabels: {
  				  enabled: false,
  				    style: {
  				      colors: [col]
  				    }
  				
  				  },
  				  legend: {  labels: { colors: [col] }},
  				  series: data,
  				  markers: {
  					  size: [4],
  					  onClick: function(e) { alert(e)}
  					},
  				  stroke: {
  					  width: 2
  					},
  				  xaxis: { 
  					  categories: chart.dates, 
  					  labels: { style: {colors: [col,col,col,col,col,col,col,col,col]}}
  				  },
  				  yaxis: {
  					forceNiceScale: true,
  			        decimalsInFloat: 2,
  			        labels: { style: {colors: [col]}}
  				  },
  				  colors: colors
  				}
         	   that.distroyChart();
         	   that.chart = new ApexCharts(document.querySelector("#srChart"), that.chartOptions);
  		
  		   that.chart.render();
  		   that.$forceUpdate();
        }).catch(e => { console.log(e); showError(that.$toast, e, "SalesRequestChart: ")})
       	 
       

       },
       distroyChart()
	    {
	    	if ( this.chart)
			{
	    		try
	    		{
					this.chart.destroy();
					this.chart = null;
				}
	    		catch(e)
	    		{
	    		//
	    		}
			}
	    }
  },
  destroyed() {

  	window.clearTimeout(this.timeoutID);
  },
  created()
  {
	this.fullSetup = this.setup;
	if ( !this.setup || !this.setup.charts )
	{
		this.mySetup = { group: 'MONTH', chartType: 'area', wHeight: 128, wUnit: 1000}
		this.fullSetup.charts = this.mySetup;
	}
	else
	{
		this.mySetup = this.setup.charts;
	}
	if ( !this.mySetup.wHeight)
	{
		this.mySetup.wHeight = 160;
    }
	if ( !this.mySetup.wUnit)
	{
		this.mySetup.wUnit = 1000;
    }
    this.getNotification();
    this.houseCurrency = sessionStorage.hCurrency;
	this.timeoutID = setInterval(this.getNotification, 60000);
	
	this.$nextTick(function () {
	  	// force recalc () (open&close)--> workaround as the first time the context menu opens at (0,0)
	   	try {
	   	this.$refs.menu.open( {}); } catch (e) {
	   	//
	   	}
	   	this.$refs.menu.close();
   	});
  },
  watch: {
        user: function() {
            this.getNotification();
		 }
    }
}
</script>

<style scoped>
.main {
	display: block;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}
.shadow {
    background-color: white !important;
	width: 100%;
    box-shadow: 5px 5px 8px 0 #444;
}
.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.white {
    //background-color: white !important;
}

.iButton:hover {color: green !important;}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


