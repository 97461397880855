<template>
	    <input v-if="disabled" ref='inp' 
    	       class='myInput' type='text' size="6" 
    	       :style="getBGStyle3()+'width: 100% !important; height: 22pt; font-size: 8pt; text-align: right;'"
    	       @mouseover="focus=true"
    	       @keydown.tab="$emit('tab')" @focus="focus=true" v-model.number="internalValue" />
    	<input  v-else-if="focus" class="myInput" ref='inp' size="6" 
    	       @keypress.enter="enterPressed()" 
    	       @blur="focus=false; $emit('input', value); $emit('blur')"
    	       @keyup.esc="escapePressed()"
    	       @keydown.tab="$emit('tab')"
    	       @focus="$event.target.select()"
    	       
    	       :style="getBGStyle3()+'border: 1px solid #ccc; height: 22pt; font-size: 9pt; text-align: right;'"
    	       v-model="internalValueNF" @click="$event.target.select()"/>
    	<input v-else ref='inp' 
    	       class='myInput' type='text' size="6" 
    	      
    	       :style="getBGStyle3()+'border: 1px solid #ccc; width: 100% !important; height: 22pt; font-size: 8pt; text-align: right;'"
    	       @mouseover="focus=true"
    	       @keydown.tab="$emit('tab')" @focus="focus=true" v-model.number="internalValue" />
    
</template>
<script>
import { getAppStyle, setDarkMode, initAppMode, getBGStyle3 } from '@/AppStyle.js';
import ClickOutside from 'vue-click-outside'
var numeral = require('numeral');
export default {
  name: "InputMoney",
  props: {
    value: Number,
   
    currency: Number,
    currencyObj: Object,
    dataMap: Object,
    nulls: Boolean,
    mark: { type: Boolean, default: false},
    clipboard: { type: Boolean, default: true},
    disabled: { type: Boolean, default: false},
    },
  data () {
    return {
    	focus: false,
		valueOrig: this.value,
    	getBGStyle3
    }
  },  
  directives:{
    ClickOutside,
  },
  components : {
    
  },
  methods: {
      open() { 
      	this.focus = true; 
      	this.$nextTick(function () {
	        this.$refs.inp.focus(); 
	      	this.$refs.inp.select();
         });
      },
      blurry()
      {
		
		this.focus=false; 
		this.$emit('input', this.value); 
		this.$emit('blur')  
	  },
      getStyle() { 
      	if ( this.mark )
      	{
      		return "background-color: #fdd;";
      	}
      	return getAppStyle();
      },
      escapePressed() { this.$emit("escape"); },
      enterPressed() { this.$emit("enter"); },
      onChange() { }
  },
  computed: {
    internalValue: {
      get() {
        this.$emit("input", this.value )
        if ( this.currencyObj && this.currencyObj.code )
        {
        	return this.currencyObj.code+ " "+ numeral(this.value).format( sessionStorage.numberFormat);
        }
        else if ( this.currency && this.dataMap && this.dataMap.data )
        {
        	let crny =  this.dataMap.data.find(c=>c.id==this.currency);
        	if ( crny )
        	{
        		return crny.additionalColumn[0]+ " "+ numeral(this.value).format( sessionStorage.numberFormat);
        	}
        }
        return numeral(this.value).format( sessionStorage.numberFormat);
      },
      set(v) {
    	this.value = numeral(v).value();
        this.$emit("input", numeral(v).value());
		if ( this.value !== this.valueOrig)
		{
			this.valueOrig = this.value;
			this.$emit("changed")
		}
      }
    },
    internalValueNF: {
      get() {
        this.$emit("input", this.value )
        return this.value;
      },
      set(v) {
        if ( v.endsWith('k') )
        {
        	v = numeral(v.substring(0, v.length-1)).value()*1000;
        }
        this.value = numeral(v).value();
        this.$emit("input", this.value);
		if ( this.value !== this.valueOrig)
		{
			this.valueOrig = this.value;
			this.$emit("changed")
		}
      }
    }
  },
  mounted() {
    this.open();
  },
  created() {
	  this.focus = false;
	  this.valueOrig = this.value;
  },
  watch: {
	
   	//value: function() { this.iValue = 0; this.$set( this, 'iValue', this.value);}
  }
 }
</script>
<style lang="scss" scoped>
.inputInt { text-align: right;}
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}
.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 99%;
}

.inputButton { 
    border: 1pt solid grey;
}
.myInput {
	height: 16pt;
	width: 100% !important;
	margin-bottom: 4pt;
	background-color: transparent;
}
</style>