  <template>
  <div >
         <div style='float: top; width: 100%; height: 66%; padding-top: 2pt; margin-top: 2pt; overflow-y: scroll;overflow-x: hidden; ' >
				<!-- {{filterEntities.map(p=>{ return {name: p.type.name, val: p.values.values}; }) }} -->
             	<div style='float: left; height: calc(38vH); width: 81%;'>
		             <div v-for='(filter, idx) in filterEntities' :key="'fltr_'+filter.type.id+'.'+idx+filter.type.name" >
		               
		                <div draggable   
		                      @dragstart.exact="dragStartFilter($event, filter, idx)"
					          @dragend.exact="dragEndFilter($event, filter, idx)" 
					          @dragover.prevent
							  @dragenter.prevent
							  class='copy2Select'
					          @drop.exact="dropFilter($event, idx)"
		                >
                          
			              	<InputInventoryFilter v-if="false"
			              	  @remove="removeFilter(idx)" 
			              	  @copy="copyFilter(idx)" 
			              	  :counter=counter
			              	  :index=idx
			              	  :number=idx
			                  @setStruct=setStruct
			                  :allOperators=allOperators
			                  :operatorId=filter.operator.id
			                  :value=filter 
			              	  :title=filter.name 
			              	  >
			              	</InputInventoryFilter>
		              	</div>
		             </div>
	             </div>
	            
	             <div style='position: sticky; top: 1px; float: right; height: calc(30vH); width: 19%;'>
	               <div v-for='idx in invFilterArrayMatched' :key="idx.id" style='display: inline'>
	              	<span :title='tooltipFilterAdd(idx.name)'>
	              		<button @click="addFilter(idx,true)" class='button active'><b>{{idx.name}}</b></button>
	              	</span>
	              </div>    
	             </div>

        </div>                

  </div>
</template>

<script>
import GLink from '@/components/GLink';
import GSelect from '@/components/GSelect';
import GSelectEntity from '@/components/GSelectEntity';
import GSelectModel from '@/components/GSelectModel';
import GSelectM from '@/components/GSelectM';
import InputInventoryFilter from '@/components/inputElements/InputInventoryFilterNew';
import InputText from '@/components/inputElements/InputText';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputTime from '@/components/inputElements/InputTime2';
import GImage from '@/components/GImage';
import InputSecret from '@/components/inputElements/InputSecret';
import InputTimezone from '@/components/inputElements/InputTimezone';
import InputInt from '@/components/inputElements/InputInt';
import InputDouble from '@/components/inputElements/InputDouble';
import InputDateTime from '@/components/inputElements/InputDateTime';
import InputDatePick from '@/components/inputElements/InputDatePick';
import Switch from '@/components/Switch'

import {HTTP, fwAPI, invAPI } from '@/variables.js'

var timers = [];
export default {
  name: "GFWEditorInventoryPanel",
   components : {
    InputInventoryFilter
  },
  props: {
   filterEntities: Array,
   activeFilter: Array, 
   allOperators: Array,
   invFilterArrayMatchedINP: Array,
   invFilterArrayDisabled: Array,
   opArray: Array
  },
  data(){
    return {
        counter: 0,
        filterEntitiesLocal: [...this.filterEntities],
        invFilterArrayMatched: [...this.invFilterArrayMatchedINP]
     }
    },
  methods: {
        getOp( idx) { return this.filterEntities[idx].operatorId },
        name() { return "FW inventory filter panel" },
        tooltipFilterAdd(txt) { return "add filter: " + txt; },
        tooltipFilterAdded(txt) { return "filter: " + txt + " already used"; }, 
        dragStartFilter(evt, filter, iRow)
	    {
	    	evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('iRow', iRow)
	    },
	    dragEndFilter(evt, filter, iRow)
	    {
	    },
	    
	    dropFilter(evt, iRow)
	    {
	    	
	    	let filters = [... this.filterEntities]
	    	let selectedFilter = this.filterEntities[iRow];
	    	let fieldRow = evt.dataTransfer.getData('iRow');
	    	let moveFilter = {...filters[fieldRow]};
	    	
	    	//alert( copyId)
	    	//alert( JSON.stringify( this.selected))
	    	
	    	this.activeFilter.splice( fieldRow, 1);
	    	this.activeFilter.splice( iRow, 0, true )
	    	filters.splice( fieldRow, 1); // remove old
	    	filters.splice( iRow, 0, moveFilter);
	    	//this.$set(this, "filterEntities", filters);
	    	this.$emit("rearrange", filters)
	    	//this.counter = new Date().getTime();
	    },
	    
        addFilter( filter, onOff, filterObject)
        {
			this.$emit("addFilter", filter, onOff, filterObject)
        },
        removeFilterByType(type)
        {
        	let filter = this.filterEntities.find( (p,i)=>p.type.id == type.id && this.activeFilter[i]);
        	this.$emit("removeFilter", this.filterEntities.indexOf(filter));

        },
        removeFilter(idx)
        {
        	this.filterEntities.splice( idx, 1);
        	this.$emit("rearrange", this.filterEntities)
        },
        copyFilter(idx)
        {
        	this.$emit("copy", this.filterEntities[idx])
        },
        setOperator(index, operatorData)
        {
        	this.$emit( "setOperator", index, operatorData)
        },
        setOnOff(index, onOff)
        {
            this.$emit( "setOnOff", index, onOff)
        	//this.onOffArr[index] = onOff;
        },

        setStruct( index, struct)
        {
        	this.$emit( "update", struct)
        },
        getTemp()
        {
        	return Math.random()*10000;
        }  
        
  },
   updated() {
     if ( !this.loaded )
     {
     	this.loaded = true;
     	//this.reload(this.dataId);
     }
     
  },
  computed: {
	msgDisplayClass: function () { return this.msgClass; }
  },
  
  created() {
  			
  },
   watch:
   {
	    //invFilterArrayMatchedINP: function() { alert(this.invFilterArrayMatchedINP.length); this.invFilterArrayMatched =[...this.invFilterArrayMatchedINP]; this.$forceUpdate()},
		//filterObjectArr: function() { this.counter = new Date().getTime();},
        filterEntities: function() { this.counter = new Date().getTime();  }
   }
}
</script>
<style  scoped>
.editorElement {
     display: inline-flex; 
     padding-bottom: 8pt;
     padding-top: 2pt;
     padding-right: 8pt;
 }
.elementLabel {
    display: inline-flex;
 }
.required:after {
    content:" *";
    color: red;
 }
.elementField {
    display: inline-flex;
     //border: 1pt solid yellow;
     width: 100%;
 }
.lUpdateInfo {
    font-size: 11pt;
    color: #888;
}
.lUpdateInfoSuccess {
    vertical-align: middle;
    
}
.mainContent {
    height: 100%;
    transition: all 0.4s ease;
    //border: 1pt solid blue;
}
.appHeadLine { 
    width: 100%; 
    border-bottom: 1pt solid #aaa; 
    text-align: right;
    margin-bottom: 2pt; 
    font-weight: bold;
    padding: 10pt 10pt 1pt;}
    
.appCounter {
	display: inline-grid;
	font-size: 11pt;
	color: #888;
}    
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;}
    
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  //height: 60%;
  width: 60%;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
  //border: 1pt solid blue;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn-nav {
    font-size: 9pt;
    vertical-align: middle;
    valign: middle;
}
.btn-save {
	background-color: #ded;
}
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}
.msgDefault {
	font-size: 9pt;
}
.msgError {
	font-size: 9pt;
	font-weight: bold;
	color: #bb0000;
}
.button {
	width:50%;
	font-size: 8pt;
	width:50%;
	padding-top:1pt; 
	padding-botton: 1pt;
}
.inactive
{
	background-color: #fff; 
	
}
.active {
	background-color: #fff;
}
 

</style>