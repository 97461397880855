<template>
	<vue-modal-2 :darkMode="false" style='z-index: 999;'
       :headerOptions="{title: title}" 
       :footerOptions="{ btn1: button1, btn2: button2, btn1OnClick:closeCancel, btn2OnClick:doIt}"
       :name="name"  @on-close="close()">
      <div class='puInfo' v-if="text" style='padding-top: 1em; padding-bottom: 1em;'>
        {{text}}
        <br v-if="text2"/>
        {{text2}}
      </div>
   </vue-modal-2>
</template>

<script>

import {defer} from '@/utils.js';

export default {
  name: 'GConfirm',
  components : {
	 
  },
  props: {
	name: {type: String, default: "modal-confirm"}
  },
  data () {
    return {
        text: 'xxx',
        text2: '',
        title: 'delete',
        button1: 'Cancel',
        button2: 'Ok',
		confirmation: null
    }
  },
  directives:{

  },
  
  methods: {
	doIt() {
	    this.confirmation.resolve();
		this.$emit("ok");
		this.$vm2.close(this.name);
	},
	closeCancel() {
	    this.close();
	    this.confirmation.reject();
	},
	close() {
		this.$vm2.close(this.name);
	},
	confirm(data) 
	{
		this.$set( this, "text", data.text);
		this.$set( this, "text2", data.text2);
		this.$set( this, "title", data.title);
		this.$set( this, "button1", data.button1);
		this.$set( this, "button2", data.button2);
		this.$vm2.close(this.name);
		this.text = data.text;
		this.$forceUpdate();
		let that = this;
		that.$vm2.open(this.name);
		this.confirmation = defer();
		return this.confirmation;
	}
  },
  watch: {

  },
  created() {

  }
}
</script>
<style scoped >

</style>
