<template>
<div class='inputWrapper'>
    <span class='span25'>
    <input v-if="border" class="inputBorder" :style='getStyle()' :disabled=disabled 
    	@change="changed" 
    	@keypress.enter="enterPressed()" 
    	@focus="$event.target.select()" v-model="internalValue"/>
    <input v-else class="inputText" :style='getStyle()' :disabled=disabled 
    	@change="changed" 
    	@keypress.enter="enterPressed()" 
    	@focus="$event.target.select()" v-model="internalValue"/>
    </span>
    <span class='span75'>
    <span @click="downloadObjectAsJson( internalValue, name)" style="padding-right: 4pt;"><mdicon size="16" name='download'></mdicon></span>
    <clipboard v-model="internalValue"><icon-write /></clipboard>
    </span>
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
export default {
  name: "GFInputJSON",
  props: {
    value: String,
    name: { type: String, default: "layout"},
    disabled: Boolean,
    mark: { type: Boolean, default: false},
    border: { type: Boolean, default: false}
  },
  components : {
    clipboard
  },
  methods: {
      getStyle() { 
      	if ( this.mark )
      	{
      		//return "background-color: #fdd;";
      	}
      	return getAppStyle();
      },
      enterPressed() { this.$emit("enter"); },
      changed() { this.$emit("changed", this.value); },
      downloadObjectAsJson(exportObj, exportName){
	    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(JSON.parse(exportObj), null, 2));
	    var downloadAnchorNode = document.createElement('a');
	    downloadAnchorNode.setAttribute("href",     dataStr);
	    downloadAnchorNode.setAttribute("download", exportName + ".json");
	    document.body.appendChild(downloadAnchorNode); // required for firefox
	    downloadAnchorNode.click();
	    downloadAnchorNode.remove();
	  }
  },
  computed: {
    internalValue: {
      get() {
        return JSON.stringify(this.value)
      },
      set(v) {
        this.$emit("input", JSON.parse(v))
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-weight: bold; font-size: 12pt; border: none; width: 100%; color: #888; background-color: #fff;}
input:focus { outline: 1pt dotted grey;}

.inputText { text-align: left;}
.inputBorder { font-size: 12pt; padding: 2pt; text-align: left; border: 1pt solid #ccc; width: 80%;  }

.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>