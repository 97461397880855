<template>
  <div class="main white" >
       <div class='white headLine'>
	       <div class='white' style=' width: 90%; float: left;'>
	       	<b :style="'font-size: '+(fontSize+1)+'pt; padding-left: 10pt;'">Last notifications for {{user.login}}:</b>
	       </div> 
	       <div style='float: right;' @click='$emit("close")'>
	       		<WindowCloseBN/>
		   </div>
	  </div>
	  <br/>
	  <div class='white body'>
       <div class='white' style='width: 100%; height: 80pt; overflow-y: auto;'>
      	<div v-for="(h,idx) in notifications" :key="'h_'+idx" class='item' 
      	     @click='openImmed( h)'
      	     @contextmenu.prevent="openCM($event, h)"
      	     :style="getStyle(h)">
      	       {{printTime( h)}}
      		  
      		  {{printNoty( h)}}
      		  <span @click="download(h.fileId, 'download')">
      		  <mdicon v-if='h.fileId' :width="10" :height="10" name="download" /> 
      		  </span>
      	</div>
       </div>
	 </div>

    <GFWEOpenEditor ref='editor' ></GFWEOpenEditor>

    <ContextMenu ref="menu" >
      <template v-if="contextData"  slot-scope="{ contextData }">
       
        <ContextMenuItem v-if="contextData.createdFromValue==='dashboardCheck'" @clicked="closeCMN(); showDashboard(contextData);">
          Show Dashboard 
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.placementId'  @clicked="closeCMN(); openBookMan(contextData);">
          Open Traffic Manager 
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.dealId' @clicked="openDeal( contextData.dealId);">
          Open {{tr('Deal')}} 
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.campaignId' @clicked="openCampaign( contextData.campaignId);">
          Open {{tr('Campaign')}} 
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.placementId' @clicked="openPlacement( contextData.placementId);">
          Open {{tr('Placement')}} 
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.createdFromValue==="operationsComplete"'  @clicked="closeCMN(); showDelivery(contextData);">
          Open Delivery Screen
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.createdFromValue==="operationsInput"'  @clicked="closeCMN(); showTrafficInput(contextData);">
          Open Traffic Complete Screen
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData.moduleId && contextData.dataId' @clicked="openEditor(contextData);">
          Open Record 
        </ContextMenuItem>
        <ContextMenuSep v-if='contextData.placementId || contextData.campaignId'/>
        <ContextMenuItem @clicked="openNotification( contextData.id);">
          Open Notification 
        </ContextMenuItem>
        
        
         <ContextMenuItem v-if='contextData.fileId'  @clicked="closeCMN(); download(contextData.fileId, 'download');">
          Download File 
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem  @clicked="closeCMN(); checkNotification(user.id,contextData.id)">
          Remove
        </ContextMenuItem>
         
     </template>
    </ContextMenu>
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, notifyAPI, userAPI, opsAPI, myTimer, receiveToken, myName, setNotyWidgetReload, showError} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import { getAppStyle, setDarkMode, isDarkMode, initAppMode } from '@/AppStyle.js';
import { printUTCHHmm } from '@/basicTimeFN.js';
import { tr } from '@/translate.js';
import store from "@/store";
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
export default {
  name: 'GFW_LOGINS',
  components : {
   ContextMenu, ContextMenuItem, ContextMenuSep,WindowCloseBN
  },
  props: {
   	user: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
  data: function () {
    return {
      tr, 	
      notifications: [],
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
       printTime(h) {
            return printUTCHHmm(h.lUpdate);
       },
        printNoty(h) {
            return h.message;
       },
       show(x)
       {
    	 alert(JSON.stringify(x))  
       },
       showDashboard(x)
       {
    	   let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/dataMiner').name;
	    	if ( !viewName )
	    	{
	    		return;
	    	}
	    	
	    	this.$router.replace({name: viewName, params: { open: 'checks'}});
       },
       openImmed( h)
       {
    	   if ( h.createdFromValue==='dashboardCheck' )
    	   {
    		   let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/dataMiner').name;
	   	    	if ( !viewName )
	   	    	{
	   	    		return;
	   	    	}
   	    	
   	    	   this.$router.replace({name: viewName, params: { open: 'checks'}});
    	   }
    	   if ( h.createdFromValue==='operationsComplete' && h.placementId && h.dataId )
    	   {
    		   this.showDelivery( h)
    	   }
    	   if ( h.createdFromValue==='operationsInput' && h.dataId )
    	   {
    		   this.showTrafficInput( h)
    	   }
       },
       showTrafficInput(n)
       {
    	    let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/workflowOperationsTrafficAssign').name;
	    	if ( !viewName )
	    	{
	    		return;
	    	}
	    	
	    	this.$router.replace({name: viewName, params: { id: n.dataId}});
       },
       showDelivery(n)
       {
    	    let viewName = this.$router.matcher.getRoutes().find(p=>p.path==='/workflowOperationsTrafficDelivery').name;
	    	if ( !viewName )
	    	{
	    		return;
	    	}
	    	
	    	this.$router.replace({name: viewName, params: { id: n.dataId}});
       },
       getStyle(h) {
    	    if ( isDarkMode() )
    	    {
    	    	let col = "#000";
    	    	let fg = "#fff"
                let extra = '';
                if ( h.typeValue === 'success' )
                {
                	col = "#030";
                } 
                if ( h.typeValue === 'warning' )
                {
                	col = "#046";
                } 
                if ( h.typeValue === 'error' )
                {
                	fg = "#fcc"
                	col = "#800";
                	extra = 'font-weight: bold;'
                } 
           		return "color: "+fg+"; background-color: "+col+";font-size: "+this.fontSize+"pt; padding-left: 10pt;"+extra;
    	    }
            let col = "#fff";
            let extra = '';
            if ( h.typeValue === 'success' )
            {
            	col = "#efe";
            } 
            if ( h.typeValue === 'warning' )
            {
            	col = "#ffe";
            } 
            if ( h.typeValue === 'error' )
            {
            	col = "#fdd";
            	extra = 'font-weight: bold;'
            } 
       		return "color: #000; background-color: "+col+";font-size: "+this.fontSize+"pt; padding-left: 10pt;"+extra;
       },
       download( fileId, doWhat )
       {
    	let that = this;
    	if ( doWhat == 'download')
        {
	    	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
                let downloadResponse = response.data;
           		that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }
        else
        {
        	
        	HTTP.post( opsAPI+"/download/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+fileId)
	        .then( response => 
	        { 
               
                that.$refs.pdfView.open(response.data.file)
           		
        	}).catch(e => (showError( that.$toast, "file download error", e)))
        }

    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	   },
      openCM(event, notification){
        event.stopPropagation();
        event.preventDefault();
        this.$refs.menu.open(event, notification )
	   },
	   closeCMN() { try{ this.$refs.menu.close(); } catch(e) { /* */ }},
       openBookMan( noty ) 
       {
			setGoBack( this.$router.currentRoute.path, "Desktop");
			if ( noty.mediaId )
			{
				this.$router.replace('/bookMan?toOpen=Placement&id='+ noty.placementId);
			}
			else
			{
				this.$router.replace('/bookMan?toOpen=Placement&list=true&id='+ noty.placementId);
			}
       },  
       openEditor(noty)
       {
			this.closeCMN(); 
			if ( noty && noty.moduleId && noty.dataId )
			{
				this.$refs.editor.open(noty.moduleId, noty.dataId);
			}
       },
       openNotification( id)
       {
			this.closeCMN(); 
			this.$refs.editor.open('Notification', id);
       },
       openPlacement( id)
       {
			this.closeCMN(); 
			this.$refs.editor.open('Placement', id);
       },
       openCampaign( id)
       {
			this.closeCMN(); 
			this.$refs.editor.open('Campaign', id);
       },
       openDeal( id)
       {
			this.closeCMN(); 
			this.$refs.editor.open('Deal', id);
       },
       addNotification(n) 
       { 
          this.notifications.splice(0,0,n);
       },
       getNotification() {
       	   let that = this;
       	   //alert( notifyAPI+"/getNotifications/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+this.user.id);
       	   HTTP.post( notifyAPI+"/getNotifications/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+this.user.id) 
	            .then( response => 
	               { 
	              	 let notifications = new Array();
	              	 //
	              	 //alert( JSON.stringify(response.data));
	              	 for ( var h in response.data )
	              	 {
	              	 	let hist = response.data[h];
	              	 	
	              	 	notifications.push( hist);

	              	 	
	              	 }
	              	 that.notifications = notifications;
    
	               }).catch(e => {
	                    showError( that.toast, e, { position: "topRight" });
			      });
       },
       checkNotification(userId, notyId) {
       	    let that = this;
       	   //alert(notifyAPI +"/checkNotifications/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+userId+"/"+notyId)
       	   HTTP.put( notifyAPI+"/checkNotifications/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+userId+"/"+notyId) 
	            .then( response => 
	               { 
	              	//alert( JSON.stringify(response.data));

	              	 that.notifications = response.data;
    
	               }).catch(e => {
	                     showError( that.toast, e, { position: "topRight" });
			      });
       }
  },
  destroyed() {
  	setNotyWidgetReload( null);
  },
  created()
  {
    setNotyWidgetReload( this.addNotification);
	this.getNotification();
	
  },
  watch: {
        user: function() {
            this.getNotification();
		 }
    }
}
</script>

<style scoped>

.main {
	display: block;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}
.shadow {
    background-color: white !important;
	width: 100%;
    box-shadow: 5px 5px 8px 0 #444;
}
.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.white {
    //background-color: white !important;
}

.iButton:hover {background-color: #e8f0e8 !important;}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


