var translationMap = new Array();
var titleMap = new Array();

export function defineTranslation( translate)
{
	translationMap = translate;
}

export function tr( label )
{
	let translation = translationMap[label];
	return ( translation ) ? translation : label;
} 

export function setTrTitle( label, tr )
{
	titleMap[label] = tr;
} 

export function trTitle( label, fallback )
{
	let translation1 = titleMap[label];
	let translation2 = titleMap[fallback];
	
	if ( !translation2 && translation1 )
	{
		setTrTitle( fallback, translation1);		
	}
	if ( !translation1 && translation2 )
	{
		setTrTitle( label, translation2);	
		translation1 = translation2;	
	}
	return ( translation1 ) ? translation1 : fallback;
} 