<template>
  <div id='body' class='body' @contextmenu.prevent="openCM($event)" :style="getAppStyle()+'height: calc(100vH - 150px) !impoertant;'">
     <div style="float: top;">
    	<div v-for="(w,i) in widgets" draggable :key="'w_'+i" :class="showSettingsWidget?'invisibleItem':'item'"
    			  @dragstart.exact="dragStartWidget($event, w, i)"
		         @dragend.exact="dragEndWidget($event, w, i)" 
		         @dragover.prevent
			      @dragenter.prevent
		         @drop.exact="dropWidget($event, i)" 
    	>
      <div v-if="w=='task'"  class='transition' 
          :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWTasks :fontSize="Math.min(fontSize,11)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
      <div v-else-if="w=='noty'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWNotifications :fontSize="Math.min(fontSize-3,8)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
       <div v-else-if="w=='logs'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWLogs :fontSize="Math.min(fontSize-3,8)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
      <div v-else-if="w=='errorlogs'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWErrorLog :fontSize="Math.min(fontSize-3,8)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
      <div v-else-if="w=='search'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWSearch :fontSize="Math.min(fontSize-3,8)" :user="user" @add="add" @open="open" @close='widgets.splice(i, 1)'/>
      </div>
      <div v-else-if="w=='servers'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWServerSwitch :fontSize="Math.min(fontSize-3,8)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
      <div v-else-if="w=='bgWidget'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWBGSwitch :fontSize="Math.min(fontSize-3,8)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
      <div v-else-if="w=='login'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWLastLogins :fontSize="Math.min(fontSize-3,8)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
      <div v-else-if="w=='chart'" class='transition' :style="'box-shadow: '+shadow1+'px '+shadow1+'px '+shadow2+'px 0 '+shadowColor+'; border: 1pt solid #ccc; float: top; width: 95%; margin-top: 5pt; margin-right: 10pt;'">
       <GFWChart :setup="widgetsSetup" @setup="widgetsSetupChange" :fontSize="Math.min(fontSize-3,8)" :user="user" @close='widgets.splice(i, 1)'/>
      </div>
     </div>
	</div>

  	
	
  	<ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">

        <ContextMenuItem v-if='!widgets.includes("task")' @clicked="widgets.push('task'); $refs.menu.close();">
          Show Tasks Widget 
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("login")' @clicked="widgets.push('login'); $refs.menu.close();">
          Show Last Login Widget
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("logs")' @clicked="widgets.push('logs'); $refs.menu.close();">
          Show Logs Widget
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("errorlogs")' @clicked="widgets.push('errorlogs'); $refs.menu.close();">
          Show ErrorLogs Widget
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("chart")' @clicked="widgets.push('chart'); $refs.menu.close();">
          Show Sales Request Widget
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("noty")' @clicked="widgets.push('noty'); $refs.menu.close();">
          Show Notification Widget
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("search")' @clicked="widgets.push('search'); $refs.menu.close();">
          Show Module Quick-Search Widget
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("servers")' @clicked="widgets.push('servers'); $refs.menu.close();">
          Show Server Widget
        </ContextMenuItem>
        <ContextMenuItem v-if='!widgets.includes("bgWidget")' @clicked="widgets.push('bgWidget'); $refs.menu.close();">
          Background Process Widget
        </ContextMenuItem>
        <ContextMenuSep />
        <ContextMenuItem  @clicked="$emit('showSettings'); $refs.menu.close();">
          Show Settings Widget
        </ContextMenuItem>
        <ContextMenuSep />
        
         <ContextMenuItem  @clicked="$emit('reload'); $refs.menu.close();">
          Reload
        </ContextMenuItem>
        <ContextMenuItem  @clicked="$emit('save'); $refs.menu.close();">
          Save
        </ContextMenuItem>

       </template>
    </ContextMenu>
    <div style="position: fixed; bottom: 0; right: 0; display: flex; font-size: 7pt;">
	    {{getCopyright()}}
	</div>
  </div>   
</template>

<script>
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import { getCopyright } from '@/utils.js';
import GFWLastLogins from '@/components/desktopWidgets/GFWLastLogins';
import GFWChart from '@/components/desktopWidgets/GFWChart';

import GFWLogs from '@/components/desktopWidgets/GFWLogs';
import GFWErrorLog from '@/components/desktopWidgets/GFWErrorLog';
import GFWNotifications from '@/components/desktopWidgets/GFWNotifications';
import GFWSearch from '@/components/desktopWidgets/GFWSearch';
import GFWTasks from '@/components/desktopWidgets/GFWTasks';
import GFWServerSwitch from '@/components/desktopWidgets/GFWServerSwitch';
import GFWBGSwitch from '@/components/desktopWidgets/GFWBGSwitch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';

import { tr } from '@/translate.js';
var momentTZ = require('moment-timezone');
import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
export default {
  name: "FWWorkbenchWidgtes",
  components : {
   ContextMenu, ContextMenuItem, ContextMenuSep, 
   GFWLastLogins, GFWNotifications, GFWLogs, GFWSearch, GFWTasks, GFWServerSwitch,GFWBGSwitch, GFWChart, GFWErrorLog
  },
  props: {
	    widgets: { type: Array },
	    showSettingsWidget: { type: Boolean, default: true},
	    widgetsSetup: { type: Object },
	    fontSize: { type: Number },
	    shadow1: { type: Number },
	    shadow2: { type: Number },
	    shadowColor: { type: String },
	    user: { type: Object }
	  },
  data() {
      return {
    	  getAppStyle, getCopyright
      }
  },
  methods: {
	  	
	  	openCM(event) 
        { 
	  	  event.preventDefault();
	  	  event.stopPropagation();
	  	  this.$refs.menu.open( event, {} );
        },
        open( menu)
	    {
        	this.$emit("open", menu)
	    },
	    add( menuEntity, menuTitle)
	    {
	    	this.$emit("add", menuEntity, menuTitle)
	    },
        widgetsSetupChange( newSetup )
        {
        	this.$emit("widgetsSetup", newSetup);
        },
        dragStartWidget(evt, widgetName, iRow)
	    {
	    	evt.dataTransfer.dropEffect = 'move'
	        evt.dataTransfer.effectAllowed = 'move'
	        evt.dataTransfer.setData('iCell', widgetName)
	        evt.dataTransfer.setData('iRow', iRow)
	    },
	    dragEndWidget(evt, widgetName, iRow)
	    {
	    },
	    dropWidget(evt, iRow)
	    {
	    	let widgetsCopy = [... this.widgets];
	    	let fieldCell = evt.dataTransfer.getData('iCell');
	    	let fieldRow = evt.dataTransfer.getData('iRow');
	    	//alert( JSON.stringify(this.widgets)+fieldRow+"-->"+iRow)
	    	widgetsCopy.splice( fieldRow, 1); // remove old

	    	//alert( JSON.stringify(this.widgets)+fieldRow+"-->"+iRow)
	    	widgetsCopy.splice( iRow, 0, fieldCell);
	    	this.$emit("widgets", widgetsCopy);
	    	
	    }
	  
  }, 
  beforeDestroy() {
	  
  },
  created() {
    
	
	},
	mounted() {
		
		
	
	
  },
  destroyed() {
  	
  },
  watch: {
	  
    },
};
</script>
<style lang="scss" scoped>
.body {
	overflow-y: auto;
}
</style>