<template>
  <div class="main" :style="getAppStyle()">
       <div class='headLine' :style="getAppStyle()">
	       <div :style="getAppStyle()+'width: 90%; float: left;'">
	       	<b :style="'font-size: '+(fontSize+1)+'pt; padding-left: 10pt;'">Last logins/actions for {{user.login}}:</b>
	       </div> 
	       <div :style="getAppStyle()+'float: right;'">
	       		<WindowCloseBN @click="$emit('close')"/>
		   </div>
	  </div>
	  
	  <div class='white body' :style="getAppStyle()">
       <div class='white' style='width: 100%;' :style="getAppStyle()">
      	<div v-for="(h,idx) in history" :key="'h_'+idx" class='item dontWrap'
      	     @click="$refs.editor.open('History', h.id)"
			 :title="printChange(h)"
      	     :style="'background-color: '+((h.ip!==h.service)?'#ffc':getBG())+';color: '+((h.ip!==h.service)?'#000':getFG())+'; font-size: '+fontSize+'pt; padding-left: 10pt;'">
      		 {{printChange(h)}} 
      	</div>
       </div>
	 </div>

    <GFWEOpenEditor ref='editor' ></GFWEOpenEditor>
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, userAPI, myTimer, receiveToken, myName} from '@/variables.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import store from "@/store";
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2, getBG, getFG, getBGStyle3, autoFG } from '@/AppStyle.js';

export default {
  name: 'GFW_LOGINS',
  props: {
   	user: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
   components : {
	   WindowCloseBN
	  },
  data: function () {
    return {
      history: [],
      getAppStyle,getBG,getFG,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
    printChange(h) {
            var fmt = sessionStorage.dateFormat+ " " + sessionStorage.timeFormat;
            var time = "";
	        try
	        {
	            time = momentTZ(new Date(h.lUpdate)).tz(sessionStorage.timezone).format(fmt);
	        }
	        catch ( e) {
	        //
	        }
       		if ( h.changes.indexOf("lastLogin") >= 0)
      		{
      			return "Login: " + time + " from " + h.ip + " ("+h.city+")";
      		}
      		else
      		{
      			return "Password change: " + time + " from " + h.ip + " ("+h.city+")";
      		}
       },
       getHistory(userId) {
       	    let that = this;
       	   HTTP.post( userAPI+"/getLoginHistory/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+userId) 
	            .then( response => 
	               { 
	              	 let history = new Array();
	              	 //alert( JSON.stringify(response.data));
	              	 for ( var h in response.data )
	              	 {
	              	 	let hist = response.data[h].history;
						hist.city = response.data[h].city;
	              	 	
	              	 	history.push( hist);
	              	 	if ( history.length > 6 )
	              	 	{
	              	 		break;
	              	 	}
	              	 	
	              	 }
	              	 that.history = history;
    
	               }).catch(e => {
	                     
			              that.$toast.error(e.response.data, 'Error', { position: "topRight" });
			      });
       }
  },
  created()
  {
	this.getHistory(this.user.id);
	
  },
  watch: {
        user: function() {
            this.getHistory(this.user.id);
		 }
    }
}
</script>

<style scoped>
.main {
	display: block;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}
.shadow {
    background-color: white !important;
	width: 100%;
    box-shadow: 5px 5px 8px 0 #444;
}
.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow-x: hidden;
  overflow-y: hidden;
}
.iButton:hover {color: #e8f0e8 !important;}
.item { cursor: pointer; display: block; white-space: nowrap; max-width: 98%;}
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


