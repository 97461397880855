<template>
<div :id='GLinkId' class='inputWrapper' >
     <!--{{s}}/{{t}}/{{visible}}//{{this.definition}}///{{this.data.id}}-->
     
     <vue-table-dynamic v-if="data.id" @cell-click='click' @cell-contextmenu="ctxMenu" :params="linkData"></vue-table-dynamic>
     <vue-table-dynamic v-else :params="linkDataDefault"></vue-table-dynamic>
     
	 <ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="click(selectedRow)">
          Open {{definition.entity}}: {{ contextData.visualDisplay }} 
        </ContextMenuItem>
         <ContextMenuItem  @clicked="deleteRow(selectedRow)">
          Delete {{definition.entity}}: {{ contextData.visualDisplay }} 
        </ContextMenuItem>
        </template>
    </ContextMenu>
    
    <vue-modal-2 :darkMode="false" :noFooter=false 
      :headerOptions="{title: 'Reference check'}" 
      :footerOptions="{ btn1: 'Close', disableBtn2:true,  btn1OnClick:closeInfoPU}"
      name="modal-info"  @on-close="closeInfoPU">
      <div class='puInfo' style='padding-top: 0em; padding-bottom: 1em;'>
        Found linked record: 
		<li v-for="item in infoLinkedRecords" :key="item.id">{{item.linkedEntity+": " +item.linkedRecord}}</li>
      </div>
   </vue-modal-2>

    <vue-modal-2 :darkMode="false" 
       :headerOptions="{title: 'Delete'}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Delete', btn1OnClick:closeDeleteConfPU, btn2OnClick:deleteConfirmed}"
       name="modal-deleteConf"  @on-close="closeDeleteConfPU">
      <div class='puInfo' style='padding-top: 1em; padding-bottom: 1em;'>
        Delete {{metadata.name}}: {{selectedRecord.visualDisplay}}
      </div>
   </vue-modal-2>
</div>
</template>

<script>
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';

import {HTTP, fwAPI, accessPointAPI, APIURL} from '@/variables.js';
export default {
   name: "GLink",
   components : {
    ContextMenu, ContextMenuItem
  },
  props: {
    disabled: Boolean,
    visible: Boolean,
    data: Object,
    id: Number,
    s: Number,
    t: Number,
    time: Number,
    counter: Number,
    metadata: Object,
    definition: Object
  },
  data() {
  	return {
  	   linkData: { orig: [], data: [],
            header: 'row',
            height: 180,
            stripe: false,
            pagination: true,
            pageSize: 5,
            enableSearch: true,
            sort: [0, 1, 2, 3],
            pageSizes: [2, 5, 10, 15, 20, 25],
            columnWidth: [
                
                ],
            border: true },
       linkDataDefault: { data: [
          ['Header1', 'Header2', 'Header3'],
          ['1', '2', '3']],
            header: 'row',
            height: 180,
            stripe: false,
            pagination: true,
            pageSize: 5,
            enableSearch: false,
            sort: [0, 1, 2, 3],
            pageSizes: [2, 5, 10, 15, 20, 25],
            columnWidth: [
                ],
            border: true },
        header: [],
        columns: [],
        loadedData: "",
        GLinkId: "GLinkId"+Math.random()*10000,
        selectedRecord:  {},
        selectedIndex: 0, 
        optionMapping: [],
        mapping: [], // row mapping according to visual table
        mappingReverse: [], // id -> rowNo
        row2IdMapping: [],  // rowNo -> id
        infoLinkedRecords: [],
        errorPU: {},
        errorTypePU: ""
  	}
  },
  methods: {
    click(rowIndex, columnIndex, data) {
        this.selectedRecord = {};
        //console.log( "click() " + JSON.stringify(this.definition));
        this.selectedIndex = this.mapping.find(p => p.rId==rowIndex).row;
        //alert(  rowIndex + "--> " + JSON.stringify( this.mapping.find(p => p.rId==rowIndex).row));
        Object.assign( this.selectedRecord, this.linkData.orig[ rowIndex - 1]);
        this.showEditor = true;
		this.$emit("open", this.selectedRecord.id, this.definition.entity );
    },
    ctxMenu(event, rowIndex) // , columnIndex, data) 
      { 
         
          if ( rowIndex > 0 )
          {
            let usr = this.linkData.data[rowIndex];
            let user = this.linkData.orig.find(p => p.id === usr[this.id]);
            let idx = this.mapping.find(p => p.rId==rowIndex).row;
            this.selectedRow = rowIndex;
            let record = this.linkData.orig[ rowIndex - 1];
            var element = document.getElementById(this.GLinkId);
            var rect = element.getBoundingClientRect();
            //alert( rect.left+"/"+ rect.top+ "/"+;
            this.$refs.menu.open(event, record, rect.left, rect.top )
           }
      },
    closeCTM() { try { this.$refs.menu.close(); } catch(e){/**/}},
  	getData()
  	{
  		console.log("====== getData() ======")
  	    // dont get data, if folder is not visible
  	    if ( ! this.data || ! this.data.id || ! this.visible)
  	        return;
		
		let key = this.metadata.name+"."+this.definition.name+"."+this.data.id+"."+this.counter;
		console.log("key              >> " + key)
		console.log("this.loadedData  >> " + this.loadedData)
		console.log("this.loadedData  => " + (this.loadedData==this.loadedData))
  	    if ( key == this.loadedData )
  	    {
  	    	return;
  	    }    
  	    let that = this;
  	    //console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/link/"+this.data.id );
  	    //console.log( "data-id: "+ this.data.id);
  	    //console.log( "link-id: "+ this.definition.name);
  	    HTTP.post( fwAPI+"/getLink/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/"+this.data.id, 
    	           { link: this.definition, data:  JSON.stringify(this.data) })
    	          
            .then( response => 
            { 
                  try{        
                  		
                        that.loadedData = key;
                        let respData = response.data;
                        that.entityMap = respData.entity2DataMap;
		                for ( var column in respData.column2EntityMap )
		                {
		                    //console.log(column);
		                    that.optionMapping[column] = [];
		                    //console.log(column + " -->" + respData.column2EntityMap[column]);
		                    let entity = respData.column2EntityMap[column];
		                    let array = respData.entity2DataMap[ entity].data;
		                    //console.log( JSON.stringify(array));
		                    for ( var opt in array)
		                    {
		                        that.optionMapping[column][ array[opt].id ] = array[opt].label;
		                    }
		                }
		    	        
		    	        that.linkData.data = [];
                        that.linkData.orig = response.data.data;
                        //console.log(JSON.stringify( response.data ));
                        that.header = new Array();
                        that.columns = new Array();
                        for(var cell in response.data.header)
                        {
                        	that.header.push( response.data.header[cell].display);
                            that.columns.push( response.data.header[cell].column);
                        }
                        that.linkData.data.push(that.header);
                        var rowNo = 0;
                        that.mapping = [];
                        that.mapping.push({row: 0, dataId: 0 });
                        for(var row in that.linkData.orig)
                        {
                        	rowNo++;
  							that.mapping.push({row: rowNo, dataId: that.linkData.orig[row].id, rId: rowNo, visible: true});
  							that.row2IdMapping[ rowNo ] = that.linkData.orig[row].id;
  							that.mappingReverse[ that.linkData.orig[row].id] = rowNo;
                            let cells = new Array()
                            for( cell in that.columns)
                            {
                                let x = that.linkData.orig[row][that.columns[cell]];
                                if ( that.optionMapping[ that.columns[cell]] )
		                        {
		                            if ( Array.isArray(x))
		                            {
		                                var val = "";
		                                for ( var xi in x )
		                                {
		                                    val += that.optionMapping[ that.columns[cell]][x[xi]]+" ";
		
		                                }
		                                x = val;
		                            }
		                            else
		                            {
		                                x = that.optionMapping[ that.columns[cell]][x];
		                            }
		                        }
                                if ( x )
                                {
                                   cells.push(x);
                                } 
                                else 
                                { 
                                   cells.push("");
                                }
                                //console.log("----> "+row[cell]);
                            }
                       
                            that.linkData.data.push(cells);
                       }
                       } catch (e) { that.error("Loading "+that.definition.entity,e) }
                    }).catch(e => {
                           that.error("Loading "+that.definition.entity,e)
                          });
        },
        closeInfoPU() { 
      	this.$vm2.close('modal-info');
      },
      closeDeleteConfPU() { 
      	this.$vm2.close('modal-deleteConf');
      },
      error(txt, err)
      {	
      	this.errorTypePU=txt;
      	this.errorPU=err;
      },
        deleteConfirmed() {
	      	this.closeDeleteConfPU();
	      	let row = this.linkData.data.find(r => r[0] == this.selectedRecord.id);
	      	
	      	HTTP.delete( fwAPI+"/delete/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.definition.entity+"/"+this.selectedRecord.id)
	                    .then( response => {
	                    //console.log( response );
	                    let index = this.mappingReverse[ this.selectedRecord.id ];
	                    this.linkData.orig.splice( this.selectedRowIndex - 1, 1);
	                    var rowNo = 0;
				        this.mapping = [];
				        this.mapping.push({row: 0, dataId: 0 });
				        for(var row in this.linkData.orig)
				        {
				          rowNo++;
				  		  this.mapping.push({row: rowNo, dataId: this.linkData.orig[row].id, rId: rowNo, visible: true});
				  		  this.row2IdMapping[ rowNo ] = this.linkData.orig[row].id;
				  		  this.mappingReverse[ this.linkData.orig[row].id] = rowNo;
				  		}
						this.linkData.data.splice( index, 1);
	                }).catch(e => {
	                    this.error("Can't delete "+this.MODULE+": "+this.selectedRecord.visualDisplay, e);
	                });
      },
      deleteRow( rowIndex) { 
        this.closeCTM();
        let rec = this.linkData.orig[ rowIndex - 1];
        this.selectedRecord = rec;
        this.selectedRowIndex = rowIndex;
        HTTP.get( fwAPI+"/canBeDeleted/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.definition.entity+"/"+rec.id+"/0")
                    .then( response => {
                    //console.log( response );
                    let linked = response.data;
                    if ( linked && linked.length > 0 )
                    {
                    	this.infoLinkedRecords = [];
                    	var count = 0;
                    	for ( var i in linked )
                    	{
                    		this.infoLinkedRecords.push({id: count++, linkedEntity: linked[i].linkedEntity, linkedRecord:linked[i].linkedRecord.visualDisplay}); 
                    		if ( count > 10 )
                    		{
                    			if ( linked.length > 10 )
                    			{
                    				this.infoLinkedRecords.push({id: count++, linkedEntity: "...", linkedRecord: "("+(linked.length-10)+" more)"}); 
                    			}
                    			break;
                    		}
                    	}
                    
                    	this.$vm2.open('modal-info');
                    }
                    else
                    {
				        this.$vm2.open('modal-deleteConf');
                    }
                }).catch(e => 
                {
                	this.error("Can't delete "+this.MODULE+": "+this.selectedRecord.visualDisplay, e);
                });
       
      },
      
  },
  computed: {
  	// watcher() { return this.id+this.visible; } 
  },
  watch: {
  	id: function()  { this.getData() },
  	counter: function()  { this.getData() },
  	time: function()  { this.getData() },
  	visible: function()  { this.loadedData = null; this.getData() }
  },
  updated() {
  	this.getData()
  }
 }
</script>
<style  scoped>
.inputWrapper {
	width: 100% !important;
	
	padding: 0;
	margin:0;
}
</style>