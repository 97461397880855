<template>
 
  <li class="context-menu-item">
    <slot/>
  </li>

</template>

<script>

export default {

}
</script>

<style lang="scss" scoped>
.context-menu-item {
    display: block;
    position: relative;
    padding: 2px 2px;
    background: rgb(215, 215, 215);
    border-radius: 0;
    color: #000;
    text-decoration: none;
    font-size: 13px;
    width: 100%;
    text-align: left;
    border-bottom: 1pt solid #aaa;
    
    cursor: pointer;
    padding: 0px;

}
</style>

