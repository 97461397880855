<template>

<div id='menu' class='menumain transition' >
     <div v-if='!loggedIn' class='menuOnOff transition' style='position: relative; top:0pt;border: 1pt solid grey;' >
	     <div class='menu  transition'  @mouseover="show('m0')">
		    <div class='mnButton'>
		        <mdicon :width="48" name="home" />
		        <div class='mnTitle'>Home</div>
		    </div>
		    <div id='m0' class='menuList invisible' @mouseout="close('m0')" style='float:top;'>
		        <div>
		          <div v-if="identValid()" class='menuItem' @click='gotoImmed("/login/"+ident(), "Login")'>LOGIN</div>
		          <div v-if="identValid()" class='menuItemSep'></div>
		          <div class='menuItem' @click='gotoImmed("/home/"+ident(), "Home")'>Home</div>
		          <div class='menuItemSep'></div>
		          <div class='menuItem' @click='gotoImmed("/about", "About")'>About</div>
		        </div>
		    </div>
	    </div>
    
     </div>
    <div v-else class='menuOnOff transition' style='position: relative; top:0pt;'>
	 
     <div class='menuOnOff transition' style='position: relative; top:0pt;' @click="toggle()">
	      <div v-if='menuOn' class='mnButtonOnOff' style='position: relative; top:0pt;'>
	      <mdicon :width="48" name="menu-open" />
	      <div class='mnTitle'>Menu</div>
	     </div>
	     <div v-else class='mnButtonOnOff' style='position: relative; top:0pt;'>
	      <mdicon :width="48" name="menu" />
	      <div class='mnTitle'>Menu</div>
	     </div>
	    </div>
	    <div class='menu invisibleMenu transition'  @mouseover="show('m1')">
	    <div class='mnButton'>
	        <mdicon :width="48" name="home" />
	        <div class='mnTitle'>Main</div>
	    </div>
	    <div id='m1' class='menuList invisible' @mouseout="close('m1')" style='float:top;'>
	        <div v-if='loggedIn'>
	
		        <div class='menuItem' @click='goto("/workbench/myDashBoard", "Workbench")'>Desktop</div>
		        
		        <div class='menuItem' @click='goto("/dataMiner", "Data manager")'>Data miner</div>
		        
		        <div class='menuItemSep'></div>
		        <div class='menuItem' @click='goto("/about", "About")'>About</div>
		        <div class='menuItem' @click="setLocked()">Lock screen</div>
		        <div class='menuItem' @click='goto("/sudoku", "Sodoku")'>Sudoku</div>
		        <div class='menuItemSep'></div>
		        <div class='menuItem' @click='refreshToken()'>Refresh Token</div>
		        <div class='menuItem' @click='goto("/setPasswd", "Password")'>Change password</div>
		        <div v-if='admin()' class='menuItem' @click='goto("/heartBeat", "Heartbeat")'>Heartbeat</div>
		        <div class='menuItemSep'></div>
		        <!-- 
		        <div v-if='admin()' class='menuItem' @click='goto("/resetCache", "Tenant Admin")'>Tenant Admin</div>
		         -->
		         <div v-for="item in mainMenu.Home" :key="item.entity">
			        <div v-if="item.section && !item.entity.startsWith('/')" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
			        <div v-else-if="item.section && item.entity.startsWith('/')" :class='item.class' @click='goto( item.entity, item.title)'>{{item.title}}</div>
			        <div v-else-if="! item.section" :class='item.class'></div>
		        </div>
		         <div class='menuItemSep'></div>
		        <div v-if="isDarkMode()" class='menuItem' @click='toggleDarkMode(false)'>Dark mode off</div>
		        <div v-else class='menuItem' @click='toggleDarkMode(true)'>Dark mode on</div>
		        <div class='menuItemSep'></div>
		        <div class='menuItem' @click='goto("/logout", "Logout")'>Logout {{user}}</div>
		        
	        </div>
	    </div>
	    </div>
	    
	    
	    <div class='menu invisibleMenu transition' @mouseover="show('m2')" >
	    <div v-show='loggedIn' class='mnButton'>
	        <mdicon :width="48" name="cogs" />
	        <div class='mnTitle'>Settings</div>
	    </div>
	    <div id='m2' class='menuList invisible'  @mouseout="close('m2')" style='float:top;'>
	        <div v-if='loggedIn'>
	             <!-- 
	        	<div v-if='admin()' class='menuItem' @click='goto("/showAccessPoint/"+accessPointId, "AccessPoints")'>Access point</div>
	        	<div v-if='admin()' class='menuItem' @click='goto("/showTenant", "Tenants")'>Tenant</div>
	            
	        	<div v-if='admin()' class='menuItemSep'></div>
	        	-->
	        	<div v-if='admin()' class='menuItem' @click='goto("/activeUsers", "Active Users")'>Logged in users</div>
	        
		        <div v-for="item in mainMenu.Settings" :key="item.entity">
			        <div v-if="item.section && !item.entity.startsWith('/')" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
			        <div v-else-if="item.section && item.entity.startsWith('/')" :class='item.class' @click='goto( item.entity, item.title)'>{{item.title}}</div>
			        <div v-else-if="! item.section" :class='item.class'></div>
		        </div>
	        </div>
	    </div>
	    </div>
	    
	    <div v-show='loggedIn && mainMenu.Booking && mainMenu.Booking.length' class='menu invisibleMenu transition'  @mouseover="show('m1b')">
	    <div class='mnButton'>
	        <mdicon :width="48" name="book-clock-outline" />
	        <div class='mnTitle'>Ad Sales</div>
	    </div>
	    <div id='m1b' class='menuList invisible'  @mouseout="close('m1b')" style='float:top;'>
	        <div v-if="loggedIn && mainMenu.Booking && mainMenu.Booking.length > 0">
			    	<div v-for="item in mainMenu.Booking" :key="item.entity">
			        	<div v-if="item.section" :class='item.class' @click='goto(item.entity, item.title)'>{{item.title}}</div>
			        	<div v-if="! item.section" :class='item.class'></div>
			        </div>
		    	</div>
	    	</div>
	    </div>
	    
	    
	    <div v-show='loggedIn && mainMenu.Masterdata && mainMenu.Masterdata.length' class='menu invisibleMenu transition' @mouseover="show('m4')" >
		    <div v-show='loggedIn' class='mnButton'>
		        <mdicon :width="48" name="notebook-edit-outline" />
		        <div class='mnTitle'>Setup</div>
		    </div>
		    <div id='m4' class='menuList invisible'  @mouseout="close('m4')" style=' float:top;'>
		        <div v-if="mainMenu.Masterdata">
			    	<div v-for="item in mainMenu.Masterdata" :key="item.entity">
			        	<div v-if="item.section" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
			        	<div v-if="! item.section" :class='item.class'></div>
			        </div>
		    	</div>
		    </div>
	    </div>
	
	    <div v-show='loggedIn && mainMenu.Reports && mainMenu.Reports.length' class='menu invisibleMenu transition' @mouseover="show('m5')" >
		    <div v-show='loggedIn' class='mnButton'>
		        <mdicon :width="48" name="microsoft-excel" />
		        <div class='mnTitle'>Reports</div>
		    </div>
		    <div id='m5' class='menuList invisible'  @mouseout="close('m5')" style=' float:top;'>
		    	<div v-if="mainMenu.Reports">
			    	<div v-for="(item,idx) in mainMenu.Reports" :key="'rpt_'+idx">
			        	<div v-if="item.section && !item.entity.startsWith('/')" :class='item.class'  @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
		       		 	<div v-else-if="item.section && item.entity.startsWith('/')" :class='item.class' @click='goto( item.entity, item.title)'>{{item.title}}</div>
		        		<div v-else-if="! item.section" :class='item.class'></div>
			        </div>
		    	</div>
		    </div>
	    </div>
	
	    <div v-show='loggedIn && mainMenu.Finance && mainMenu.Finance.length' class='menu invisibleMenu transition' @mouseover="show('m6')" >
		    <div v-show='loggedIn' class='mnButton'>
				<mdicon :width="48" name="finance" />
		        <div class='mnTitle'>Finance</div>
		    </div>
		    <div id='m6' class='menuList invisible'  @mouseout="close('m6')" style=' float:top;'>
		    	<div v-if="mainMenu.Finance">
			    	<div v-for="item in mainMenu.Finance" :key="item.entity">
			        	<div v-if="item.section && !item.entity.startsWith('/')" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
			            <div v-else-if="item.section && item.entity.startsWith('/')" :class='item.class' @click='goto( item.entity, item.title)'>{{item.title}}</div>
			            <div v-else-if="! item.section" :class='item.class'></div>
			        </div>
		    	</div>
		    </div>
	    </div>
	
	    <div v-show='loggedIn && mainMenu.Schedule && mainMenu.Schedule.length' class='menu invisibleMenu transition' @mouseover="show('m7')" >
	    <div class='mnButton'>
	        <mdicon :width="48" name="chart-timeline" />
	        <div class='mnTitle'>Schedule</div>
	    </div>
	    <div id='m7' class='menuList invisible'  @mouseout="close('m7')" style=' float:top;'>
	    	<div v-if="mainMenu.Schedule">
		    	<div v-for="item in mainMenu.Schedule" :key="item.entity">
		        	<div v-if="item.section && !item.entity.startsWith('/')" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
			        <div v-else-if="item.section && item.entity.startsWith('/')" :class='item.class' @click='goto( item.entity, item.title)'>{{item.title}}</div>
			        <div v-else-if="! item.section" :class='item.class'></div>
		        </div>
	    	</div>
	    </div>
	    </div>
	    
		<div v-show='loggedIn && mainMenu.Automation && mainMenu.Automation.length' class='menu invisibleMenu transition' @mouseover="show('m7b')" >
		    <div  class='mnButton'>
		        <mdicon :width="48" name="highway" />
		        <div class='mnTitle'>Automation</div>
		    </div>
		    <div id='m7b' class='menuList invisible'  @mouseout="close('m7b')" style=' float:top;'>
		    	<div v-if="mainMenu.Automation">
			        <div v-for="item in mainMenu.Automation" :key="item.entity">
				        <div v-if="item.section" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
				        <div v-if="! item.section" :class='item.class'></div>
			        </div>
		        </div>
		    </div>
	    </div>
	    
	    <div v-show='loggedIn && mainMenu.Controls && mainMenu.Controls.length' class='menu invisibleMenu transition' @mouseover="show('m3')" >
		    <div  class='mnButton'>
		        <mdicon :width="48" name="tools" />
		        <div class='mnTitle'>Controls</div>
		    </div>
		    <div id='m3' class='menuList invisible'  @mouseout="close('m3')" style=' float:top;'>
		    	<div v-if="mainMenu.Controls">
			        <div v-for="item in mainMenu.Controls" :key="item.entity">
				        <div v-if="item.section" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
				        <div v-if="! item.section" :class='item.class'></div>
			        </div>
		        </div>
		    </div>
	    </div>
	    
	    <div v-show='loggedIn && mainMenu.Admin && mainMenu.Admin.length' class='menu invisibleMenu transition' @mouseover="show('m3A')" >
		    <div  class='mnButton'>
		        <mdicon :width="48" name="speedometer" />
		        <div class='mnTitle'>Admin</div>
		    </div>
		    <div id='m3A' class='menuList invisible'  @mouseout="close('m3A')" style=' float:top;'>
		    	<div v-if="mainMenu.Admin">
			        <div v-for="item in mainMenu.Admin" :key="item.entity">
				        <div v-if="item.section" :class='item.class' @click='goto("/fw/"+item.entity, item.title)'>{{item.title}}</div>
				        <div v-if="! item.section" :class='item.class'></div>
			        </div>
		        </div>
		    </div>
	    </div>
	    
	
	    <div v-show='loggedIn' class='menuOnOff transition'  >
	    <div v-show='loggedIn' class='mnButton' @click="goto('/search', 'Search')">
	        <mdicon :width="48" name="magnify" />
	        <div class='mnTitle'>Search</div>
	    </div>
	    
	    </div>
	 </div>
    <div v-show='loggedIn' class='msgLine msgLineXL'>
      <div :class='messageClassName' style='display: inline-flex;'>{{getMessage()}}</div> 
    </div>
    
    <div v-show='loggedIn'  :style="getAppStyle()+'float:right;'">
        <span  @click='logout()' v-tooltip='"Logout"'>
        <div class='userInfo' id='showUserHere'>{{user}}</div>
        </span>
        <span v-tooltip='"Choose " +tr("SalesUnit")' :style="getAppStyle+'border-radius: 5pt;'">
        <div class='unitInfo' id='showUnitHere' :style="getAppStyle+'border-radius: 5pt;'" >
        <vSelect :style="getAppStyle+'border-radius: 5pt;'" 
          :options="unitOptions" v-model="selectedUnit" :clearable="clearable()" @input="doReload" placeholder=''>  
        </vSelect>
        </div>
        </span>
        <div id=showLoginHere class='menuInfo'></div>
    </div>
    <!-- LOGO -->
    <div v-if='loggedIn' :style="getAppStyle()+'float:right;margin-right:3pt;'">
		<img v-if="tenant && tenant.logo" :src="'data:image/png;base64,'+tenant.logo"/>
		<!--
		<img v-else style='width: 22%; height: auto; float: right; padding-right: 20pt;' src="/nextlogo.png"/>
		-->
	</div>
	
    
    <div v-show='!loggedIn'  style='float:right;margin-right: 20pt;'>
         <button v-if="identValid()" class="button is-dark" @click='$router.replace("/login/"+ident())'>
          Login
        </button>
        <button v-else class="button is-dark" disabled="true">
          Login
        </button>
    </div>
	<!--
	<div v-if='!loggedIn' :style="getAppStyle()+'float:right;margin-right:3pt;'">
			<img style='width: 22%; height: auto; float: right; padding-right: 20pt;' src="/nextlogo.png"/>
	</div>
	-->
	 <vue-modal-2 :darkMode="false" style='z-index: 99'
       :headerOptions="{title: 'Logout'}" 
       :footerOptions="{ btn1: 'Cancel', btn2: 'Logout', btn1OnClick:closeLogoutConfPU, btn2OnClick:logoutConfirmed}"
       name="modal-LogoutConf"  @on-close="closeLogoutConfPU">
      <div class='puInfo' style='padding-top: 1em; padding-bottom: 1em;'>
        Logout {{user}}
      </div>
   </vue-modal-2>

   
   <ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="getUser( contextData); $refs.menu.close()">
        	Add {{contextData.title}} to favorites
        </ContextMenuItem>
         <ContextMenuSep/>
        

       </template>
    </ContextMenu>
    
</div>
</template>
<script> 
import {HTTP, userAPI, refresh, reload, listen } from '@/variables';
import { tr } from '@/translate.js';
import { goTo } from '@/App.vue';
import { openView,getData } from '@/utils.js';
import { getUser, updateUser } from '@/user.js';
import { getAppStyle, isDarkMode, setDarkMode, initAppMode } from '@/AppStyle.js';
import { initGoBack, setGoBack, setMenu } from '@/breadCrumb.js';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import JQuery from "jquery";
import ClickOutside from 'vue-click-outside'
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
let $ = JQuery;
var timerTenant;
export default {
  props: {
    user: String,
    unitOptions: Array,
    selectedUnit: Object,
    tenantId: Number,
    accessPointId: Number,
    loggedIn: Boolean,
    ready: Number,
    menuList: Array,
    mainMenu: Object
  },
  data() {
      return {
         displayedTenantId: 0,
         menuOn: false,
         tenant: {},
         messageClassName: "msgInfo",
         darkMode: false,
         message: "",
         userObject: {},
         tr,
         getAppStyle, isDarkMode
      }
  },
  components : {
    vSelect, ContextMenu, ContextMenuItem, ContextMenuSep
  },
  directives:{
    ClickOutside,
  },
  methods: {
    ident() 
    {
    	if (!sessionStorage.ident || sessionStorage.ident==='undefined')
    	{
    		return "start";
    	}
    	return sessionStorage.ident; 
    },
    setLocked()
    {
		this.$emit("lock")
	},
    reload() {
    	this.$forceUpdate();
    },
    identValid() { return sessionStorage.ident; },
    clearable() { if ( sessionStorage.salesUnitId) { return sessionStorage.salesUnitId==0; } return false;},
    myLogin() { if ( this.login ) { return sessionStorage.login; } else { return "LOGIN" } },
    show(me) { this.closeAll(); $('#'+me).removeClass('invisible'); },
    closeAll() { $('.menuList').addClass('invisible');},
    toggle() 
    { 
    	$('.menuList').addClass('invisible');
    	this.menuOn = ! this.menuOn;
        if (!this.menuOn)
        {
    		$('.menu').addClass('invisibleMenu');
    		$('.msgLine').addClass('msgLineXL');
    		$('.msgLine').removeClass('msgLineM');
    	}
    	else
    	{
    		$('.menu').removeClass('invisibleMenu');
    		$('.msgLine').addClass('msgLineM');
    		$('.msgLine').removeClass('msgLineXL');
    	}
    },
    toggleOff() 
    { 
        this.menuOn = false;
    	$('.menuList').addClass('invisible');
    	$('.menu').addClass('invisibleMenu');
    	$('.msgLine').addClass('msgLineXL');
    	$('.msgLine').removeClass('msgLineM');
    },
    close(me) { $('#'+me).addClass('invisible'); },
    refreshToken() { this.closeAll(); refresh(1); },
    getMessage() { return this.message; },
    ctxMenu(event, item, menuNo) 
    { 
      event.preventDefault();
      item['menuNo'] = menuNo;
      this.$refs.menu.open(event, item );
    },
    toggleDarkMode( setOn)
    {
    	setDarkMode( setOn);
    	initAppMode();
    	let that=this;
    	getUser().then( p=>{
       	 that.userObject = p;
       	 that.userObject.favorites.darkMode = setOn;
		 if ( !setOn )
		 {
			that.userObject.favorites.darkModeBGColorSave = that.userObject.favorites.darkModeBGColor;
			that.userObject.favorites.darkModeBGColor = '#fff';
		 }
		 else if ( that.userObject.favorites.darkModeBGColorSave )
		 {
			that.userObject.favorites.darkModeBGColor = that.userObject.favorites.darkModeBGColorSave;
		 }
		 
       	 updateUser( that.userObject).then( x=>{
       		this.$forceUpdate();
        	this.$router.go();
       	  });
         });
    	
    },
    
    tickerDisplay( m, c) 
    {
        this.message = m; 
        if (c) { this.messageClassName = c} 
        else { this.messageClassName = "msgInfo"}
    },
    goto( newPath, title)
    {
      let target = listen( newPath, title); 
      //this.toggleOff();
      this.closeAll();
      if ( target )
      {
      	openView( this.$router, this.$toast, target)
      }
    },
	gotoImmed( newPath, title)
	{
		this.$router.replace(newPath);
	},
    getLogin() { try {return JSON.parse(sessionStorage.myLogin).login;} catch(e) { return ""} },
    logoutConfirmed() { this.closeLogoutConfPU(); this.$router.replace("/logout")},
    logout(){ this.$vm2.open('modal-LogoutConf'); },
    closeLogoutConfPU() { 
      	this.$vm2.close('modal-LogoutConf');
    },
    
    myAlert(text) 
    { 
        this.tickerDisplay(text, "msgError");
    },
    admin()
      {
           var data = JSON.parse(sessionStorage.myLogin);
         //console.log("ISADMIN> "+data.isAdmin);
         if (true === data.isAdmin)
          {
              return true;
          }
          return false;
      },
     doReload(unit){ 
    	
     	if (unit) 
     	{ 
     		sessionStorage.unitId=unit.id; 
     		sessionStorage.salesUnit=unit.label; 
     		this.$emit( "reload", sessionStorage.unitId, unit);
     	} else { 
     	    sessionStorage.unitId=sessionStorage.salesUnitId; 
     	    sessionStorage.salesUnit="--"; 
     		this.$emit( "reload", sessionStorage.salesUnitId, unit); }
    },
    getUser( module) { 

    		let that = this;
    		
    		//alert( JSON.stringify( module ));
    		HTTP.post( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
            .then( response => 
            { 
                let user = response.data;
                let recentlyOriginal = [];
                if ( !user.favorites )
 				{
 					recentlyOriginal = [];
 				}
 				else
 				{
	 				let favs = user.favorites.entities;
					sessionStorage.editorStyle = favs.editorStyle?favs.editorStyle:'classic';
	 				let tmp = [];
					for ( let i in favs)
					{
					    let fav = favs[i];
					   
					    if ( fav && Array.isArray(fav) )
					    {
					        for ( let ii in fav )
					        {
					            let f = fav[ii];
					            f.rowSpan=1;
								if ( ! tmp[f.no-1] )
								{
									tmp[f.no-1] = [];
								}
								tmp[f.no-1].push( f);
							}
						}
						else if ( fav)
						{
							if ( ! tmp[fav.no-1] )
							{
								tmp[fav.no-1] = [];
							}
							tmp[fav.no-1].push( fav );
						}
					}
					recentlyOriginal = tmp;
					
	 				if ( !recentlyOriginal )
	 				{
	 					recentlyOriginal = [];
	 				}
	 			}
	 			if ( module  )
				{
				    let colorArr=['','#ddd','#cec', '#cce', '#eec', '#ccc', '#cee', '#ddd'];
				    if ( !recentlyOriginal[module.menuNo-1] || ! recentlyOriginal[module.menuNo-1].find( p=>p.module === module.entity) )
				    {
				    	if ( !recentlyOriginal[module.menuNo-1] )
				    	{
				    		recentlyOriginal[module.menuNo-1] = [];
				    	}
				   	 	recentlyOriginal[module.menuNo-1].push( { no: module.menuNo, module: module.entity, title: module.title, color: colorArr[module.menuNo]});
				   	 	//alert( JSON.stringify(recentlyOriginal));
				   	 	user.favorites = { entities: recentlyOriginal};
				   	 	HTTP.put( userAPI+"/setUserFavorites/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, user)
			            .then( response => 
			            { 
			 				that.$emit( "reload", sessionStorage.unitId, "");
			 				
			            }).catch(e => {
			                   if ( e.response)
		                        {
		                        	that.$toast.error("saving user: " + e.response.data, 'Error', { position: "topRight" });
		                     	}
		                     	else
		                     	{
		                     		that.$toast.error("saving user: " + e, 'Error', { position: "topRight" });
		                     	}
			
			            });
				   	}
				 }
            }).catch(e => {
                   if ( e.response)
                    {
                    	that.$toast.error("loading user (menuBar): " + e.response.data, 'Error', { position: "topRight" });
                 	}
                 	else
                 	{
                 		that.$toast.error("loading use (menuBar)r: " + e, 'Error', { position: "topRight" });
                 	}

            });
       },
       getTenant()
       {
		   console.log("getTenant> " + sessionStorage.tenantId +"/"+ sessionStorage.userId+"::"+timerTenant );
		   
		   if ( sessionStorage.tenantId && sessionStorage.userId)
		   {
		   	let that = this;
			this.tenantId = sessionStorage.tenantId;
		   	getData("Tenant", that.tenantId).then( 
				p=> {
					that.tenant=p; 
					console.log(p.name);
				});	
	   	   }
		   try {clearInterval(timerTenant); timerTenant = 0;} catch (e) {console.log(e)}
	   },
	   onStart()
	   {
		   if (this.loggedIn) 
		   { 
			   this.$emit('tickerDisplay', this.tickerDisplay); 
			   console.log(1+"--"+this.tenantId);
			   if ( !timerTenant )
			   timerTenant = setInterval( this.getTenant, 400 );
		   }
	   }

  },
  created()
  {
	  
	  this.tenantId = sessionStorage.tenantId;
	  console.log(0)
	  timerTenant = setInterval( this.getTenant, 1000 );
      setMenu( this );
      
      this.darkMode = isDarkMode();
  },
  watch: {
	  tenantId: function() { },
      ready: function() { if (this.loggedIn) { this.onStart()} }
      //selectedUnit: function() { if (this.selectedUnit) { sessionStorage.unitId=this.selectedUnit.id; this.$emit( "reload", sessionStorage.unitId);}}
  }
 }
</script>
<style lang="scss" scoped>

.msgInfo {
    padding: 8pt; 
    position: absolute; 
    color:#999; 
    height: 100%; 
    width: 100%; 
    font-size: 10pt; 
    vertical-align: middle;
}
.msgMESSAGE {
    padding: 8pt; 
    position: absolute; 
    color:#999; 
    height: 100%; 
    width: 100%; 
    font-size: 10pt; 
    vertical-align: middle;
}
.msgINFO {
    padding: 8pt; 
    position: absolute; 
    color:#999; 
    height: 100%; 
    width: 100%; 
    font-size: 10pt; 
    vertical-align: middle;
}
.msgError {
    padding: 8pt; 
    position: absolute; 
    color:#c00; 
    height: 100%;
    font-weight: bold; 
    width: 100%; 
    font-size: 11pt; 
    vertical-align: middle;
}
.msgImportant {
    padding: 8pt; 
    position: absolute; 
    color:#44C; 
    height: 100%;
    font-weight: bold; 
    width: 100%; 
    font-size: 11pt; 
    vertical-align: middle;
}
.msgERROR {
    padding: 8pt; 
    position: absolute; 
    color:#c00; 
    height: 100%;
    font-weight: bold; 
    width: 100%; 
    font-size: 11pt; 
    vertical-align: middle;
}
.menumain { position: relative;  width: 100%; height: 50pt; margin-bottom: 2pt; padding: 1pt;}
.menu { display: inline-flex; float: top; }
.menuOnOff { display: inline-flex; float: top; }
.mnButton {
  
   padding-top: 4pt;
   width: 52pt;
   height: 50pt; 
   text-align: center;
   border: 1pt solid #dde;
}
.mnButtonOnOff {
   
   padding-top: 4pt;
   width: 50pt;
   height: 50pt; 
   text-align: center;
   border: 1pt solid #dde;
}
.mnButtonOnOff:hover {
   transition: background-color 0.2s;
   background-color: #ddf; 
   color: #444;
}
.mnButton:hover {
   transition: background-color 0.2s;
   background-color: #ddf; 
   color: #444;
}
.mnTitle {
    padding-top: 6pt;
    color: #888;
    font-size: 11px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.menuList { 
    position: absolute; 
    float: top; 
    top: 50pt;
    background-color: #444; 
    color: #dde;
    border: 1pt solid #ccc; 
    z-index: 999;
    opacity: 1;
    border-radius: 4px;
    padding-top: 1pt;
    padding-bottom: 1pt;
    box-shadow: 5px 5px 8px 0 #444;
	transition: all .8s;
   -webkit-transition: all .8s;
}
.invisible { 
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
   -webkit-transition: all .2s;
}
.invisibleMenu { 
    visibility: hidden;
    opacity: 0;
    width: 0pt !important;
    -webkit-transform: scale(0.0);
        -ms-transform: scale(0.0);
        transform: scale(0.0);
    transition: all .5s;
   -webkit-transition: all .5s;

}
.transition {
 	transition: all .5s;
   -webkit-transition: all .5s;
 }
.menuList:hover { 
    display: inline-flex; 
}
.menuItemSep {     
    float:left; 
    clear: left;
    display: block;
    position: relative;
    padding: 3px 3px;
    background: rgb(215, 215, 215);
    border-radius: 0;
    text-decoration: none;
    font-size: 10px;
    width: 100%;
    text-align: left;
    border-bottom: 0.5pt solid #aaa;
    cursor: pointer;
    padding: 0px;
 }
    

.menuInfo {  
   margin-top: 1pt;
   font-size: 10px;   
   opacity: 0.7;
 }
.unitInfo {  
  width: 180pt;
  border-radius: 3pt;
  font-size: 10px;
  background-color: #f4f4ff; 
 }
.userInfo {
  width: 180pt;
  font-size: 12px;
  padding-left: 4pt;
  border-radius: 3pt;
  cursor: pointer;
  border: 0.5pt solid #ccc;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
 }
 .userInfo:hover {
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  border: 1pt outset grey;
  background-color: #e8f0e8;
  color: #000;
 }
 .userInfoRed {
  background-color: #ffcccc; 
  color: #000;
  opacity: 0.8;
 }
.msgLine {
   display: inline-flex; 
   position: absolute; 
   font-size: 12pt; 
   border: none; 
   text-align: center; 
   height: 100%; 
}
.msgLineXL {
   display: inline-flex; 
   position: absolute; 
   font-size: 12pt; 
   border: none; 
   text-align: center; 
   height: 100%; 
   width: calc(90vH);
}
.msgLineM {
   display: inline-flex; 
   position: absolute; 
   font-size: 12pt; 
   border: none; 
   text-align: center; 
   height: 100%; 
   width: 250pt;
}
.menuItem {  
   float:left; 
   clear: left;
   width: 200pt;
   padding-top: 2pt;
   padding-left: 6pt;
   
   z-index: 9999 !important;
   font-size: 10pt;
   cursor: pointer;
   //display: inline-flex; 
   opacity: 1;
 }
 .menuItem:hover { 
   background-color: #dde;
   float:left; 
   clear: left;
   z-index: 9999;
   cursor: pointer;
   color: #000;
   
   opacity: 1;
 }
 
 .fas{
    font-size: 3em !important; /*size whatever you like*/
}

</style>