<template>
	<input :style="getAppStyle()+(isDarkMode()?'color-scheme: dark;':'')" 
	    :class="noBorder?'dateInputWrapperNoBorder':'dateInputWrapper'"
		@mouseover="$event.target.focus();"
	    @focus="$event.target.select()"
	    @blur="blurry()"
		@changed="selected(value)"
		@keypress.enter="enterPressed()"
	    @keyup.esc="escapePressed()"
	    @keyup.tab="$emit('tab')"
		:disabled="disabled"
	    type="date" v-model="text"/>
	
</template>

<script>
//import Datepicker from 'vuejs-datepicker';
import { getAppStyle, setDarkMode, initAppMode, isDarkMode, getBGStyle3 } from '@/AppStyle.js';
var momentTZ = require('moment');
export default {
  name: "DatePick",
  components : {
    
    //DatePicker
  },
   props: {
    auto: { type: Boolean, default: true },
	disabled: { type: Boolean, default: false },
	noBorder: { type: Boolean, default: false },
    minDate: Object,
    maxDate: Object,
	
    value: {
      type: [Date, String]
    }
  },
  data () {
    return {
    	 dateFMT : {
			  title: 'MMMM YYYY',
			  weekdays: 'W',
			  navMonths: 'MMM',
			  input: ['L', 'YYYY-MM-DD', 'DD.MM.YYYY'],
			  dayPopover: 'WWW, MMM D, YYYY',
			  data: ['L', 'YYYY-MM-DD', 'DD.MM.YYYY'],
			  
			},
			getAppStyle,getBGStyle3,isDarkMode
       }
  },
  computed: {
	 text: {
	       get() {
			
			if ( this.value instanceof Date && !isNaN(this.value))
			{
				let x = this.value.toISOString().split('T')[0]
				return x;
			}
			
			return this.value;
	       },
	       
	       set(v) {
			
	         this.$emit("input", v);
	         
	        }
		}
	},
    methods: {
        escapePressed() { this.$emit("escape"); },
        enterPressed() { this.$emit("enter"); },
		blurry() { this.$emit("blur"); },
        open() { this.$refs.inp.focus(); this.$refs.inp.select();},
		customFormatter(date) {
		return momentTZ(date).format(sessionStorage.dateFormat);
		},
		selected(d)
		{
			alert( d)
			this.$emit("change", d);
		},

     },
    watch:
    {
        //dateFormat: function()  { this.displayTime() },
        //internalValue: function()  { this.displayTime() }
    } 
 }
</script>
<style scoped lang="scss">
.dateInputWrapper { 
    border: 0.1px solid #ddd;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
    
	white-space: normal;
	overflow: visible !important;
}
.dateInputWrapperNoBorder { 
    padding: 2pt;
    width: 100%;
	border: none;
	white-space: normal;
	overflow: visible !important;
}



</style>