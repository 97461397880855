<template>

<span v-tooltip='tooltip'  @click='doCopy' v-clipboard:copy="internalValue">
<svg @click='doCopy' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-plus tooltipClass" viewBox="0 0 16 16">
  <path v-show="! isVisible()" d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
  <path v-show="! isVisible()" d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
  <path v-show="isVisible()" d="M13.854 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L6.5 10.293l6.646-6.647a.5.5 0 01.708 0z"></path>

</svg>
</span>

</template>
<script>

var copied=false;
var myTimer = null;
export default {
  props: {
    value: { type: String },
    title: { type: String, default: "copy to clipboard" },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      copied
    }
  },
  components : {
    
  },
  computed: 
  {
    internalValue: {
      get() {
        return this.disabled? "****" : this.value
      },
      set(v) {
        this.$emit("input", v)
      }
    },
    tooltip: {
      get() {
        return copied? "copied":this.title
      }
    }
  },
  methods: 
  {
        isVisible()
        {
            return this.copied;
        },
        hide()
        {
            this.copied = false;
            clearInterval(myTimer);
        },
        show()
        {
            this.copied = true;
        },
        doCopy() 
        {
          this.show();
          myTimer = setInterval(this.hide, 1500);
        }
  },
  watch: 
  {
        copied: function() {this.isVisible()}
  }
}
</script>
<style lang="scss" scoped>


.tooltipClass:hover {
  background: #CCC;
  color: #000;
}

</style>