<template>
 <div >

     <XModal name="xch1" :resizable="true"  width='66%' height="60%" :draggable="true" @opened="startChart" @before-close="onClose">
       <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
         <div class='appHead'>
             <div style='display: inline-flex; color: #444; font-weight: bold; padding-left: 5pt; padding-top: 3pt; font-size: 10pt; float: left;'>
             <span>Weekly delivery</span> 
             </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div class='mainContent dontWrap'  >
                <div id='aChart' style='color: #000;' />
            </div> <!-- float: left -->
            </slot>
			</div> <!-- mainContent -->
	      
	    </div> <!-- modal-body -->
	    
	
	  </XModal> <!-- modal-container -->  	  
	
	<ContextMenu ref="menu" offsetY=120>
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem >
          What ever
        </ContextMenuItem>
        <ContextMenuItem >
          What else
        </ContextMenuItem>
        <ContextMenuSep/>
        
       </template>
    </ContextMenu>
	<GFWEOpenEditor ref='editor' @update='reload'></GFWEOpenEditor>
 </div>
</template>

<script>
import {HTTP, fwAPI, placementServiceAPI, bngAPI, setReload, showError, myLocale, formatNumber } from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2 } from '@/basicTimeFN.js';
import { getAppStyle, initAppMode, isDarkMode } from '@/AppStyle.js';
import { format, formatNZ } from '@/Numbers.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import { tr } from '@/translate.js';
import {getData} from '@/utils.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select';
import ApexCharts from 'apexcharts'
import 'vue-select/dist/vue-select.css';
export default {
  name: 'GFW DEAL View',
  components : {
   ContextMenu,ContextMenuItem,ContextMenuSep, 
  },
  props: {

  },
  data () {
    return {
    	data: {},
		placement: null,
    	getAppStyle, format, formatNZ, tr,
    	ready: false,
		reloaded: true,
		chartOptions: {},
		chart: null
      }
  },
  methods: {
     getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
    
     myLocale() { return myLocale();},
      getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100% - 200px); padding: 1ex;"
    },
	
	onClose()
	{
		this.distroyChart(); 
	},
	startChart()
	{
		let data = this.data;
		let that = this;
				 let labels = data.limits.map(p=>'W'+p.week);
				let total = (100+that.placement.incentiveDiscount)*.01;
				
				let series = [{"name":"Target paid","data": data.limits.map(p=>p.budget)},
						               {"name":"Booked paid","data": data.limits.map(p=>p.actualBudgetPaid)},
							  {"name":"Target total","data": data.limits.map(p=>p.budget*total)},
						               {"name":"Booked total","data": data.limits.map(p=>p.actualBudget)}];
				// alert( JSON.stringify(series))
				 
				let colors = [ '#67b73a', '#673ab7', '#21f396', '#2196f3' ];
				let col = sessionStorage.fgColor;
				that.$forceUpdate();         	
				         	
				
				that.chartOptions = {
					  chart: { 
					    type: 'area',
					    height: 400,
						width: "90%",
					    toolbar: {
				         show: false
					    },
					    animations: {
					        enabled: false
					    },
					    events: {
					    	 
					    }						
					  },

					  title: {
						//  text: "Sales requests in TUSD"
					  },
				  	  dataLabels: {
					  enabled: false,
					    style: {
					      colors: [col]
					    }
					
					  },
					  legend: {  labels: { colors: [col] }},
					  series: series,
					  markers: {
						  size: [4],
						  onClick: function(e) { alert(e)}
						},
					  stroke: {
						  width: 2
						},
					  xaxis: { 
						  categories: labels, 
						  labels: { style: {colors: [col,col,col,col,col,col,col,col,col]}}
					  },
					  yaxis: {
						forceNiceScale: true,
				        decimalsInFloat: 2,
				        labels: { style: {colors: [col]}}
					  },
					  colors: colors
					}
					that.distroyChart();
					//alert(document.querySelector("#aChart"))
		     	   that.chart = new ApexCharts(document.querySelector("#aChart"), that.chartOptions);
			
			       that.chart.render();
			       that.$forceUpdate();

	},
    open(data, placementId) { 
         this.data = data;
		 let that = this;
		 getData( 'Placement', placementId ).then( p => { that.placement = p; that.$modal.show('xch1');});
		 this.ready = true;
    
     },
	 distroyChart()
	 	    {
	 	    	if ( this.chart)
	 			{
	 	    		try
	 	    		{
	 					this.chart.destroy();
	 					this.chart = null;
	 				}
	 	    		catch(e)
	 	    		{
	 	    		//
	 	    		}
	 			}
	 	    },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
     decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
  
     identity(x) { return x },
    
     close() { this.$modal.hide('xch1') },
     
  },
  watch: {

	
  },
  created() {
	this.$modal.show('xch1');
  }
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }

.right {
	align-items: right;
	text-align: right;
	justify-content: right;
	padding-left: 5pt;
}

.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important; 
  border-bottom: 1pt solid #ccc;
  
}
.header { 
  font-wight: bold;
  font-size: 11pt; 
  padding: 2pt 2pt 2pt 2pt;
}
.cell
{
	font-size: 10pt; 
}
.title
{
	display: table-cell; 
	font-size: 9pt; 
	height: 18pt;
	width: 80pt !important;
	padding-top: 2pt;
	padding-bottom: 2pt;
	font-weight: bold;
	text-align: center;
	vertical-align: middle;
	border-right: 1px solid #888;
	border-bottom: 1px solid #888;
	background-color: #3b60c4;
	color: #fff;
}
.indent {
	padding-left: 5pt;
	font-style: italic;
	font-weight: normal;
}
.cumulative {
	font-style: italic;
	font-weight: normal;
}
.row
{
	display: table-row;
}
.row:hover
{
	background-color: #777;
	color: #fff;
}
.gap5 
{
	border-top: 2px solid #888;
}
.head
{
	font-weight: bold;
	background-color: #3b60c4;
	color: #fff;
}
.centered {
  justify-content: center;
}
.right {
  justify-content: right;
}
.center {
  text-align: center;
}
.left {
  justify-content: left;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 28pt; 
  font-weight: bold; 
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  x-overflow: hidden;
}

.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}

.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 

.modal-wrapper {
  //display: table-cell;
  
}

.modal-container {
    
  padding-bottom: 20pt;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  //transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
  padding: 5pt;
  height: 100%;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}


.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.colored {
  //background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>
