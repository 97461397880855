var goBackTo = new Array();
var mainPath = '/fw/myDashBoard';
var mainName = "Workbench";
var menuBar = null;
export function setMenu(mn)
{
	menuBar = mn;
}
export function reloadMenubar()
{
	if ( menuBar )
	{
		menuBar.reload();
	}
}
export function setGoBack( goBack, mName, alias )
{
	goBackTo.push({name: mName, path: goBack, alias: alias});
}
export function initGoBack( goBack, mName )
{
	goBackTo = new Array();
	goBackTo.push({name: mName, path: goBack, alias: mName});
	if ( menuBar )
	{
		menuBar.toggleOff();
	}	
}

export function backTo()
{
	return goBackTo;
}
export function goBack( )
{
	if ( goBackTo && goBackTo.length > 0)
	{
		return goBackTo.pop();
	}
	else 
	{ 
		return {name: mainName, path: mainPath};
	}
}
