<template>
  <div class="main white" @contextmenu.prevent="openCM($event,{})">
       <div class='white headLine'>
	       <div class='white' style=' width: 90%; float: left;'>
	       	<b :style="'font-size: '+(fontSize-1)+'pt; padding-left: 10pt;'">Next tasks for {{user.login}}:</b>
	       </div> 
	       <div style='float: right;' @click='$emit("close")'>
	       		<WindowCloseBN/>
		   </div>
	  </div>
	  <br/>
	  <div class='white body'>
       <div class='white' style='width: 100%;'>
      	 		
		        <div v-if="tasks && tasks.length" class='white' style='padding-left: 10pt; width: 100%; height: 100pt; overflow-y: auto;'>
			        <div v-for="(task,idx) in tasks"  :key="'task_'+idx" @contextmenu.prevent="openCM($event, task)">
			        	<GFWETaskItem :shadowColor=shadowColor :fontSize="Math.min(fontSize+1,12)" :shadow1=shadow1 :shadow2=shadow2 :task='task' @openTask="$refs.editor.open('Task', task.id)" />
			        </div>
		        </div>
		        <div v-else class='white' style='padding-left: 10pt; width: 100%; height: 100pt; overflow-y: auto;'>
		        	you are lucky... no tasks
		        </div>
       </div>
	 </div>
    <ContextMenu ref="menu" >
      <template v-if="contextData"  slot-scope="{ contextData }">
       
        <ContextMenuItem v-if='contextData && contextData.id'  @clicked="closeCMN(); $refs.editor.open('Task', contextData.id)">
          Open {{contextData.name}}
        </ContextMenuItem>
        <ContextMenuItem v-if='contextData && contextData.id'  @clicked="closeCMN(); done( contextData)">
          Mark as done
        </ContextMenuItem>
        <ContextMenuSep v-if='contextData && contextData.id'/>
        <ContextMenuItem  @clicked="closeCMN(); newTask()">
          Create new task
        </ContextMenuItem>
	
      </template>
    </ContextMenu>
    <GFWEOpenEditor ref='editor' ></GFWEOpenEditor>
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, userAPI, myTimer, receiveToken, myName, showError} from '@/variables.js';
import store from "@/store";
import GFWETaskItem from '@/components/GFWETaskItem';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
export default {
  name: 'GFW_LOGINS',
  components : {
	  GFWETaskItem, ContextMenu, ContextMenuItem, ContextMenuSep, WindowCloseBN
  },
  props: {
   	user: Object,
   	fontSize: {type: Number, defaultValue: 8}
   },
  data: function () {
    return {
      tasks: [],
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  methods: {
	   openCM(event, menu){
	        event.stopPropagation();
	        this.$refs.menu.open(event, menu )
	   },
	   closeCMN() { try{ this.$refs.menu.close(); } catch(e) { /* */ }},
	   done( task)
	   {
		   let that = this;
		   task.done = true;
		   HTTP.put( fwAPI+"/updateData/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/Task/"+task.id, task)
           .then( response => 
           {
        	   that.getTasks(that.user.id); 
           }).catch(e => {
               showError(that.$toast, "mark task as done", e);
         });
	   },
	   newTask( )
	   {
		   this.$refs.editor.open('Task', 0)
	   },
       getTasks(userId) {
       	    let that = this;
       	    //
			// get tasks
			// 
			HTTP.post( userAPI+"/getTasks/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+userId)
            .then( response => 
            {
            	 that.tasks = response.data;
            	
		    }).catch(e => {
                  showError(that.$toast, "get tasks", e);

            });
       }
  },
  created()
  {
	this.getTasks(this.user.id);
	
  },
  watch: {
        user: function() {
            this.getTasks(this.user.id);
		 }
    }
}
</script>

<style scoped>
.main {
	display: block;
	width: 100% !important;
}
.body {
	display: block;
	float: top;	
}
.shadow {
    background-color: white !important;
	width: 100%;
    box-shadow: 5px 5px 8px 0 #444;
}
.headLine {
	float: top;	
	width: 100%;
	white-space: nowrap;
}
.white {
   //background-color: white !important;
}

.iButton:hover {color: #e8f0e8 !important;}
.item { cursor: pointer; display: block; white-space: nowrap; }
.item:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>


