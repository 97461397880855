<template>
<div class='inputWrapper' >
   
   <div class='input80' z-index="999" :style="getAppStyle()" >	
        <vSelect z-index="999" :options="selectData" v-model="selectedArray" :style="getAppStyle()+ getStyle()+'font-size: 8pt; height: 24pt; border-radius: 4pt;'"
        			@input="setValue" 
        			:selectable=isSelectable
        			:disabled="isDisabled()"
        			:appendToBody="true"
        			:multiple="true">  
        			 <template #option="{ id, label }">
      					<span z-index="999">{{label}}</span>
    				 </template>
        </vSelect>
    </div>	
    
    <div class='input20' >	
        <div v-if='isDisabled()'>
		<button :disabled='isDisabled()' class='button btn' @click="openDetail">
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
			  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
			</svg>
		</button>
		</div>
		<div v-if='!isDisabled()'>
    	<span v-tooltip='"Open "+ linkData.entity'>
		<button :disabled='isDisabled()' :style='getAppStyle()' class='button btn' @click="openDetail">
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
			  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
			</svg>
		</button>
		</span>
		</div>
	 </div>	
</div>
</template>

<script>
// import VSelectize from '@isneezy/vue-selectize'
import vSelect from 'vue-select'
//import 'selectize/dist/css/selectize.default.css'
import 'vue-select/dist/vue-select.css';
import {HTTP, fwAPI, accessPointAPI, APIURL} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
getAppStyle
export default {
  name: "GSelectMultiple",
  props: {
    visible: Boolean,
    selectedTab: Number,
    tabNo: Number,
    useCache: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    data: Object,
    id: Number,
    time: Number,
    parentMeta: Object,
    parentId: { type: Number, default: 0 },
    mark: { type: Boolean, default: false},
    entityMap: Object,
    metadata: Object,
    definition: Object,
    value: Array,
    x: Number, y: Number
  },
  components : { vSelect },
  data() {
  	return {
         linkData: {},
         linkedMetadata: {},
  	     selectedArray: {},
         selectData:  [],
         selectedIndex: 1,
         showEditor: false,
        valueARR: {},
        header: [],
        text: "",
        columns: [],
        limit: 10,
        loadedData: "",
        inInit: false,
        getAppStyle,
        mapping: [], // row mapping according to visual table
        mappingReverse: [], // id -> rowNo
        row2IdMapping: []  // rowNo -> id
        
  	}
  },
  methods: {
    searchList(text) { 
    },
    isDisabled() { return this.disabled }, // || this.definition.type=='enum[]'  },
    maybeCreate() {},
    isSelectable(option) { return option.visible; },
    getStyle() { 
      	if ( this.mark )
      	{
      		return "background-color: #fdd;";
      	}
      	return "";
      },
    openDetail() {
        var values = [];
        for (var opt in this.selectedArray)
        {
           values.push( this.selectedArray[opt].id);
        }
        if (! this.selectedArray )
        {
            //alert("Array is empty");
        }
    	this.$emit("open", this.selectedArray[0].id, this.linkData.name, values)
    },
    editor() 
    {
        this.showEditor = true;
    },
    closeEditor()
      {
         this.showEditor = false;
         //this.getData();
      },
    update() {},
    doSearch(text) {
    },
    setValue(selArr) { 
          var valueTMP = [];
          this.selectedArray=[];
          for (var opt in selArr)
          {
              valueTMP.push( selArr[opt].id);
              this.selectedArray.push( this.selectData.find( p=> p.id === selArr[opt].id));
          }
          if ( this.definition.type.startsWith("String"))
          {
            // emit stringified array -> as it has to be stored in a string-field
          	this.$emit("input",JSON.stringify(valueTMP));
          	return;
          }
          this.$emit("input", valueTMP ); 
    },
    getValue()
        {
            if ( this.value )
            {  
	        	if ( Array.isArray( this.value ) )
	      		{
	      			//this.selectedArray = this.value;
	      			this.valueARR = this.value; 
	      			return this.valueARR;
	      		}
	      		//this.selectedArray = JSON.parse(this.value);
	      		this.valueARR = JSON.parse(this.value);
	      		return this.valueARR;
	      	}
	      	this.selectedArray = [];
	      	return this.valueARR;
        },
        
    sort(selectData)
    {
    	 selectData.sort(function(a,b) {
		     	let v1 = a.visible;
	            let v2 = b.visible;
	            if ( v1 > v2 )
	            {
	                return -1;
	            }
	            if ( v1 < v2 )
	            {
	                return 1;
	            }
	            if ( a > b )
	            {
	                return 1;
	            }
	            if ( a < b )
	            {
	                return -1;
	            }
		     	return 0;
		     	});
		  return selectData;
    },    
    getData()
  	{
  	    //alert("getData("+this.definition.column+")")
  	    this.getValue();
        if ( this.definition.enum )
        { 
        	//console.log(JSON.stringify(this.entityMap[this.definition.column].data)+ this.value);
        	this.selectData = JSON.parse( JSON.stringify(this.entityMap[this.definition.column].data));
        	//console.log(JSON.stringify( this.selectData ));
        	//console.log(JSON.stringify( "-->"+this.value ));
        	if ( this.valueARR != null && this.valueARR.length > 0 )
            {
            	//for ( var optId in this.valueARR)
            	//{
            	//	let option = this.selectData.find( p => p.id == optId);
            	//	console.log( optId + "--> " + option);
            	//}
            	
            	// ------------------------------------------------
            	// value == [ array of ids]
            	// selectData = [ {id, label}, ...]
            	// filter all selectData that where the id can be found in the value-array
            	// ------------------------------------------------
                this.selectedArray = this.selectData.filter( p => this.valueARR.find( x => x == p.id) == p.id);
            }
            else
            {
                this.selectedArray = [];
            }
            
            return;
        }
        //console.log( JSON.stringify(this.value))
        //console.log( JSON.stringify(this.selectedArray))
        //console.log( "GSELECT getData()");
  		var select = this.definition.visual;
  		var scanner = new RegExp("SELECT[(][ ]*([a-zA-Z_0-9]*)[ ]*[)][ ]*");
        var myArray = scanner.exec(select);
        // read "link" from SELECT( link )  
        var link = myArray[1];
        // find the definition   
        
        this.linkData = this.metadata.links.find( p => p.name == link);
        
        let myValue = this.valueARR;
        let useCache = this.useCache;
        let id = this.id == null?0:this.id;
    	let entity = this.metadata.name;
    	
        if ( !this.linkData || ! this.linkData.entity )
        {
        	// no link -> get entity from the given column
        	this.linkData = this.metadata.columns.find( p => p.column === link);
        	try
        	{
        		// linkdata is now pointing to column
        		let referencedId = parseInt( this.data[this.linkData.column]);
        		myValue = parseInt( this.value );
	        	useCache = false;
	        	id = myValue;
	        	id = this.id == null?0:this.id;
	        	// find entity 
	        	let mySelect = this.entityMap[this.linkData.select].data.find( m => m.id == referencedId);
	        	this.linkData = {entity: mySelect.label, name: mySelect.label };
	        	// console.log( select+ "--> " + JSON.stringify(this.linkData));
	        	//entity = mySelect.label;
        	} catch(e)
        	{
        		console.log( "cant get entity");
        		//this.loadedData = key;
        		this.selectData=0;
        		let col = this.definition.column;
        		console.log( col);
        		this.selectedValue = this.data[ col];
        		console.log( this.selectedValue);
        		return;
        		/*
                this.selectData = [{id: 0, label: "false", visible: true},{ id: 1, label: "true", visible: true}];
                //console.log( "GSELECT["+link+"]> "+this.selectData.length);
                this.selectedValue = this.selectData .find( p => p.id == myValue);
                if ( ! this.selectedValue )
                {
                    this.selectedValue  = [];
                }
                this.setSelected();
                */
                
        	}
        }
	
        let key = this.metadata.name+"."+link+"."+this.disabled+"."+JSON.stringify(this.value);
        
  	    if ( key == this.loadedData && this.selectData && this.selectData.length > 0  )
  	    {
  	    	if ( this.data.id != 0 )
  	    	{
  	    		return;
        	}
        }    
		let useCacheHere = useCache && ! this.linkData.filter && this.selectData.length && this.entityMap[link].data;
		
        if ( useCacheHere && this.entityMap && this.entityMap[link] )
        {
            this.loadedData = key;
            this.selectData = JSON.parse( JSON.stringify(this.entityMap[link].data));
            //alert(link+"-->"+JSON.stringify(this.entityMap[link].data));
            //alert('myval> '+JSON.stringify(myValue)+JSON.stringify(this.value))
            //console.log( link + this.selectData.length + " found")
            this.inInit = true;
            if ( this.valueARR )
            {
                this.selectedArray = this.selectData.filter( p => myValue.includes(p.id));
                //console.log("<1> " + JSON.stringify(this.selectedArray));
            }
            else{
                this.selectedArray = [];
            }
            //alert(JSON.stringify(myValue)+"-->"+JSON.stringify(this.selectedArray));
            //console.log( JSON.stringify(this.selectedValue));
            this.sort(this.selectData)
            this.inInit = false;
            return;
        }

  	    // dont get data, if folder is not visible
  	    if ( ! this.data || ! this.visible)
  	        return;
        //let id = this.id == null?0:this.id;    
        //alert(this.linkData.name);
        //console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/"+this.definition.column+"/"+id+"/"+this.linkData.name+"/options/true/0/" + JSON.stringify(this.data));
  	    let that = this;
  	    HTTP.post( fwAPI+"/getEntityAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+this.metadata.name+"/"+this.definition.column+"/"+id+"/"+this.linkData.name+"/true/0", JSON.stringify(this.data)) 
            .then( response => 
            { 
                  try{         
                        that.inInit = true;
                        that.loadedData = key;
                        that.selectData = response.data.data;
                        that.sort(this.selectData);
                        //alert( JSON.stringify(response.data.data));
                        if ( that.valueARR && Array.isArray( that.valueARR))
                        {
                            that.selectedArray = that.selectData.filter( p => that.valueARR.includes(p.id));
                            //console.log("<2> " + JSON.stringify(this.selectedArray));
                        }
                        else
                        {
                             this.selectedArray = [];
                        }
                        that.inInit = false;
                        //console.log(JSON.stringify(that.selectData));
                       } catch (e) { console.log( e ) }
                    }).catch(e => {
                           that.$toast.error("loading data: " + e.response.data, 'Error', { position: "topRight" });
                          });
        }
        
  },
  computed: {
	
  },
  created() {
  	this.getData();
  },
  watch: {
      id: function()  { 
          //alert('id/'+this.definition.column);
          this.loadedData = "";
          this.getData()
       },
      value: function() 
      	{
      		this.getValue()
      	},
      time: function()  { 
        //alert('time/'+this.definition.column); this.loadedData = ""; this.getData() 
      },
      metadata: function()  { 
        //alert('metadata/'+this.definition.column); 
      	this.getData() },
      visible: function()  { if ( this.visible) { this.getData()} }

  },
  updated() {
    //console.log( "GSELECT:visible> " + JSON.stringify(this.visible));
    this.loadedData = "";
  }
 }
</script>
<style>
.vs__selected {
    display: flex;
    align-items: center;
    //background-color: #eeeeff !important;
    border: 1px solid rgba(60,60,60,.26);
    border-radius: 4px;
    color: #444;
    line-height: 1.4;
    margin: 4px 2px 0;
    padding: 0 .25em;
    z-index: 0;
}
</style>
<style scoped>

.inputWrapper {
	width: 100% !important;
	display: inline-block;
	padding: 0;
	margin:0;
	border: 0.5px solid #ccc;
    border-radius: 6px;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
.btn {
	height: 24pt !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
label, span{
        display: block;
    }
</style>