<template>
<div class='inputWrapper' :style="getAppStyle()+''">
	<div style="display: inline-flex; width: 42%;">
    <GSelectSimple :border="false" :nulls="false" :fontSize="10" :options="getHeaderAsOptions()" v-model="col">
		
	</GSelectSimple>
	</div>
	<div style="display: inline-flex; width: 55%;">
	<div style='display: inline-flex; padding-top: 5pt;padding-left: 3pt; color: #aaa !important;'><mdicon size="14" name="magnify" spin="true"/></div>
    <input ref="entersearch" v-model="searchterm" :title="'search for '+topic+' (full data search, not case sensitive)'"
			                @keyup.enter="$emit('input', getSearch() ); $emit('search'); $emit('enter', getSearch() );" 
			                @keyup="$emit('change', getSearch())" 
    						@focus="$event.target.select()"
							autocomplete="one-time-code"
    						:placeholder="'search for '+topic+'...'"
    						:style="getAppStyle()+'font-size: 9pt; margin-left: 2pt;margin-top: 4px; border: none; width: 80%; height: 22pt; margin-right: 10pt;'"/> 
	</div>
</div>
</template>
<script>
import { getAppStyle, getAppStyleImp, setDarkMode, initAppMode, getBGStyle2 } from '@/AppStyle.js';
import clipboard from '@/components/Clipboard';
import toggleViewIcon from '@/components/ToggleViewIcon';
import GSelectSimple from '@/components/GSelectSimple';
export default {
  props: {
	  searchTermStruct: Object,
	  topic: String,
	  header: Array
  },
  components : {
	    GSelectSimple
	    //, toggleViewIcon
	  },
  data() {
      return {
    	  searchterm: this.searchTermStruct?this.searchTermStruct.searchTerm:'',
    	  col: this.searchTermStruct?this.searchTermStruct.columnNo:0,
          getAppStyle, getAppStyleImp
      }
  },
  methods: {
      enterPressed() { this.$emit("enter"); },
      getSearch()
      {
		if ( this.col )
		{
			return { column: this.header[ this.col-1], columnNo: this.col, searchTerm: this.searchterm};
		}  
		return { column: null, columnNo: 0, searchTerm: this.searchterm};
	  },
      getHeaderAsOptions()
      {
		  let colNo = 0;
		  let options = [];
		  options.push( {id: colNo++, label: "[table]", visible: true});
		  if ( this.header)
		  {
		  	for ( let x=0; x<this.header.length; x++)
		  	{
				options.push( {id: colNo++, label: this.header[x], visible: true});
		  	}
		  }
		  return options;
	  }
  },
  updated() {
	  
  },
  created() {
	  this.searchterm='';
      this.col=0;
  },
  watch:
  {
   searchTermStruct: function() { 
	        if ( this.searchTermStruct && this.searchTermStruct.columnNo >= 0 )
	        {
    	    	this.searchterm=this.searchTermStruct.searchTerm;
    	    	this.col=this.searchTermStruct.columnNo;
    	    	this.$forceUpdate(); 
    	    }
    	   },
   imgData: function() { this.showImage = true; },
   col: function() { this.$emit('change', this.getSearch())}
  },
  computed: {
    
    
 }
}
</script>
<style lang="scss" scoped>

.textInput { font-size: 12pt; border: 1pt solid #ccc; width: 100%}
.span25 { display: inline-block; width: 85%; background-color: transparent !important;}

.dist5 { margin-left: 4pt; }
.span75 { display: inline-block; width: 15%; text-align: right; flow: right; background-color: transparent !important;}
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
    background-color: transparent !important;
}
.inputButton { 
    border: 1pt solid grey;
}
input { font-size: 12pt; border: none; }
input:disabled { font-size: 12pt; border: none; color: #888;}
input, input:focus{
    outline: none;
}
</style>